.relative {
    position: relative;
}

.padding-20 {
    padding: 20px;
}

.text-align-center {
    text-align: center;
}

.padding-top-15 {
    padding-top: 15px;
}

.no-scroll-bars {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.no-scroll-bars::-webkit-scrollbar {
    display: none;
}

.cur-point {
    cursor: pointer;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-20 {
    padding: 20px;
}

.padding-30 {
    padding: 30px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.feedback:active {
    opacity: 0.5;
    transition: all 0.1s;
}

.div-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.width-100 {
    width: 100%;
}

.width-120 {
    width: 120px;
}

.width-140 {
    width: 140px;
}

.width-160 {
    width: 160px;
}
.width-180 {
    width: 180px;
}

.width-200 {
    width: 200px;
}

.width-300 {
    width: 300px;
}

.height-5 {
    height: 5px;
}
.height-10 {
    height: 10px;
}
.height-15 {
    height: 15px;
}
.height-20 {
    height: 20px;
}
.height-25 {
    height: 25px;
}
.height-30 {
    height: 30px;
}
.height-35 {
    height: 35px;
}
.height-40 {
    height: 40px;
}
.height-45 {
    height: 45px;
}

.background-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.modal-background-cover {
    background: rgb(70, 70, 70);
}

.orange-background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-image: linear-gradient(-20deg, #f794a585 0%, #fdd5bd85 100%);
    padding-top: 10px;
    transition: all 0.5s;
    opacity: 1;
}

.gray-background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); 
}

.shift-to-gray {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); 
    transition: all 0.5s;
    opacity: 1;
}

.inactive-shift {
    opacity: 0;
}