.s-page-layout {
    display: grid;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.1s;
}

:root {
    --width-content-nav-open: 100%;
}

#scroll-selector {

    width: var(--width-content-nav-open);
    margin-left: auto;
    position: relative;
}

.s-page-nav-open {
}

.s-page-layout-mobile {
    height: 100vh;
    width: 100vw;
    margin-right: auto;
    display: grid;
}

.s-page-nav {
    width: 50px;
    background-color: #fff;
    padding: 10px;
    position: relative;
    transition: all 0.2s;
    position: absolute;
    height: 100vh;
    z-index: 5;
}

.s-page-mobile-nav {
    height: 100vh;
    width: 0px;
    padding: 10px;
    position: absolute;
    z-index: 5;
    transition: all 0.2s;
}

.s-page-nav-mobile {
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 0px;
    background: #fff !important;
}


.s-nav-ext {
    width: 350px;
}

@media (max-width: 550px) {
    .s-nav-ext {
        width: 100vw;
        height: 100%;
        overflow: auto;
        padding-bottom: 200px;

    padding: 20px;
    }
}

.s-page-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3px;
    margin-top: 20px;
    margin-bottom: 0px;
    cursor: pointer;
}

.s-page-tab {
    height: 50px;
    padding: 4px;
    padding-top: 12px;
    border-radius: 4px;
    text-align: center;
}

#tab-sel {
    background: rgba(46, 51, 65, 0.262);
    color: #fff;
    font-weight: bold;

}

.full-width {
    width: 100%;
}

.nav-time-row {
    width: 280px;
    display: grid;
    grid-template-columns: 50px 50px 1fr;
    column-gap: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.nav-section {
    margin-top: 20px;
}

.nav-label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

.arrival-list {
    margin-top: 20px;
}

.filter-holder {
}

.team-section {
    margin-top: 30px;
}


.subject-scroll {
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    
}
.pause-scroll {
    overflow: hidden;
}

.subject-holder {
    width: max-content; 
    padding-right: 10%;
    padding-left: 10%;
    height: 100%;
    transition: all 0.2s;
}

.no-padding-left {
    padding: 0%;
    margin-left: -10%;
    transition: all 0.2s;
}

.more-padding-right {

}

.full-screen {
    padding-left: 00px;
    height: 100vh;
    transition: all 0.2s;
    overflow: hidden;
}

.session-block {
    height: 100%;
}

.session-focus-container {
    width: 80vw;
    height: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    display: inline-block;
    vertical-align: top;
    transition: all 0.2s;
    
}

@media (max-width: 550px) {
    .session-focus-container {
    }
}

.session-focus-card {
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 5px 2px rgba(0, 0, 0, 0.207);
    overflow: hidden;
}

.mag-mob {
    height: 100vh;
    overflow: hidden;
    margin-bottom: -100px;
    padding-bottom: 100px;
}

.session-elements-container {
    height: 100%;
}


@media (max-width: 560px) {
    .session-focus-container {
        width: 80vw;
        height: 90%;
        display: inline-block;
        vertical-align: top;
    }
    .session-focus-card {
        height: 90%;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0.5px 0.5px 5px 2px rgba(0, 0, 0, 0.207);
        display: relative;
    }

    .mag-mob {
        height: 100%;
    }
    .magnify-mobile {
        width: 100vw;
        height: 100vh;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
        overflow: hidden;
    }
}

.focused-arrival {
    opacity: 0.2;
}

.focus-time {
    font-size: 13px;
}


.session-focus-header {
    display: grid;
    grid-template-columns: minmax(100px, 180px) minmax(200px, 1fr);
    padding: 20px;
}

.session-focus-img {
    object-fit: cover;
    width: 90%;
    max-width: 150px;
    border-radius: 4px;
}

.session-focus-container h1 {
    margin-top: 5px;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 2px;
}

.session-focus-container h2 {
    font-size: 17px;
}
.session-focus-details h3 {
    font-size: 15px;
    opacity: 0.5;
}


.session-focus-header-mobile {
    display: grid;
    grid-template-columns: 85px auto;
    padding: 20px;
    padding-bottom: 10px;
    transition: all 0.2s;
}


.session-focus-header-mobile .session-focus-img {
    object-fit: cover;
    width: 70px;
    height: 70px;
    border-radius:100px;
    display: block;
    margin-right: auto;
    transition: all 0.2s;
}

.session-focus-header-mobile h1 {
    font-size: 19px;
    margin: 0px;
    margin-top: 4px;
    transition: all 0.2s;
}
.session-focus-header-mobile h2 {
    font-size: 15px;
    margin: 0px;
    margin-top: 4px;
    transition: all 0.2s;
}
.session-focus-header-mobile h3 {
    font-size: 13px;
    margin-top: 4px;
    opacity: 0.5;
}

#condense-focus-header {
    grid-template-columns: 45px auto;
    padding-bottom: 0px;
    height: 80px;
}

#condense-focus-header h1 {
    font-size: 17px !important;
    font-weight: 500;
    margin-top: -2px;
}

#condense-focus-header h2 {
    font-size: 13px !important;
    font-weight: 500;
    margin-top: 1px;
}

.session-focus-actions {
    width: 130px;
    position: absolute;
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    z-index: 10;
    top: 40px;
    right: 20px;

}


@media (max-width: 550px) {
    .session-focus-actions {
        position: absolute;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        left: 0px;
        z-index: 2;
        bottom: 20px;
        top: 82vh;
    }
}

.action-button {
    height: 40px;
    width: 100%;
    margin-right: 10px;
    border-radius: 4px;
    font-size: 19px;
    font-weight: bold;
    transition: all 0.2s;
}

.action-button:hover {
    box-shadow: 0.5px 0.5px 5px 2px rgba(0, 0, 0, 0.207);

}

.action-button:active {
    opacity: 0.5;
}

.focus-tab {
    display: inline-block;
    margin-right: 30px;
    font-size: 21px;
}

.focus-note-bg {
    height: 100%;
    width: 100%;
}

:root {
    --height-foc-mob-scroller: auto;
}

#foc-mob-note-grid {
}

#full-height {
    height: 100%;
}


.session-focus-body {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    padding-top: 0px !important;
}
.focus-note-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.adding-note-grid {
    display: grid;
    grid-template-columns: auto 200px;
}

@media (max-width: 550px) {
    .session-focus-body {
        padding-top: 0px;
        overflow: hidden;
    }
}

.focus-blank-container {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.4;
    background: rgba(255, 255, 255, 0);
}

.focus-blank-img {
    height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.focus-add-note {
    height: 50px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 0.0px 0.0px 2px 2px rgba(0, 0, 0, 0.111);
    border-radius: 100px;
    margin-bottom: 5px;
}

.focus-add-note-container {
    border-radius: 100px;
    height: 50px;
    width: 100%;
    padding: 0px;
    margin-top: 10px;
    transition: all 0.2s;
    background: #000;
}

.note-expand {
    grid-template-columns: 60px 1fr;
}

.focus-add-note:hover {
    box-shadow: 0.0px 0.5px 5px 2px rgba(0, 0, 0, 0.187);
}

.focus-add-not:active {
    opacity: 0.5;
}

.focus-nav {
    padding-left: 20px;
}
.foc-note-item {
    display: grid;
    grid-template-columns: 60px auto;
    margin-bottom: 10px;
    transition: all 0.2s;
    position: relative;
}

.complete-section {
    margin-top: 30px;
}

.foc-note-item img {
    object-fit: cover;
    height: 50px;
    border-radius: 100px;
}


.foc-note-item h3 {
    font-size: 19px;
    margin-bottom: 5px;
}

.foc-note-item h4 {
    font-size: 13px;
    opacity: 0.3;
}


@media (max-width: 550px) {
    .foc-note-item {
        display: grid;
        grid-template-columns: 40px auto;
        margin-bottom: 10px;
        transition: all 0.2s;
        position: relative;
        transition: all 0.2s;
    }
    .foc-note-item img {
        object-fit: cover;
        height: 35px;
        border-radius: 100px;
        margin-left: -10px;
        transition: all 0.2s;
    }

    .foc-note-item h3 {
        font-size: 1.0rem;
        margin-bottom: 2px;
        transition: all 0.2s;
    }
    
    .foc-note-item h4 {
        font-size: 0.7rem;
        opacity: 0.3;
        transition: all 0.2s;
    }
    
}



.edit-foc-textarea {
    width: 100%;
    border-radius: 4px;
    height: 150px;
    font-size: 19px;
    border: 0px;
}
.edit-foc-textarea:focus {
    outline: none;
}

.edit-foc-note {
    box-shadow: 0.0px 0.0px 2px 2px rgba(0, 0, 0, 0.111);
    border-radius: 4px;
    padding: 10px;
}

.notes-list-box {
    padding: 10px;
    overflow: auto;
    height: 70vh;
    padding-bottom: 150px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

#mob-note-list-scr {
    height: 100% !important;
    padding-bottom: 50px;
}

#mob-not-list-adding {
    height: 100% !important;
    padding-bottom: 50px;
}


.nav-section input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: rgba(162, 107, 107, 0);
    padding: 8px;
    border: 1px solid #fff;
    color: #fff;
}

.nav-section input:focus {
    outline: 2px solid #fff;
    background: rgba(255, 255, 255, 0.139);
}
.nav-section input::placeholder {
    color: #fff;
    opacity: 0.5;
}

.start-button {
    padding: 2px;
    border-radius: 4px;
    height: 40px;
    border: 0px;
    padding-left: 15px;
    padding-right: 15px;
}

.details-section {
    height: 50px;
    overflow: hidden;
    transition: all 0.2s;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer;
    background: rgba(18, 18, 18, 0.125);
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.262);
    color: #fff;
}

.details-section:hover {
    background: rgba(18, 18, 18, 0.194);
}

.expand-to-fit {
    height: max-content;
    box-shadow: 0.5px 0.5px 3px rgba(100, 101, 103, 0.605);
    background: rgba(18, 18, 18, 0.125);
    color: #fff;
    font-weight: bold;
}

.nav-section-header {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: -10px;
}

.ap-btn {
    height: 40px;
    border: 0px;
    width: 50px;
    margin-right: 5px;
    transition: all 0.2s;
    letter-spacing: 0.2rem;
    background-color: #f3f5f700; 
    color: #fff;
}

.ap-btn:focus {
    outline: none;
    box-shadow: 0.5px 0.5px 3px rgb(159, 159, 159);
}

.ap-sel {
    background-color: #f3f5f741; 
    color: #fff;
    border-radius: 4px;
}

.am-pm-switch {
    margin-left: 20px;
}



.nav-time-row .time-inp {
    text-align: center;
    width: 53px;
    letter-spacing: 0.15rem;
}

.nav-time-row .time-inp:focus {
    background: rgba(255, 255, 255, 0.112);
    outline: none;
}

.full-date-picker {
    width: 305px !important;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 17px;
}

.full-calendar-picker {
    width: 305px;
}

.full-day-picker {
    width: 30px;
}

.nav-options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    cursor: pointer;
}

.nav-option-text {
    margin-bottom: 20px;
    transition: all 0.2s;
}

.sel-opt {
    font-weight: bold;
}

.session-position-item {
    font-size: 17px;
    font-weight: 600;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.1s;
}

.sess-nav-scroll {
    height: 80vh;
    overflow: auto;
    padding: 2px;
}

.pos-sel {
    background: rgba(0, 0, 0, 0.18);
}

.session-position-item:hover {
    background: rgba(0, 0, 0, 0.269);
}

.session-position-item p {
    padding: 0px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 400 !important;
}

.session-position-search {
    margin-bottom: 5px;
}

.session-position-search:focus {
    box-shadow: 0.5px 0.5px 3px rgb(159, 159, 159);
    outline: none;
}

.emp-item {
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
}

.sel-emp {
    background: rgba(46, 51, 65, 0.262);
    color: #fff;
}

.green-circle {
    border-radius: 100px;
    background: rgb(23, 255, 50);
    box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.365);
    height: 20px;
    margin-top: 5px;
    width: 20px;
}


.red-circle {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.248);
}

.active-team-member {
    display: grid;
    grid-template-columns: 40px auto 30px;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.active-team-avatar {
    height: 30px;
    object-fit: contain;
    border-radius: 100px;
}

.sess-add-team {
    position: relative;
    width: 100%;
}


.edit-team:focus {
    outline: none;
}
.active-team-name {
    font-size: 17px;
    font-weight: 500;
    margin-top: 3px;
} 

.active-team-email {
    font-size: 13px;
}

.remove-guest {
    height: 20px;
    padding: 1px;
    margin-top: 12px;
    opacity: 0.2;
}

.remove-guest:hover {
    opacity: 1;
}

.remove-guest:active {
    opacity: 0.5;
}

.nav-divider {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 5px;
    background: rgb(197, 207, 218);
}






















.session-background-page {
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    display: grid;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}

.card-view-mobile {
    height: 100vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    grid-template-rows: 110px auto;
    overflow: auto;
    
}

.form-logo {
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20px;
}

.register-form {
    margin-top: 40px;
}

.register-form-header {
    font-size: 18px;
    font-weight: 800;
    color: rgb(141, 141, 141);
    margin-bottom: 20px;
}

.form-sub-text {
    font-size: 14px;
    margin-bottom: 10px;
}

.email-text {
    margin-bottom: 15px;
}

.input-container {

    width: 100%;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.form-input-guest {
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    padding-left: 15px;
    font-size: 16px;
    background-color: #f8f8f8;
    display: grid;
}

.submit-register-btn {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(80, 156, 224);
    border-radius: 100px;
    border: 0px;
    width: 200px;
    height: 40px;
    color: #fff;
    font-weight: bold;
}

.btn-padding {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 20px;
}


.talent-tag-mobile {
    display: inline-block;
    margin-right: 5px;
    margin-top: 8px;
    border: 2px solid #eee;
    border-radius: 100px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    color: rgb(71, 156, 206);

}


.talent-tag-selected-mobile {
    border: 2px solid rgba(103, 117, 227, 0.632);
    background-color: rgba(235, 235, 235, 0.098);
}

.tag-group-selected-mobile {
    border: 2px solid rgba(143, 173, 207, 0.774);
    background-color: rgba(134, 170, 224, 0.384);
    color: rgb(61, 105, 163);
}

.tag-count-mobile{
    background-color: rgba(103, 153, 227, 0);
    font-size: 14px;
    margin-top: 3px;
}

.form-view-mobile {
    display: grid;
    grid-template-rows: 140px 180px min-content;
    row-gap: 10px;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
}

.mobile-header {
    height: 60px;
    background-color: rgba(194, 209, 231, 0.209);
    border-radius: 4px 4px 0px 0px;
    padding: 20px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.mobile-body {
}

.current-tags {
}

.current-section {
    height: 100%;
    display: grid;
    grid-template-rows: 100px auto;
    border-radius: 4px;
    padding: 10px;
    position: relative;
}

.mobile-header-icon {
    height: 50px;
    width: 50px;
    border: 2px solid #fff;
    border-radius: 100px;
    margin-top: -5px;
}

.mobile-company {
    font-size: 146x;
    font-weight: bold;
}

.focus-card {
    margin-top: -20px;
    text-align: left;
}

.focus-subject {
    font-weight: bold;
    font-size: 26px;
    color: rgb(54, 69, 90);
}

.focus-topic {
    font-size: 18px;
    font-weight: 500;
    color: rgb(54, 69, 90);
}

.focus-subTopic {
    font-size: 18px;
    font-weight: 500;
    color: rgb(54, 69, 90);
}

.section-label-mobile {
    font-weight: bold;
    font-size: 16px;
    opacity: 0.4;
}

.mobile-room {
    font-size: 14px;
    font-weight: 500;
}

.mobile-focus {
    height: 80px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-tabs-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
    position: absolute;
    width: 100%;
    cursor: pointer;
    right: 0px;
    bottom: 10px;
    border-radius: 4px 0px 0px 0px;
    height: 55px;
}

.notes-section {
    margin-top: 40px;
}

.mobile-tab-content {
    padding: 20px;
}

.current-notes {
    border-radius: 4px;
    height: auto;
}

.mobile-tab {
    font-weight: bold;
    background-color: rgb(216, 223, 234);
    height: 55px;
    padding-top: 8px;
}

.mobile-tab:hover {
    background-color: rgb(255, 255, 255);
}

.attendee-section {
    margin-top: 10px;
    margin-bottom: 50px;
}

.section-label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.internal-attendee {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    height: 31px;
    text-align: center;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 100px;
    border: 1px solid #ccc;
}

.prev-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.prev-subject-card {
    box-shadow: 1px 1px 2px 1px #eee;
    padding: 10px;
    border-radius: 4px;
}

.prev-subject {
    font-size: 13px;
    font-weight: bold;
}

.prev-topic {
    font-size: 12px;
    font-weight: 600;
}

.prev-subTopic {
    font-size: 12px;
    font-weight: 600;
}


.start-nav {
    height: 50px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    padding: 5px;
}
