
.log-in-background {
    height: 2000px;
    width: 2000px;
    background-color: #ccc;
    padding-top: 100px;
    background: rgb(116,133,224);
    background: linear-gradient(148deg, rgba(116,133,224,1) 0%, rgba(48,218,240,1) 100%);

}
.form {
    margin-left: 500px;
};