@media (max-width: 519px) {
    

    #mobile-bottom-nav {
        position: fixed;
        bottom: 0px;
        height: 60px;
        width: 100%;
        background-color: rgb(94, 96, 169);
        z-index: 15;
    }

    #right-side-bar {
        display: none !important;
    }

    .mobile-nav-layout {
        display: grid;
        grid-template-columns: auto auto auto;
        padding-top: 5px;
    }

    .mobile-nav-icon {
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 5px;
        opacity: 0.5;
        cursor: pointer;
    }

    .sel-mob-ic {
        opacity: 1;
    }

    #mobile-state-hide {
        display: none !important;
    }
}


@media (min-width: 520px) {
    #mobile-bottom-nav {
        display: none;
    }

    #mobile-state-hide {
    }

}
