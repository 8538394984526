.temp-dash-layout {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: auto max-content;
}

.temp-nav {
    background-color: #fff;
    height: 100vh;
    border: 1px solid #ccc;
}

.temp-expanded {
    width: 200px;
}

.temp-min {
    width: 30px;
}

.temp-dash-main-content {
    margin-top: 20px;
}

.temp-session-card {
    height: 200px;
    width: 200px;
    box-shadow: 1px 1px 1px 1px #ccc;
    padding: 10px;
}

.dash-session-list-container {
    margin-top: 30px;
}