.help-box-container {  
    position: absolute;
    height: 80vh;
    width: 400px;
    right: 5px;
    top: 50px;
    
    background-image: linear-gradient(-20deg, #f794a5 0%, #fdd5bd 100%);
    border-radius: 4px;
    z-index: 15;
    box-shadow: 1px 3px 4px 1px rgba(88, 88, 88, 0.719);
    overflow: auto;
    display: grid;
    grid-template-rows: 30px 40px auto;
    padding-bottom: 20px;
}


.help-box-container::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE and Edge */
.help-box-container {
    -ms-overflow-style: none;
}

.help-box-header {
    padding: 10px;
    height: auto;
}

.help-box-title {
    font-size: 16px;
    font-weight: 600;
    color: rgb(98, 103, 109);
}

.help-box-body {
    padding: 10px;
    overflow: auto;
    -ms-overflow-style: none;
}
.help-box-body::-webkit-scrollbar {
    display: none;
}

.body-section-features {
    height: 100%;
}

.body-label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
}

.suggestion-textarea { 
    width: 100%;
    height: 110px;
    border-radius: 4px;
    padding: 10px;
}

.topic-container {
    margin-top: 10px;
    padding: 25px;
    height: 100%;
    overflow: auto;
    padding-bottom: 100px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    -ms-overflow-style: none;
}

.close-help {
    border-radius: 4px;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
    border: 0px;
    color: #fff;
    height: 30px;
    font-weight: 500;
    padding-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.199);
}

.close-help:focus{
    outline: none;
}

.topic-container::-webkit-scrollbar {
    display: none;
}

.topic-header {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 5px;
}

.topic-sub-header {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 5px;
}

.topic-description {
    font-size: 13px;
    font-weight: 440;
}

.help-select {
    font-size: 14px;
    font-weight: 500;
}

.no-doc {
    text-align: center;
    margin-top: 50px;
    color: #fff;
}