.qui-men-container {
    padding: 5px;
}

.qui-men-label {
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 10px;
    margin-left: 10px;

}

.qui-check-in-length {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    background-color: rgb(237, 84, 84);
    border-radius: 100px;
    width: 23px;
    height: 23px;
    text-align: center;
}

.qui-men-note-input-container {
    width: 100%;
    position: relative;
}

.qui-men-note-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    padding: 8px;
    padding-right: 35px;
    font-size: 15px;
    margin-bottom: 8px;
}

.qui-men-tag-input {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    border: 0px;
    padding: 8px;
    padding-right: 35px;
    font-size: 15px;
}

.qui-men-tag-input:focus {
    outline: none;
}

.qui-men-note-input::placeholder {
    font-size: 15px;
    opacity: 0.5;
}

.qui-men-note-input:focus {
    outline-width: 3px;
    outline-color: #ccc;
}

.qui-note-submit-btn {
    position: absolute;
    right: 5px;
    top: -8px;
    height: 30px;
    border-radius: 4px;
    border: 0px;
}

.qui-note-submit-btn:hover {
    background-color: #fafafa;
}

.qui-men-icon {
    height: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.quick-bar-body {
    padding-left: 5px;
    padding-right: 5px;
}

.qui-men-option {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 60px auto;
    cursor: pointer;
}

.qui-men-option:hover {
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.151);
}

.qui-men-option-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
}

.quick-bar-back-btn {
    margin-top: 5px;
}

.quick-bar-back {
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    background-color: rgba(232, 236, 240, 0);
    font-size: 15px;
    font-weight: 600;
    opacity: 0.5;
}

.qui-bar-tag {
    padding: 3px;
    background-color: #fff;
    margin-bottom: 3px;
    border-radius: 4px;
    width: auto;
    position: relative;
    cursor: default;
    border: 0.8px solid #f5f5f5;
    font-size: 13px;
}

.qui-tag-count {
    text-align: center;
    opacity: 0.3;
}

.qui-tag-delete-container {
    position: relative;
    border-radius: 0px 25px 25px 0px;
}

.qui-tag-delete-container:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.qui-tag-delete {
    height: 12px;
    opacity: 0.5;
    position: absolute;
    right: 5px;
    top: 4px;
}

.qui-tag-delete:hover {
    opacity: 0;
}

.tag-label {
    margin-top: 20px;
}

.qui-bar-label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
}

.qui-tags-scroll {
    overflow-y: auto;
    height: 60vh;
    padding-bottom: 200px;
    -ms-overflow-style: none;
}

.qui-tags-scroll::-webkit-scrollbar {
    display: none;
  }

.qui-tag-delete-red {
    height: 12px;
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 6px;
}

.qui-tag-delete-red:hover {
    opacity: 1;
}

.greeting-message-top {
    position: relative;
    padding-left: 10px;
}

.qui-tag-elements {
    display: grid;
    grid-template-columns: 30px auto 20px;
    column-gap: 10px;
}

.qui-greeting-save {
    position: absolute;
    right: 5px;
    top: 0px;
    font-size: 11px;
    border-radius: 4px;
    border: 0px;
    background: rgb(35, 157, 238);
    color: #fff;
    font-weight: 600;
}

.qui-section-divider {
    height: 1px;
    background: rgb(210, 216, 221);
    margin-bottom: 10px;
    margin-top: 10px;
}

.qui-select-emp-avatar {
  object-fit: cover;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.qui-men-sub-label {
    font-size: 13px;
    font-weight: 500;
}

.qui-select-emp-item {
    display: grid;
    grid-template-columns: 40px auto 20px;
    
}

.qui-emp-options-container {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.178);
}


.qui-emp-item {
    display: grid;
    grid-template-columns: 40px auto;
    padding: 5px;
}

.qui-emp-item:hover {
    background: #eee;
    cursor: pointer;
}

.qui-select-emp-name {
    font-size: 15px;
    margin-top: 4px;
}

.qui-current-emp {
    background-color: #fff;
    padding: 5px;
    border-radius: 100px;
    margin-bottom: 10px;
    border: 0.8px solid #f5f5f5;
    cursor: pointer;
}

.qui-current-emp:hover {
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.178);
}

.qui-select-emp-arrow {
    height: 12px;
    width: auto;
    margin-top: 8px;
    cursor: pointer;
}

.sel-down-arrow {

}

.sel-up-arrow {
    transform: rotate(180deg);
}

.qui-sign-out {
    text-align: center;
    margin-top: 30px;
    opacity: .5;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.tag-add-popup {
    background-color: #fff;
    box-shadow: 0px 0px 1px 1px rgba(204, 204, 204, 0.432);
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    z-index: 10;
    margin-bottom: 10px;
}

.tag-add-type-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.add-tag-popup-btn {
    font-size: 15px;
    border: 0px;
}

.add-tag-popup-btn:hover {
    background-color: #eee;
}

.tag-group-item {
    cursor: pointer;
    background-color: #fff;
    border: 0px;
}

.arrow-flip {
    transform: rotate(180deg);
}

.tag-group-back-arrow {
    height: 15px;
}

.qui-bar-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    margin-top: 30px;
}

.position-item {
    padding: 8px;
    margin-bottom: 5px;
    background: rgba(46, 51, 65, 0.084);
    color: #fff;
    border-radius: 4px;
}

.position-name {
    font-size: 17px;
    font-weight: 600;
}

.menu-header {
    padding: 10px;
    padding-bottom: 0px;
    font-size: 21px;
    font-weight: bold;
}

.nav-arrivals-padding {
    padding: 10px;
}

.menu-list {
    padding: 8px;
    margin-top: 10px;
    position: relative;
}

.menu-button {
    margin-top: 10px;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    display: block;
    width: 120px;
    right: 13px;
    bottom: 10px;
    transition: all 0.2s;
    font-size: 15px;
    margin-left: auto;
    font-weight: bold;
}

.menu-button:hover {
    box-shadow: 1px 1px 2px rgba(94, 94, 94, 0.413);
}

.menu-button:disabled {
    background: #eee;
}

#create-new-position {
}

.menu-list .switch-position {
    position: absolute;
    top: 10px;
    right: 10px;
}

.menu-sub-section {
    padding: 10px;
}

.menu-label {
    margin-bottom: 5px;
    font-weight: 600;
}

.menu-input-container {
    margin-bottom: 10px;
    height: auto;
}

.menu-input {
    width: 100%;
    height: 40px;
    font-size: 19px;
    padding: 8px;
    border-radius: 4px;
    border: 0px;
}

.menu-input:focus {
    outline: none;
}



#create-new-position {

}

.add-pos-con {
    padding: 3px;
    border-radius: 4px;
    height: 40px;
    background-color: #fff;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0.5px 0.5px 3px 1px rgba(34, 34, 34, 0.18);
    transition: all 0.2s;
    position: relative;
}

.add-pos-exp {
    height: max-content;
    position: relative;
}

.new-position {
    width: 100%;
    text-align: center;
    padding-top: 5px;
}

#pos-input-bg {
}

#pos-input-bg:disabled {
    opacity: 0.2;
}

.aff-container {
    margin-top: 5px;
    border: 1px solid #eee;
    border-radius: 4px;
}

.aff-item {
    display: grid;
    grid-template-columns: 0px auto;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 17px;
    padding: 10px;
}

.aff-item:hover {
    background: #eee;
    cursor: pointer;
}

.qui-men-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0px;
}