
/* TRANSITIONS */

.test-enter {
    opacity: 0.01;
}

.test-enter.test-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.test-leave {
    opacity: 1;
}

.test-leave.test-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}

.appear-appear {
    opacity: 0.01;
}

.appear-appear.appear-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.container-transition-enter {
    opacity: 0;
  }
.container-transition-enter-active {
opacity: 1;
transition: opacity 200ms;
}
.container-transition-exit {
opacity: 1;
}
.container-transition-exit-active {
opacity: 0;
transition: opacity 200ms;
}

.fadeOutHeader {
    opacity: 0;
    transition: opacity 0.5s;
}


.fadeOutNew{
    opacity: 0;
    height: auto;
    transition: all 0.4s;
    z-index: 5;

}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s;
}

.fadeOut{
    opacity:0;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s;
    z-index: 5;

}

.fadeOutFast{
    opacity:0;
    height: 100%;
    width: 100%;
    transition: opacity 0.2s;

}

.fadeInFast {
    opacity: 1;
    transition: opacity 0.05s;
    z-index: 10;

}

.fadeInHeader {
    opacity: 1;
    transition: opacity .5s;
    z-index: 10;
}

.fadeIn{
    opacity: 1;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s;
    z-index: 5;
}

.fadeInBtn{
    opacity:1;
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
    transition: opacity 0.5s;
}

.sly-check-in-list {
    margin-top: 10px;
}

@media(min-width: 921px) {
    .card-assign {
        z-index: 50 !important;
        opacity: 0;
        height: 00px;
        width: 250px;
        transition: all 0.5s;
    }
}

@media(max-width: 920px) {
    .card-assign {
        z-index: 50 !important;
        opacity: 0;
        width: 100%;
        transition: all 0.2s;
        background-color: rgb(173, 180, 194) !important;
    }
}

.fadeOutSlow {
    opacity: 0;
    transition: all 0.5s;
}

.step-1 {
    opacity: 0.1 !important;
    background-color: rgb(255, 255, 255);
}

.step-2 {
    opacity: 1 !important;
    background-color: rgb(123, 153, 228) !important;
    height: 320px;
}

.step-3 {
    height: 320px;
    box-shadow: 0px 0px 3px 1px rgb(154, 161, 255);
}

.step-4  {
    background-color: rgb(142, 123, 228);
    box-shadow: 1px 1px 8px 1px rgb(142, 123, 228);
}

.step-5 {
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px #ddd;
    border-radius: 4px;
    transition: all 0.1s !important;
}

.margin-top-list {
}

.grow-animate {
    height: 1px;
    transition: all 0.5s;
}

.growing-space {
    height: 200px;
}
