.dashboard-layout {
    display: grid;
    grid-template-columns: max-content auto;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.2s;
}

.dash-nav {
}

.dash-content {
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
}

@media (max-width: 550px) {
    .dash-content {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    }
}


.dash-search {
    width: 350px;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    padding: 8px;
    padding-left: 15px;
    background: #fafafa;
    font-size: 17px;
}

@media (max-width: 550px) {
    .dash-search {
        width: 100%;
    }
}

.dash-header {
    font-size: 25px;
    font-weight: 800;
    padding-bottom: 10px;
}



/* ACCOUNTS */


.account-item {
    width: 300px;
    height: auto;
    background: #fff;
    box-shadow: 0.5px 0.5px 3px rgba(50, 90, 148, 0.605);
    padding: 20px;
    border-radius: 4px;
    transition: all 0.2s;
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    margin-bottom: 15px;
}

.account-item .user-hs {
    height: 60px;
    border-radius: 100px;
    margin-bottom: 10px;
}

.account-item h2 {
    font-size: 19px;
}

.account-item h3 {
    font-size: 15px;
}

.account-item h4 {
    font-size: 13px;
    opacity: 0.5;
}

.account-item .delete-btn {
    margin-left: auto;
    display: block;
}

.account-btn {
    right: 40px;
}

.add-account-container {
    position: absolute;
    background: #fff;
    box-shadow: 0.5px 0.5px 3px rgba(50, 90, 148, 0.605);
    padding: 20px;
    right: 40px;
    top: 40px;
    width: 250px;
    border-radius: 4px;
    z-index: 5;
}

.add-account-container h3 {
    font-size: 17px;
    margin-bottom: 2px;
}

.add-account-input {
    margin-bottom: 10px;
}