.roompage-container {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); 

    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.roompage-container::-webkit-scrollbar {
    display: none !important;
}

@media (max-width: 509px) {
    .roompage-layout {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: block;
    overflow: auto;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    }
    .roompage-layout::-webkit-scrollbar {
        display: none !important;
    }
}

@media (max-width: 509px) {
    #active-card-relative-mobile {
        position: relative;
    }
}


@media (min-width: 510px) {
    .roompage-layout {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: auto min-content;
    }
}

.sidebar-collapse {
    grid-template-columns: auto max-content;
}

.transparent-checkin {
    background-color: rgba(228, 228, 228, 0.24);
    width: 100%;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 1px 1px #eee;
    font-size: 13px;
    font-weight: 600;
}


.transparent-checkin-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
}

.arrow-min-sidebar {
    height: 14px;
    width: 14px;
    margin-left: 5px;
    margin-top: 5px;
    transform: rotate(270deg);
    cursor: pointer;
    margin-right: 5px;
}

.left {
    transform: rotate(90deg);
}

.check-in-container-room {
    height: 100vh;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    width: 280px;
    background-color: #f7f9fc;
    overflow: hidden;
    transition: all 0.1s;
    padding: 2px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 2px;
}

.room-min-width {
    width: 40px;
}

.transparent-checkin-header {
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
}

.collapse-room {
    background-color: #f7f9fc;
    width: 130px;
    padding: 3px;
    opacity: 1;
    transition: all 0.2s
}

.layout-left {
    height: 100%;
    position: relative;
}

@media (max-width: 509px) {
    .layout-left {
        padding: 0px;
        height: 100vh;
    }
}

.start-session-pop-up {
    z-index: 25;
    background-color: #fff;
    box-shadow: 1px 1px 5px 1px #bbb;
    height: auto;
    width: 330px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border-radius: 4px;
}

.heads-up {
    font-size: 19px;

}

.session-to-click {
    height: 40px;
    border: 1px solid #eee;
    padding-top: 7px;
    padding-left: 20px;
    border-radius: 4px;
    cursor: pointer;
}

.selected-session {
    background-color: rgb(118, 176, 220);
    color: #fff;
}

.prep-buttons {
    width: 290px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    font-weight: 600;
}

.no-button {
    border-radius: 4px;
    height: 40px;
    background-color: rgb(121, 121, 121);
    color: #fff;
    border: 0px;
    font-weight: 600;
}

.no-button:hover {
    box-shadow: 1px 2px 3px 2px #ccc;
}

.start-prep-button:hover {
    box-shadow: 1px 2px 3px 2px #ccc;
}

.start-prep-button {
    border-radius: 4px;
    height: 40px;
    background-color: rgb(82, 193, 95);
    color: #fff;
    border: 0px;
    font-weight: 600;
}

.room-view-card {
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    height: 100%;
    width: auto;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;

}


.room-view-card::-webkit-scrollbar {
    display: none;
}
  
.expand-this-view {
    width: 100%;
    text-align: center;
    opacity: 0.2;
}

.full-screen {
    height: 90%;
}

    .room-card-layout {
    row-gap: 0px;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    }

    .room-add-section {
        display: grid;
        grid-template-columns: auto 28px;
        border-radius: 4px;
        height: auto;
        min-height: 47px;
    }
    

.select-arrow-room {
    position: absolute;
    right: 25px;
}

.room-section {
    width: 100%;
    margin-left: 10px;
    position: relative;
}

.room-card-layout-min {
    overflow-y: hidden;
    height: 100%;
}

@media (min-width: 1100px) {
    .room-card-layout {
    display: grid;
    grid-template-columns: min-content auto;
    overflow-y: hidden;
    height: 100%;
    }
}

.room-view-settings-min {
    width: 300px;
    position: relative;
}


.room-view-settings {
    padding: 20px;
    height: 80vh;
    margin-top: 10px;
    border-radius: 4px;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-rows: min-content min-content;
    position: relative;
    background-color: #fff;
}

.layout-right {
    height: 100%;
    position: relative;
    
}

.room-page-room-name {
    font-size: 17px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: -10px;
    font-weight: 600;
    display: inline-block;
}

/* TABS */

.room-page-tab-container {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 40px;
    width: 100%;
    cursor: pointer;
}

.room-page-list-label {
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 10px;
}

.room-page-list-label-inactive {
    font-weight: bold;
    color: #fff;
    background-color: #32567d8a;
    text-align: center;
    padding-top: 10px;

}

/* CHECK IN LIST */

.checkins-list {
    width: 100%;
    padding: 15px;
}

.checkins-scroll {
    overflow-y: scroll;
    padding-bottom: 500px;
    margin-right: -30px;
    padding-right: 15px;
}

.completed-container {
    overflow: hidden;
    height: 100vh;
    background-color: #32567d8a;
}

.checkins-container {
    height: 100%;
    background-color: #f7f9fc;
    overflow: hidden;
    transition: all 0.5s;
}

.completed-list {
    width: 100%;
    padding: 15px;
}

.check-card-name {
    font-size: 17px;
    font-weight: 700;
}

.check-card-name-no-dibs {
    font-size: 17px;
    font-weight: bold;
}
.check-card-project-no-dibs {
    font-size: 15px;
    font-weight: 500;
}

.check-card-rep-no-dibs {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.6;
}

.completed-card-name {
    font-size: 17px;
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: -5px;
}

.check-card-project {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
}

.sort-check-ins {
    display: grid;
    grid-template-columns: 100px 60px 1fr;
    margin-left: 5px;
    
    margin-bottom: 10px;
}

.sort-by-option {
    font-weight: 600;
    opacity: 0.2;
}

.sort-selected {
    font-weight: bold;
    opacity: 1;
}

.check-card-role {
    font-size: 12px;
    font-weight: 500;
    margin-top: 3px;
}

.check-card-rep {
    font-size: 13px;
    margin-bottom: 5px;  
}

.check-card-time {
    font-size: 13px;
    margin-right: -15px;
    opacity: 0.7;
}

.check-card-element-bold {
    font-size: 15px;
    font-weight: bold;
}

.room-tab {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 5px;
}

.room-check-card {
    box-shadow: 0 1px 2px 1px rgba(73, 73, 73, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.303);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 12px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.room-check-card-dibs {
    box-shadow: 0 1px 1px 1px rgba(73, 73, 73, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.303);
    background-color: rgb(43, 218, 130);
    border: 1 solid #000;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 12px;
    margin-bottom: 5px;
    cursor: pointer;
}

.room-check-card-not-my-dibs {
    background-color: rgb(230, 233, 240);
    border: 1 solid #000;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 12px;
    margin-bottom: 5px;
    cursor: pointer;
}

.room-completed-card {
    box-shadow: 0 1px 2px 1px rgba(73, 73, 73, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.303);
    background-color: rgb(255, 255, 255);
    border: 1 solid #000;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 12px;
    margin-bottom: 5px;
    cursor: pointer;
}

.layout-left-header {
    height: auto;
}

.room-page-room-name {
    font-size: 25px;
}

.rp-active-talent {
    height: 90vh;
    width: 100%;
    padding: 0px;
    padding: 10px;
    overflow: hidden;
}

    .rp-active-talent-bg {
        height: 100%;
        border-radius: 4px;
        padding: 5px;
        
    }
        .rp-current-available-container {
            width: 100%;
            margin-top: 50px;
        }

            .rp-current-available {
                font-size: 25px;
                font-weight: bold;
                margin-bottom: 10px;
                color: #bbb;
                text-align: center;
            }

            .rp-current-available-description {
                font-size: 17px;
                font-weight: 600;
                width: 215px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                color: #bbb;
                text-align: center;
            }

            .margin-top-50 {
                margin-top: 50px;
            }

            .rp-current-available-description-wide {
                font-size: 15px;
                font-weight: 500;
                width: auto;
                max-width: 380px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                margin-top: 20px;
                color: #bbb;
                text-align: center;
            }

            .rp-current-available-btn {
                width: 30%;
                min-width: 140px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
                margin-bottom: 15px;
            }

            .rp-assign-btn {
                width: 100%;
                height: 40px;
                border-radius: 100px;

                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                background-color: #465e836b;
                border: 0px;
                color: #fff;
                font-size: 17px;
                font-weight: bold;
            }

            .rp-assign-btn:disabled {
                background-color: #cccdd3;
            }

        .rp-active-talent-card {
            height: 100%;
            background-color: #fff;
            border-radius: 4px;
            display: grid;
            grid-template-rows: min-content auto;
        }

        
        @media(min-width: 921px) {
            .rp-active-talent-card-2 {
            height: 100%;
            background-color: #fff;
            border-radius: 4px;
            display: grid;
            grid-template-columns: 250px auto;
            padding: 5px;
            column-gap: 20px;
            }


            .note-display-room-view {
                overflow-y: auto;
            }
        }


        @media(max-width: 920px) {
            .rp-active-talent-card-2 {
            height: 90vh;
            background-color: #fff;
            border-radius: 4px;
            display: grid;
            padding: 5px;
            margin-bottom: 20px;
            column-gap: 20px;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            }

            .rp-active-talent-card-2 .focus-talent-elements-display {
                display: grid;
                grid-template-columns: auto 150px;
            }

            .rp-active-talent-card-2 .talent-elements {
                text-align: left !important;
                margin-left: 20px;
            }
          


            .note-display-room-view {
                overflow: hidden;
            }
        }

        @media(min-width: 751px) {
            .rp-active-talent-card-3 {
            height: 100%;
            background-color: #fff;
            border-radius: 4px;
            display: grid;
            grid-template-columns: 250px auto;
            
            
            padding: 5px;
            column-gap: 20px;
            }

            .note-display-room-view {
                overflow-y: auto;
            }
        }

        @media(max-width: 750px) {
            .rp-active-talent-card-3 {
                
                height: 90vh;
                background-color: #fff;
                border-radius: 4px;
                display: grid;
                padding: 5px;
                margin-bottom: 20px;
                column-gap: 20px;
                overflow: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }

            .rp-active-talent-card-3 .focus-talent-elements-display {
                display: grid;
                grid-template-columns: auto 150px;
            }

            .rp-active-talent-card-3 .talent-elements {
                text-align: left !important;
                margin-left: 20px;
            }


            .note-display-room-view {
                overflow: hidden;
            }


            .show-queue {
                margin-top: -15px;
            }
        }

        .rp-active-talent-card-2::-webkit-scrollbar, .rp-active-talent-card-3::-webkit-scrollbar {
            display: none;
        }

        .view-2 {
            margin-bottom: 5px;
        }

        .rp-active-talent-2-left {
            border-right: 1px solid #ccc;
        }

            .rp-active-talent-header {
                width: 100%;
                height: auto;
                padding-top: 20px;
                text-align: center;
                border-bottom: 1px solid #eee;
            }  
            .rp-active-talent-header-2 {
                width: 100%;
                height: auto;
                text-align: left;
                padding-bottom: 4px;
                border-bottom: 1px solid #eee;
            }  

                .rp-active-talent-avatar {
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                    background-color: #ccc;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 5px;
                }

                .rp-active-talent-avatar-2 {
                    width: 30px;
                    height: 30px;
                    border-radius: 100px;
                    background-color: #ccc;
                    margin-left: 10px;
                    margin-bottom: 5px;
                }

                .rp-active-talent-name {
                    font-size: 19px;
                    font-weight: bold;
                    margin-bottom: -1px;
                }

                .rp-active-talent-project {
                    font-size: 17px;
                    font-weight: 500;
                    margin-bottom: -1px;
                }

                .rp-active-talent-role {
                    font-size: 15px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }
        
            .rp-active-talent-body {
                padding: 20px;
                width: 100%;
                height: 100%;
                overflow-y: auto;
            }   

                .new-note-textarea-room {
                    width: 100%;
                    border-radius: 4px;
                    height: 40px;
                    padding: 8px;
                    border: 1px solid rgba(174, 183, 196, 0.575);
                    margin-bottom: 15px;
                    font-size: 17px;
                    font-weight: 500;
                }

                .new-note-textarea-room:focus {
                    outline: none;
                }

                .new-note-textarea-room::placeholder {
                    outline: none;
                    opacity: 0.5;
                }

                .rp-active-talent-notes-container {
                    height: 100%;
                    width: 100%;
                }

                .rp-active-talent-tags-container {
                    height: min-content;
                    width: 100%;
                    margin-bottom: 30px;
                }

                .rp-active-talent-notes-input {
                    height: 100%;
                    width: 100%;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 19px;
                }

                .rp-active-talent-notes-input:focus {
                    outline-width: 0px;
                }

                .complete-button-container {
                    width: 40%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 10px;
                    margin-bottom: 15px;
                }

                .complete-button-container-2 {
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #eee;
                    padding-bottom: 15px;
                }

                .complete-btn-room {
                    height: 40px;
                    width: 120px;
                    border-radius: 100px;
                    background-color: #51df91;
                    border: 0px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                }
                .complete-btn-room-2 {
                    height: 40px;
                    width: 200px;
                    border-radius: 4px;
                    background-color: #51df91;
                    border: 0px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }

                @media (max-width: 750px) {

                    .rp-active-talent-card-3 .complete-btn-room-2 {
                        width: 100px;
                    }

                    .rp-active-talent-card-3 .user-avatar-active-card {
                        margin-left: 20px;
                    }

                    .rp-active-talent-card-3 .focus-talent-layout {
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }

                    .rp-active-talent-card-3 #not-next-checkin {
                        width: 100%;
                        display: inline-block;
                    }

                }

                @media (min-width: 751px) {
                    .left-border {
                        border-left: 2px solid rgba(211, 213, 228, 0.507);
                        padding-left: 15px;
                    }
                }


                @media (max-width: 920px) {


                    .rp-active-talent-card-2 .complete-btn-room-2 {
                        width: 100px;
                    }

                    .rp-active-talent-card-2 #not-next-checkin {
                        width: 250px;
                    }

                    .rp-active-talent-card-2 .focus-talent-layout {
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }
                        
                    .rp-active-talent-card-2 .user-avatar-active-card {
                        margin-left: 20px;
                    }
                }

                .complete-btn-room-2:disabled {
                    background-color: #ddd;
                }

                .complete-btn-room:hover {
                    box-shadow: 1px 1px 4px 1px #ddd;
                }


.room-page-section-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.room-page-section-header {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
}


.room-page-section-header-closed {
    width: 100%;
    display: grid;
    grid-template-columns: auto 50px;
}

.room-page-section-select {
    width: 150px;
    font-size: 15px;
}

.list-add-btn {
    margin-left: auto;
    font-size: 12px;
    margin-top: 12px;
    cursor: pointer;
}

.room-page-label {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 10px;
    display: inline-block;
}

.room-page-reader-card {
    border: 1px solid #eee;
    padding: 8px;
    width: auto;
    border-radius: 4px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.room-page-role-card {
    border: 2px solid #eee;
    background-color: #fff;
    padding: 8px;
    padding-top: 6px;
    height: 35px;
    width: auto;
    border-radius: 4px;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.reader-name-room-view {
    font-size: 17px;
    padding: 5px;
    font-weight: 500;
}

.reader-layout-room-view {
    display: grid;
    grid-template-columns: auto 25px;
    height: auto;
    padding-left: 5px;
}

.reader-container-room-view {
    background-color: #f8f8f8;
    width: max-content;
    border-radius: 4px;
    height: auto;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.room-page-role-card:hover {
    box-shadow: 1px 1px 3px 1px #ddd;
}

.activated {
    border: 2px solid rgb(132, 219, 190);
    background-color: rgba(209, 231, 224, 0.644);
}

.room-page-reader-contents {
    display: grid;
    grid-template-columns: auto 20px;
    column-gap: 8px;
}

.room-page-project-header {
    display: grid;
    grid-template-columns: auto 20px;
    column-gap: 8px;
    margin-bottom: 5px;
}

.room-page-readers-list {
    padding-top: 0px;
}

.room-page-project-list-bg {
    height: 76%;
    width: 100%;
    border-radius: 4px;
    overflow: auto;
}

    .room-page-project-list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
    }


.room-page-project-card {
    width: 96%;
    height: auto;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 20px;
}

.room-page-project-title {
    font-size: 17px;
    font-weight: 700;
}

.room-page-role-container {
    display: grid;
    grid-template-columns: 15px auto;
    margin-top: 3px;
}

.room-page-project-role {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 3px;
}

.room-page-role-icon {
    height: 12px;
    width: 12px;
    background-color: #ccc;
    border-radius: 100px;
    margin-top: 2px;
}

/* INPUTS */


/* BUTTONS */

.delete-item {
    height: 12px;
    width: 12px;
    justify-content: center;
    border: 0px;
    border-radius: 4px;
    outline: none;
    opacity: 0.5;
    margin-top: -5px;
    margin-left: 5px;
}

.delete-item:hover {
    opacity: 1;
}

.delete-item:focus {
    outline: 0;
}

/* ICONS */

.icons {
    float: right;
}

.dibs-icon {
    height: 20px;
    width: 15px;
    margin-left: 2.5px;
}

.star-icon {
    height: 20px;
    width: auto;
    margin-top: 12px;
}

.clear-icon {
    height: 10px;
    width: auto;
    margin-bottom: 8px;
}



.no-data-message {
    text-align: center;
    padding: 10px;
    opacity: 0.2
}


.role-select-card {
    margin-top: 10px;
}

.add-users-icon {
    height: 30px;
    width: 30px;
    opacity: 0.2;
    margin-right: 5px;
}

.add-users-icon:hover {
    opacity: 1;
}

.add-project-icon {
    height: 21px;
    width: auto;
    opacity: 0.2;
    margin-right: 5px;
}

.add-project-icon:hover {
    opacity: 1;
}


/* TalentTags.Js */

.talent-tag-list {
    font-size: 15px;
}

.talent-tag-list-2 {
    font-size: 15px;
    width: 100%;
}

.talent-tag {
    display: inline-block;
    margin-right: 5px;
    margin-top: 8px;
    border: 1px solid #eee;
    border-radius: 100px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 6px;
    font-weight: 700;
    font-size: 17px;
    cursor: pointer;
}

.talent-tag-2 {
    display: inline-block;
    margin-right: 5px;
    margin-top: 4px;
    border: 2px solid rgba(218, 218, 218, 0.212);
    color: rgb(113, 115, 122);
    border-radius: 4px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    height: 35px;
    padding-top: 2px;
    font-weight: 500;
    font-size: 17px;
    width: max-content;
    cursor: pointer;
}


.talent-tag-selected {
    border: 2px solid rgba(103, 117, 227, 0.632);
    background-color: rgba(150, 154, 190, 0.098);
}

.tag-group-selected {
    border: 2px solid rgba(227, 103, 146, 0.632);
    background-color: rgba(227, 103, 192, 0.098);
}

.room-page-room-icon {
    height: 24px;
    width: auto;
    margin-top: -10px;
    margin-left: 12px;
    display: inline-block;
}


.room-card-nav-icon { 
    margin-left: 9px;
    height: 20px;
    width: 20px;
    opacity: 0.4;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 8px;
}

.room-card-nav-icon-open {
    height: 20px;
    width: 20px;
    margin-left: 9px;
    opacity: 1;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 8px;
}

.room-card-invite-icon {
    height: 23px;
    margin-left: 10px;
    margin-top: 20px;
    opacity: 0.1;
    position: absolute;
    right: 0px;
    right: 47px;
    top: -11px;
}

.room-card-nav {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #fff;
}

.room-card-invite-icon:hover {
    opacity: 0.6;
}

.room-card-invite-icon-open {
    height: 20px;
    margin-left: 10px;
    margin-top: 20px;
    opacity: 0.8;
    cursor: pointer;
}

.room-card-invite-icon-open:hover {
    opacity: 0.6;
}

.room-card-nav-icon:hover {
    opacity: 0.6;
}

.room-card-nav-icon-open:hover {
    opacity: 0.6;
}

.margin-top {
    margin-top: 40px;
}


/* INVITE */

.invite-modal {
    height: 105px;
    width: 300px;
    border-radius: 4px;
    position: absolute;
    background-color: rgb(100, 158, 239);
    box-shadow: 1px 1px 4px 1px #ccc;
    right: 50px;
    top: 20px;
    padding: 10px;
    padding-top: 5px;
    border: 2px solid rgb(118, 144, 220);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    z-index: 5;
}

.invite-modal-click-out {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 4;
}

.invitee-input {
    border-radius: 4px;
    border: 0px;
    width: 100%;
    background-color: #fdfdfd;
    height: 35px;
    margin-top: 3px;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 600;
}

.invitee-input:focus {
    outline: none;
}

.send-invite-btn {
    width: 80px;
    font-size: 12px;
    font-weight: bold;
    background-color: rgb(50, 105, 161);
    color: #fff;
    border-radius: 4px;
    border: 0px;
    height: 25px;
    margin-top: 5px;
}

.tag-display {
    display: grid;
    grid-template-columns: auto 10px 16px 0px;
}

.tag-display-guest {
    display: grid;
    grid-template-columns: auto 16px;
    column-gap: 10px;
}

.tag-display-count {
}

.tag-count {
    font-size: 12px;
    margin-bottom: -3px;
    margin-top: 1px;
    border-radius: 100px;
    border: 0px;
}

.red {
    background-color: rgba(227, 103, 192, 0.098);
}

.eye-icon {
    height: 15px;
    width: 15px;
    display: inline-block;
}

.user-avatar-active-card {
    
  object-fit: cover;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.talent-elements {
    text-align: center;
}

.tag-select {
    background-color: rgb(244, 245, 248);
    font-weight: 500;
    color: rgb(83, 63, 87);
    border: 2px solid #7627d16c;
}

.projects-icon {
    height: 15px;
    margin-top: 4px;
    border-radius: 2px;
}

.room-page-project-title-container {
    display: grid;
    grid-template-columns: 25px auto;
    font-weight: bold;
}

.pending-clock {
    height: 15px;
    margin-left: 12px;
    position: absolute;
    top: 14.5px;
    opacity: 0.5;
}

.note-text-room-view {
    font-size: 17px;
    font-weight: 500;
}

.note-date-room-view {
    font-size: 13px;
    opacity: 0.7;
}

.note-initials {
    font-size: 19px;
    background-color: rgb(222, 226, 237);
    height: 35px;
    width: 35px;
    border-radius: 100px;
    text-align: center;
    padding-top: 3px;
    font-weight: 700;
    color: #fff;
}

.note-container-room-view {
    display: grid;
    grid-template-columns: 45px auto 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}


.note-display-room-view {
    height: 70vh;
    padding-bottom: 150px;
}

.rp-active-talent-notes-container {
    height: 100%;
}


.remove-note-room-view {
    height: 19px;
    padding-right: 10px;
    opacity: 0.1;
}

.remove-note-room-view:hover {
    opacity: 0.8;
}

.select-session-component {
    width: 300px;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: -5px;
    display: block;
}

.room-card-header-room-view {
    margin-bottom: -5px;
}

.room-reader-snooze-item {
    border-radius: 10px;
    background-color: #eceff4;
    padding: 10px;
    margin: 10px;
    margin-top: 20px;
    margin-bottom: -2px;
    width: 150px;
    display: inline-block;
    font-size: 15px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    cursor: pointer;
}

.status-icon {
    height: 30px;
    width: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.user-alertness {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    text-align: center;
}

.im-alert {
    background-color: #465e836b;
}

.alertness-label {
    text-align: center;
    width: 40%;
    min-width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 15px;
    font-weight: bold;
    color: #bbb;
}

.take-item {
    display: inline-block;
}

.take-item-layout {
    width: 200px;
    display: grid;
    grid-template-columns: 20px auto 30px;
    height: 40px;
    border-radius: 4px;
    box-shadow: .5px .5px 1px 1px rgba(0, 0, 0, 0.089);
    padding-left: 15px;
    padding-top: 7px;
    font-size: 17px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.take-timer-click-container {
    margin-bottom: 10px;
}

.take-timer-click-container .timer-count {
    font-size: 21px;
}

.active-time {
    column-gap: 5px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    margin-bottom: 10px;
    width: 200px;
}

.mark-take {
    border-radius: 100px;
}

.add-take-btn {
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
    width: 200px;
    font-size: 15px;
    height: 40px;
    padding-top: 1px;
    color: #fff;
    border: 0px;
    font-weight: bold;
    background-color: #465e836b;
}

.stop-take-btn {
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    height: 40px;
    padding-top: 3px;
    color: #fff;
    border: 0px;
    font-weight: bold;
    background-color: #e75541;
    width: 200px;

}


.stop-take-btn:focus {
    outline: none;
}

.stop-take-btn:active {
    outline: none;
    background-color: #333;
}

.add-take-btn:focus {
    outline: none;
}

.add-take-btn:active {
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.089);
}

.take-icon {
    height: 18px;
    margin-right: 10px;
    margin-top: -5px;
    cursor: pointer;
}

.take-btn-elements {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.take-number {
    opacity: 0.2;
}

.take-btn-element {
    width: 100%;
    font-size: 13px;
}

.take-icon-small {
    height: 13px;
    margin-right: 5px;
}

.mark-take {
    border-radius: 4px;
    background-color: #eee;
    border: 0px;
}

.mark-take:active {
    background-color: #333;
    color: #fff;
}

.mark-take:focus {
    outline: none;
}

.settings-container-absolute {
    background-color: #fff;
}

.alert-bold-text {
    font-size: 19px;
    font-weight: 800;
}