:root {
    --color-bg-container: #fff;
    --color-text-header: #000;
    --color-text-description: #000;
    --color-bg-button: rgb(70, 203, 112);
    --color-text-button: #fff;
}

.ani-update-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    background: var(--color-bg-container);
    box-shadow: 1px 1px 5px 2px rgba(51, 59, 75, 0.262);
    border-radius: 4px;
    border: 0px;
    width: auto;
    max-width: 500px;
    height: 600px;
    transition: all 0.2s;
}

.update-content {
    display: grid;
    grid-template-rows: max-content 380px 120px;
    width: 100%;
}

.update-content .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 30px;
    padding: 20px;
}

.update-content button {
    height: 50px;
    width: 100%;
    text-align: center !important;
    border-radius: 4px;
    border: 0px;
    background: var(--color-bg-button);
    color: var(--color-text-button);
    font-weight: bold;
}

.update-content .header {
    font-size: 29px;
    margin-bottom: 15px;
    border-bottom: 5px solid rgba(61, 128, 179, 0.534);
    padding-bottom: 10px;
    width: 100%;
    padding: 20px;
}

.update-content .body {
    white-space: pre-wrap;
    font-size: 19px;
    line-height: 2rem;
    height: 390px;
    overflow: auto;
    padding: 20px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.inline {
    display: inline-block;
}

.update-widget {
    position: absolute;
    right: 10px;
    bottom: 20px;
    height: 40px;
}

.closeout {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
}