.activation-code {
    height: 50px;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    background-color: #eeeeee;
    border: 0px;
    font-size: 30px;
    width: 200px;
    letter-spacing: .1em;
}

.activation-code::placeholder {
    font-size: 30px;
    letter-spacing: .4em;
    padding-left: 8px;
}

.with-ani-btn {
    height: 50px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 1px #ddd;
}

.sign-in-input-section {
    width: 300px;
    margin-top: 20px;
}

.sign-in-label {
    font-size: 15px; 
    font-weight: bold;
}

.login-input {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background-color: #fafafa;
    border: 1px solid #eee;
}