:root {

    --padding-nav: 5px;
    --padding-nav-section: 5px;
    --padding-nav-input: 8px;
    --padding-dash-input: 8px;
    --padding-focus-tag-add: 10px;
    --padding-focus-tag-add-item: 10px;
    --padding-focus-tag-item: 10px;
    --padding-analytics-item: 0px;
    
    
    --height-nav-input: 40px;

    --border-nav-input: 0px;
    --border-dash-input: 1px solid #ccc;

    --border-radius-nav-input: 4px;
    --border-radius-dash-input: 4px;
    --border-radius-focus-tag-add: 4px;
    --border-radius-focus-tag-add-item: 4px;
    --border-radius-focus-tag-item: 4px;

    --box-shadow-nav-input-focus: 0.5px 0.5px 5px 1px rgba(72, 72, 72, 0.495);
    --box-shadow-dash-input-focus: 0.5px 0.5px 5px 1px rgba(72, 72, 72, 0.495);
    --box-shadow-focus-tag-add: 0.5px 0.5px 3px 1px rgba(72, 72, 72, 0.200);
    --box-shadow-focus-tag-add-item: 0.5px 0.5px 2px 1px rgba(72, 72, 72, 0.123);
    --box-shadow-focus-tag-item: 0.5px 0.5px 2px 1px rgba(72, 72, 72, 0.123);
    --box-shadow-button-hover: 0.5px 0.5px 2px 1px rgba(72, 72, 72, 0.29);
    --box-shadow-button-focus: 0.5px 0.5px 5px 1px rgba(72, 72, 72, 0.495);
    --box-shadow-button: 0.5px 0.5px 2px 1px rgba(72, 72, 72, 0.123);
    --box-shadow-analytics-item: 0px;
    --box-shadow-analytics-item-hover: 0.5px 0.5px 2px 1px rgba(72, 72, 72, 0.495);
    --box-shadow-summary-tag: 0.5px 0.5px 2px 1px rgba(72, 72, 72, 0.123);
    --box-shadow-summary-card: 0.5px 0.5px 1px 1px rgba(60, 77, 120, 0.132);

    --card-padding: 10px;
    --card-shadow: 0.5px 0.5px 2px 1px rgba(9, 21, 48, 0.426);
    --card-shadow-hover: 0.5px 0.5px 5px 1px rgba(72, 72, 72, 0.303);
    --card-shadow-select: 0.5px 0.5px 5px 1px rgba(72, 72, 72, 0.495);
    --card-border-radius: 4px;
    --card-border: 0px;
    --card-margin-bottom: 10px;
    --card-fontsize-title: 19px;
    --card-fontsize-subtitle: 15px;
    --card-fontsize-description: 13px;
    --card-fontweight-title: 600;
    --card-fontweight-subtitle: 400;
    --card-fontweight-description: 300;

    --color-main-bg: linear-gradient(135deg, #389ce4 0%, #e2d1c3 100%);
    --color-nav: linear-gradient(180deg, #37396d 0%, #6585f0 100%);
    --color-card-bg: rgba(17, 25, 46, 0.262);
    --color-card-bg-hover: rgba(46, 51, 65, 0.139);
    --color-card-bg-select: rgba(46, 51, 65, 0.228);
    --color-card-text: #fff;
    --color-dash-input-bg: rgba(242, 242, 242, 0.119);
    --color-dash-input-text: rgb(0, 0, 0);
    --color-dash-input-placeholder: rgba(255, 255, 255, 0.31);
    --color-nav-input-text: #fff;
    --color-nav-input-bg: rgba(255, 255, 255, 0.119);
    --color-nav-input-placeholder: rgba(255, 255, 255, 0.31);
    --color-nav-section-label: #fff;
    --color-nav-back: rgb(32, 33, 36);
    --color-selected-bg: rgb(70, 142, 220);
    --color-selected-text: #fff;
    --color-focus-body-label: #000;
    --color-bg-save-changes-btn: rgb(48, 130, 216);
    --color-text-save-changes-btn: #fff;
    --color-text-primary: #000;
    --color-dash-sub-label: #000;
    --color-dash-input-label: #000;
    --color-dash-input-text: #000;
    --color-dash-input-placeholder: rgba(0, 0, 0, 0.365);
    --color-divider-dark: rgb(201, 201, 201);
    --color-bg-delete-btn: rgb(240, 55, 55);
    --color-text-delete-btn: #fff;
    --color-bg-remove-arrival-btn: rgb(55, 60, 72);
    --color-modal-layover: rgba(0, 0, 0, 0.358);
    --color-modal-container: #fff;
    --color-bg-analytics-item: rgb(255, 255, 255);
    --color-text-analytics-item: rgb(0, 0, 0);
    --color-check-bg: rgb(72, 139, 210);
    --color-bg-summary-tag: rgb(216, 222, 235);
    --color-text-summary-tag: rgb(113, 121, 135);
    --color-background-modal-summary-section: rgba(243, 246, 249, 0.824);
    --color-text-summary-card-name: rgb(47, 50, 53);
    --color-text-summary-card-pos: rgb(77, 87, 104);
    --color-text-summary-group-label: rgb(77, 87, 104);
    --color-bg-check-in: linear-gradient(-20deg, #3a475bf9 0%, #2c2f35f0 100%);


    --columns-dash-slot-item-topline: 100px 70px auto 200px;
    --columns-dash-time-inputs: 1fr 1fr 1fr;
    --columns-das-one: 1.5fr 1fr 1fr;
    --columns-das-two: 1.5fr 1fr 1fr;
    --columns-dash-slot-item-row-one: 80px 50px auto 120px 120px;

    --column-gap-das-one: 20px;

    --font-size-nav-input: 19px;
    --font-size-nav-group-header: 21px;
    --font-size-nav-back: 19px;
    --font-size-nav-section-label: 17px;
    --font-size-focus-body-label: 17px;
    --font-size-dash-slot-item: 19px;
    --font-size-dash-sub-section-header: 19px;
    --font-size-dash-sub-label: 21px;
    --font-size-dash-input-label: 15px;
    --font-size-button: 15px;
    --font-size-summary-tag: 13px;
    --font-size-summary-card-pos: 13px;
    
    --font-weight-nav-back: 800;
    --font-weight-nav-input: 600;
    --font-weight-nav-section-label: 600;
    --font-weight-focus-body-label: 800;
    --font-weight-dash-slot-item-name: 600;
    --font-weight-dash-sub-section-header: 700;
    --font-weight-dash-sub-label: 800;
    --font-weight-dash-input-label: 500;
    --font-weight-button: bold;
    --font-weight-summary-card-name: 800;

    --height-dash-slot-item-avatar: 50px;
    --height-divider-dark: 1px;

    --mar-focus-tag-add-item: 5px 5px;
    --mar-focus-tag-item: 5px 10px 5px 0px;
    --mar-dash-slot-item-avatar: 5px 10px 5px 0px;

    --mar-bottom-back: 20px;
    --mar-bottom-nav-section-label: 10px;
    --mar-bottom-focus-body-section: 20px;
    --mar-bottom-focus-body-label: 10px;
    --mar-bottom-dash-sub-section: 20px;
    --mar-bottom-dash-sub-section-header: 15px;
    --mar-top-dash-sub-content-container: 20px;

    --max-height-focus-tag-add: 500px;

    --width-focus-tag-add-item: max-content;
    --width-focus-tag-item: max-content;

}

#white {
    color: #fff;
}


/* COMMON */

.padding-sides-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.drop-open {
    z-index: 2;
}

.half-opacity {
    opacity: 0.3;
}

.dash-talent-schedule-window p {
    margin: 0px;
    padding: 0px;
}

.dash-add-slot-container {
    margin-top: 20px
}

.dash-add-slot-container p {
    margin: 0px;
    padding: 0px;
}

.cur-point {
    cursor: pointer;
}

.margin-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.nav {
    padding: var(--padding-nav);
    width: 350px;
    height: 100vh;
    transition: all 0.2s;
}

@media (max-width: 550px) {
    .nav {
        width: 100vw;
        transition: all 0.2s;
    }
}

.nav-back-btn {
    font-size: var(--font-size-nav-back);
    font-weight: var(--font-weight-nav-back);
    color: var(--color-nav-back);
    margin-bottom: var(--mar-bottom-back);
}


.nav-color {
    background: var(--color-nav);
    box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.304);
}

.nav-content {
    width: 100%;
    padding: var(--padding-nav);
}

.nav-section {
    padding: var(--padding-nav-section);
    width: 100%;
}

.nav-section-label {
    font-size: var(--font-size-nav-section-label);
    font-weight: var(--font-weight-nav-section-label);
    color: var(--color-nav-section-label);
    margin-bottom: var(--mar-bottom-nav-section-label);
}


.nav-input {
    width: 100%;
    height: var(--height-nav-input);
    padding: var(--padding-nav-input);
    border-radius: var(--border-radius-nav-input);
    border: var(--border-nav-input);
    color: var(--color-nav-input-text);
    background: var(--color-nav-input-bg);
    font-size: var(--font-size-nav-input);
    font-weight: var(--font-weight-nav-input);
}

.nav-input:focus {
    outline: none;
    box-shadow: var(--box-shadow-nav-input-focus);
}

.nav-input::placeholder {
    color: var(--color-nav-input-placeholder);
}

.nav-section {
    padding: var(--padding-nav-section);
    position: relative;
}

#session-nav-section {
    padding-left: 0px;
    padding-right: 0px;
}

.nav-position-search {
    max-height: 500px;
    overflow: auto;
}

.nav-section-title-input {
    height: 50px !important;
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 600;
    transition: all 0.2s;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer;
    background: rgba(18, 18, 18, 0.125) !important;
    border: 0px !important;
    color: #fff;
    width: 100%;
}
.nav-section-title-input::placeholder {
    color: rgba(255, 255, 255, 0.208);
}


.nav-item-card {
    padding: var(--card-padding);
    box-shadow: var(--card-shadow);
    border-radius: var(--card-border-radius);
    border: var(--card-border);
    margin-bottom: var(--card-margin-bottom);
    background: var(--color-card-bg);
    color: var(--color-card-text);
}

.nav-item-card-title {
    font-size: var(--card-fontsize-title);
    font-weight: var(--card-fontweight-title);
}

.nav-item-card-subtitle {
    font-size: var(--card-fontsize-subtitle);
    font-weight: var(--card-fontweight-subtitle);
}

.nav-item-card-description {
    font-size: var(--card-fontsize-description);
    font-weight: var(--card-fontweight-description);
}

.nav-group-label {
    font-size: var(--font-size-nav-group-header);
}

.nav-min-dash {
    width: 60px !important;
    transition: all 0.2s;
}

.nav-min-mobile-dash {

    width: 5px !important;
    transition: all 0.2s;
}

.nav-toggle {
    position: absolute;
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 100px;
    right: -25px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.119);
}

.nav-toggle-mobile {
    position: absolute;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 100px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.119);
    top: 5px;
    right: 5px;
}

.ntg-right {
    top: 5px;
    right: -97vw;
}

.focus-tag-add-container {
    box-shadow: var(--box-shadow-focus-tag-add);
    padding: var(--padding-focus-tag-add);
    border-radius: var(--border-radius-focus-tag-add);
    max-height: var(--max-height-focus-tag-add);
    overflow: hidden;
}

.focus-tag-add-container {
    box-shadow: none;
}

.focus-tag-add-list {
    overflow: auto;
}

.focus-tag-add-item {
    box-shadow: var(--box-shadow-focus-tag-add-item);
    padding: var(--padding-focus-tag-add-item);
    border-radius: var(--border-radius-focus-tag-add-item);
    width: var(--width-focus-tag-add-item);
    display: inline-block;
    margin: var(--mar-focus-tag-add-item);
}

.candidate-tags .mob-tag-it {
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 28px;
    padding-top: 2px;
}

.mob-tag-it {
    border-radius: 100px;
    height: 34px;
    font-size: 17px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    padding-top: 4px;

}

.selected-item {
    background: var(--color-selected-bg);
    color: var(--color-selected-text);
}

.focus-tag-list {
    margin-bottom: 20px;
}

.focus-tag-list-item {
    box-shadow: var(--box-shadow-focus-tag-item);
    padding: var(--padding-focus-tag-item);
    border-radius: var(--border-radius-focus-tag-item);
    width: var(--width-focus-tag-item);
    display: inline-block;
    margin: var(--mar-focus-tag-item);
}

:root {
    --padding-focus-tag-item-mobile: 2px 10px 2px 10px;
}

@media (max-width: 550px) {
    .focus-tag-list-item {
        box-shadow: var(--box-shadow-focus-tag-item);
        padding: var(--padding-focus-tag-item-mobile);
        border-radius: 100px;
        width: var(--width-focus-tag-item);
        display: inline-block;
        margin: var(--mar-focus-tag-item);
    }
}


.focus-body-section {
    display: grid;
    grid-template-columns: 1fr 45px;
    column-gap: 15px;
    margin-bottom: var(--mar-bottom-focus-body-section);
}

.focus-body-label {
    font-size: var(--font-size-focus-body-label);
    font-weight: var(--font-weight-focus-body-label);
    margin-bottom: var(--mar-bottom-focus-body-label);
    color: var(--color-focus-body-label);
}

.focus-time {
    display: grid;
    grid-template-columns: 100px 100px;
}

.arrival-card .focus-time {
    grid-template-columns: auto;
}


.mobile-host-nav {
    position: absolute;
    height: 100vh;
}



.arrival-card-settings {
    margin-top: 10px;
    width: 284px;
}


.inline {
    display: inline-block;
}

.card-white {
    background: #fff;
    color: var(--color-text-primary);
}

.dash-sub-section {
    margin-bottom: var(--mar-bottom-dash-sub-section);
}

.dash-slot-item-avatar {
    object-fit: cover;
    border-radius: 100px;
    height: var(--height-dash-slot-item-avatar);
    margin: var(--mar-dash-slot-item-avatar);
}

.dash-slot-item-name {
    font-weight: var(--font-weight-dash-slot-item-name);
    font-size: var(--font-size-dash-slot-item);
}

.dash-slot-item-topline {
    display: grid;
    grid-template-columns: var(--columns-dash-slot-item-topline);
}

.dash-sub-content-container {
    margin-top: var(--mar-top-dash-sub-content-container);
}

.dash-sub-section-header {
    font-size: var(--font-size-dash-sub-section-header);
    font-weight: var(--font-weight-dash-sub-section-header);
    color: var(--color-dash-sub-section-header);
    margin-bottom: var(--mar-bottom-dash-sub-section-header);
}

.dash-sub-label {
    font-size: var(--font-size-dash-sub-label);
    font-weight: var(--font-weight-dash-sub-label);
    color: var(--color-dash-sub-label);
}

.dash-time-inputs {
    display: grid;
    grid-template-columns: var(--columns-dash-time-inputs);
}

.dash-input-label {
    font-size: var(--font-size-dash-input-label);
    font-weight: var(--font-weight-dash-input-label);
    color: var(--color-dash-input-label);
    margin-bottom: 5px !important;
}

.dash-input {
    height: var(--height-dash-input);
    padding: var(--padding-dash-input);
    border-radius: var(--border-radius-dash-input);
    border: var(--border-dash-input);
    color: var(--color-dash-input-text);
    background: var(--color-dash-input-bg);
    font-size: var(--font-size-dash-input);
    font-weight: var(--font-weight-dash-input);
    width: 100%;
}

.time {
    width: 50px !important;
    text-align: center;
}

.dash-input:focus {
    outline: none;
    box-shadow: var(--box-shadow-dash-input-focus);
}

.dash-input::placeholder {
    color: var(--color-dash-input-placeholder);
}

#add-to-schedule {
    width: 150px;
    display: block;
    margin-left: auto;
}

.dash-add-slot-container {
    background: var(--nav-item-card)
}

.dash-add-slot-inputs {
    display: grid;
}

.grid {
    display: grid;
}

#add-slot-btn {
    position: absolute;
    top: 20px;
    right: 0px;
}

.das-one {
    grid-template-columns: var(--columns-das-one);
    column-gap: var(--column-gap-das-one);
}

.das-two {
    grid-template-columns: var(--columns-das-two);
    column-gap: var(--column-gap-das-one);

}

.divider-dark {
    height: var(--height-divider-dark);
    width: 100%;
    background: var(--color-divider-dark);
    margin-bottom: 15px;
}

.dash-slot-item-row-one {
    grid-template-columns: var(--columns-dash-slot-item-row-one);
    column-gap: var(--column-gap-das-one);
}

.dash-slot-item-row-two {
    grid-template-columns: var(--columns-das-two);
    column-gap: var(--column-gap-das-one);
}

.invite-modal-container {
    position: absolute;
    right: 0px;
    height: 200px;
    top: -0px;
    width: 300px;
    border-radius: 4px;
    box-shadow: var(--box-shadow-focus-tag-item);
    background: #fff;
    z-index: 10;
    padding: 20px;
}

.download-app {
    box-shadow: var(--box-shadow-focus-tag-item);
    padding: 15px;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 200px;
    text-align: center;
    font-size: 21px;
    margin-top: 50px;
}

.invite-text {
    font-size: 17px;
}

.invite-bg {
    background: var(--color-main-bg);
    height: 100vh;
    width: 100%;
    padding-top: 100px;
}

.invite-card {
    height: max-content;
    width: auto;
    max-width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.256);
}

.invite-logo {
    height: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.and-its-gone {
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#group-switch {
    position: absolute;
    top: 15px;
    right: 5px !important;
}

.nav-scroller {
    height: 80vh;
    overflow: auto;
}

#nav-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    cursor: pointer;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.139) !important;
}

.content-null-text {
    font-size: 17px;
    width: 100%;
}

.session-item {
    height: auto;
    background: #fff;
    width: auto;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0.5px 0.5px 3px rgba(50, 90, 148, 0.605);
    border-radius: 4px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s;
}

.search-item-label {
    font-weight: bold;
    font-size: 17px;
}

.full-width {
    width: 100%;
}

.search-section {
    margin-bottom: 20px;

}

.search-section-label {
    font-size: 19px;
    margin-bottom: 5px;
}

.searched-item {
    height: auto;
    background: #fff;
    width: auto;
    vertical-align: top;
    margin-bottom: 5px;
    box-shadow: 0.5px 0.5px 3px rgba(50, 90, 148, 0.605);
    border-radius: 4px;
    padding: 10px;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.2s;
    display: grid;
    grid-template-columns: auto 150px;
}

.searched-item button {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 2px 1px rgba(116, 116, 116, 0.116);
    border: 0px;
    transition: all 0.1s;
}

.searched-item button:hover {
    box-shadow: 0.5px 0.5px 2px 1px rgb(143, 143, 143);

}

.searched-item button:focus {
    box-shadow: 0.5px 0.5px 2px 1px rgb(143, 143, 143);
    outline: none;
    border: 1px solid #ccc;
}

.searched-item button:active {
    opacity: 0.2;
}

.session-view-navigate {
    position: fixed;
    right: 70px;
    bottom: 20px;
    width: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.session-view-navigate button {
    height: 40px;
    border-radius: 4px;
}

.session-item-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 50px;
}

.session-item-buttons button {
    box-shadow: 0.5px 0.5px 2px 1px #ddd;
    transition: all 0.1s;
}

.session-item-buttons button:focus {
    outline: none;
    box-shadow: 0.5px 0.5px 2px 1px #bbb;

}
.session-item-buttons button:hover {
    box-shadow: 0.5px 0.5px 2px 1px #bbb;

}
.session-item-buttons button:active {
    box-shadow: 0.5px 0.5px 2px 1px #bbb;
    opacity: 0.5;

}

.cancel-nav-btn {
    background: rgba(0, 0, 0, 0.043);
    color: #fff;
}

.cancel-nav-btn:active {
    opacity: 0.2;
}

.session-item-buttons button {
    height: 40px;
    border-radius: 4px;
    border: 0px;
}

.date-picker-container {
    height: 280px;
}

.session-item:hover {
    box-shadow: 0.5px 0.5px 5px rgba(50, 89, 148, 0.831);

}

.session-item h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 5px;
}

.session-item h3 {
    font-size: 15px;
}


.session-item h4 {
    font-size: 17px;
}



.checkin-body-container .form-input::placeholder {
    opacity: 0.5;
}

.checkin-body-container .form-input:focus {
    outline: none;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.397);
}

.checkin-body-container .label {
    font-size: 29px;
    font-weight: 800;
    margin-bottom: 10px;
}

.check-in-button {
    display: block;
    margin-left: auto;
    margin-top: 40px;
    height: 60px;
    width: 250px;
    border-radius: 4px;
    border: 1px solid #fff;
    color: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.125);
    font-size: 21px;
    font-weight: 700;
    transition: all 0.2s;
}

.check-in-button:focus {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.29);
    outline: none;
}

.check-in-button:active {
    opacity: 0.5;
}


.nav-section-header {
    color: #fff !important;
}

.nav-summary-row {
    margin-top: 20px;
}

.nav-summary-row h5 {
    color: #fff !important;
    font-size: 19px;
}

.nav-summary-row h2 {
    color: #fff !important;
    font-size: 21px;
    font-weight: 800;
}

.nav-toggle-img {
    position: relative;
    height: 50px;
    width: 50px;
}

.arrival-card-icon {
    height: 20px;
    opacity: 0.2;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: all 0.1s;
}

#arrival-display-icon {
    right: 40px;
}

.arrival-card-icon:hover {
    opacity: 1;
}



.content-list-scroll {
    height: 90vh;
    overflow: auto;
    padding-left: 2px;
    padding-right: 2px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(100, min-content);
    column-gap: 10px;
}

@media (max-width: 550px) {
    .content-list-scroll {
        display: block;
        width: 100%;
    }

    .session-item {
        width: 100%;
    }
}

.popup-divider {
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #ccc;
}


/* BUTTONS */

.cancel-note-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    padding: 15px;
    cursor: pointer;
}

.button-box-shadow {
    box-shadow: var(--box-shadow-button);
    transition: all 0.2s;
}
.button-box-shadow:hover {
    box-shadow: var(--box-shadow-button-hover);
}
.button-box-shadow:active {
    opacity: 0.5;
}
.button-box-shadow:focus {
    box-shadow: var(--box-shadow-button-focus);
    outline: none;
}

.save-change-btn {
    border-radius: 4px;
    font-size: var(--font-size-button);
    font-weight: var(--font-weight-button);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    color: var(--color-text-save-changes-btn);
    background-color: var(--color-bg-save-changes-btn);
    z-index: 5;
    transition: all 0.1s;
}

.save-change-btn:hover {
    opacity: 0.5;
}

.save-change-btn:focus {
    outline: 0;
    box-shadow: 0.5px 0.5px 5px 2px rgba(0, 0, 0, 0.262);
}

.save-change-btn:active {
    opacity: 0.2;
}

.save-change-btn:disabled {
    background-color: #ddd;
}

.delete-btn {
    border-radius: 4px;
    font-size: var(--font-size-button);
    font-weight: var(--font-weight-button);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    color: var(--color-text-delete-btn);
    background-color: var(--color-bg-delete-btn);
    z-index: 5;
    transition: all 0.1s;
}

.delete-btn:focus {
    outline: 0;
    box-shadow: 0.5px 0.5px 5px 2px rgba(0, 0, 0, 0.262);
}

.delete-btn:active {
    opacity: 0.2;
}

#m-foc-note-btn {
    font-size: 15px !important;
    margin-left: 5px;
    padding-right: 15px;
    padding-left: 15px;
}

#m-foc-note-btn:disabled {
    background: #ddd;
}

.remove-guest-btn {
    border-radius: 4px;
    font-size: 15px;
    font-weight: var(--font-weight-button);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 150px;
    border: 0px;
    color: var(--color-text-delete-btn);
    background-color: var(--color-bg-remove-arrival-btn);
    z-index: 5;
    margin-left: auto;
    display: block;
}
#save-edit-note-btn {
    position: absolute;
    bottom: -50px;
    right: 0px;
}

#delete-note-btn {
    position: absolute;
    bottom: -50px;
    right: 140px;
}

.add-myself-btn {
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
    border-radius: 100px;
    border: 0px;
    background: rgba(0, 0, 0, 0.146);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.edit-team {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 100px;
    border-radius: 100px;
    border: 0px;
    background: rgba(0, 0, 0, 0.146);
    color: #fff;
}

.edit-team:focus {
    box-shadow: 0px;
}

#cancel-popup {
    width: 100%;
}

#greeting-save {
    position: absolute;
    bottom: 20px;
    width: 150px;
    right: 25px;
    bottom: 10px;
}

.dash-section {
    height: 100vh;
    overflow: auto;
    padding: 2px;
}

@media (max-width: 550px) {
    .dash-section {
        overflow: hidden;
    }
}

.dash-header-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto 295px;
    border-bottom: 1px solid rgba(21, 41, 70, 0.605);
    margin-bottom: 20px;
}

.content-session-btns {
    width: 100%;
    display: grid;
    grid-template-columns: auto max-content;
    column-gap: 5px;
}
.dash-session-btn {
    height: 34px;
    width: 150px;
    border-radius: 2px;
    margin-bottom: 20px;
    font-weight: 500;
    border: 0px;
    box-shadow: 0px 1px 1px 0.2px rgba(0, 0, 0, 0.358);
    background: rgb(42, 196, 142);
    color: #fff; 
}

@media (max-width: 550px) {
    .dash-header-container {
        grid-template-columns: 100%;
    }

    .dash-session-btn {
        width: 100%;
    }
}

.employee-account-btns {
    display: grid;
    grid-template-columns:1fr 1fr;
    column-gap: 10px;
}

.account-btn {
    width: 140px;
}

.dash-session-btn:hover {
    
    box-shadow: 0px 1px 3px 0.2px rgba(0, 0, 0, 0.474);
}

.dash-session-btn:focus {
    outline: none;
    border: 1px solid #000;
}

.dash-session-btn:active {
    opacity: 0.5;
}

.dash-sess-dropdown {
    border: 0px;
    height: min-content;
    position: relative;
    width: min-content;
}

.dash-sess-dropdown .clicked {
    z-index: 1;
}



/* MODALS */

.modal-fullscreen {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0px;
}

.modal-bg {
    height: 100%;
    width: 100%;
    background: var(--color-modal-layover);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    justify-content: center;
}

.modal-card {
    height: 95vh;
    width: 95vw;
    max-width: 1180px;
    margin: auto;
    display: block;
    background: var(--color-modal-container);
    opacity: 1;
    border-radius: 4px;
    z-index: 100;
    position: relative;
    margin-top: 1%;
    overflow: auto;
    padding: 20px;
}


.modal-card h2 {
    font-size: 17px;
}

.analytics-item {
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: var(--box-shadow-analytics-item);
    background: var(--color-bg-analytics-item);
    padding: var(--padding-analytics-item);
    border-radius: 4px;
    color: var(--color-text-analytics-item);
    width: auto;
}

.analytics-type {
    font-weight: 400;
    font-size: 13px !important;
}

.analytics-value {
    font-size: 19px;
    font-weight: bold;
}

.modal-summary-section h3 {
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 20px;
}

.subjects-container-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    grid-template-rows: repeat(100, min-content);
    column-gap: 5px;
    row-gap: 5px;
}

.subjects-container-list {
    display: grid;
    grid-template-columns: auto;
    row-gap: 3px;
}

.summary-card-times {
    position: absolute;
    width: 100%;
    max-width: 350px;
    bottom: 5px;
}

.summary-group-item {
    border-radius: 4px;
}



.summary-card {
    background-color: #fff;
    padding: 20px;
    box-shadow: var(--box-shadow-summary-card);
    border-radius: 4px;
    display: inline-block;
    width: auto;
    position: relative;
    border: 1px solid #eee;
}

.summary-card-times {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 10px;
}

.summary-header img {
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
}

.summary-header {
    display: grid;
    grid-template-columns: 60px auto;
}

.summary-header-nohs {
    display: grid;
}

.summary-card-name {
    font-size: var(--font-size-summary-card-name);
    font-weight: var(--font-weight-summary-card-name);
    color: var(--color-text-summary-card-name);
}

.summary-card-pos {
    font-size: var(--font-size-summary-card-pos);
    font-weight: var(--font-weight-summary-card-pos);
    color: var(--color-text-summary-card-pos);
}

.modal-check-box {
    display: grid;
    grid-template-columns: 20px auto;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 70px
    
}

.modal-summary-settings-label {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    vertical-align: top;
}

.modal-check-text {
    font-size: 15px;
    margin-top: -4px;
}
.modal-check-box .check-on {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    background: var(--color-check-bg);
    border: var(--color-check-bg);
}

.modal-check-box .check-off {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid rgb(182, 182, 182);
}

.modal-summary-section {
    position: relative;
    border-radius: 4px;
    margin-bottom: 20px;
}

.modal-summary-section-bg {
    padding: 5px;
    height: auto;
    background: #fafafa;
}


.modal-summary-section-bg {

}

.modal-summary-people-settings {
}

.analytics-container {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

}

.modal-summary-group-select {
    width: 180px; 
    z-index: 10;
    position: relative;
    margin-right: 5px;
    margin-bottom: 5px;
}

.summary-card-body-section {
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-summary-sort-option {
    font-size: 14px;
    vertical-align: top;
    margin-right: 10px;
    cursor: pointer;

}

.modal-summary-sort-option:hover {
    opacity: 0.5;
}



.summary-tag {
    display: inline-block;
    border-radius: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    background: var(--color-bg-summary-tag);
    color: var(--color-text-summary-tag);
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: var(--font-size-summary-tag);
    font-weight: 600;

}

.summary-card-divider {
    margin-top: 5px;
    height: 2px;
    background: #eee;
}

.summary-note {
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 15px auto;
}

.summary-bullet {
    height: 5px;
    width: 5px;
    background: #ccc;
    border-radius: 100px;
}

.summary-note-text {
    font-size: 17px;
    margin-top: -10px;
}

.summary-note-by {
    font-size: 13px;
    opacity: 0.4;
}

.quick-menu-customize-greeting-textarea {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    display: block;
    height: 300px;
    white-space: pre-wrap;
    padding: 8px;
    border-radius: 4px;
    border: 0px;
    box-shadow: 0.5px 0.5px 5px 1px rgb(32, 52, 87);
}

.spacing-20 {
    margin-right: 20px;
}

.closeit {
    height: 30px;
    padding: 3px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.min-content {
    height: min-content;
    grid-template-rows: auto;
    margin-bottom: 30px;
}

.min-content h3 {
    font-size: 21px;
    font-weight: 700;
    margin-top: 5px;
    color: var(--color-text-summary-group-label);
}

.copy-list-buttons {
    position: absolute;
    top: -5px;
    right: 0px;
}

.copy-list-buttons button {
    margin-right: 5px;
    font-size: 15px;
}

.modal-card h1 {
    font-size: 25px;
    font-weight: bold;
}

:root {
    --box-shadow-add-plus-button: 0.5px 0.5px 5px 1px rgba(0, 0, 0, 0.043);
}

.add-elements-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: grid;
    grid-template-rows: 70px 70px;
}
.add-elements-container img {
    height: 60px !important;
}

.add-plus-button:focus {
    outline: none;
}

.add-plus-button:active {
    opacity: 0.2;
}

.add-plus-icon {
    height: 60px;
    width: 60px;
    margin: 0px;
    transition: all 0.2s;
}


.add-plus-icon:active {
    opacity: 0.2;
}

.close-magnify-button {
    position: absolute;
    top: 20px;
    right: 15px;
    background: rgb(41, 88, 176);
    color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 1px #ccc;
    border: 0px;
    height: 36px;
    font-size: 17px;
}



/* IMAGES */

.company-logo-container {
    height: 250px;
    width: auto;
    background: #fff;
    padding: 20px;
    text-align: center;
}

.container-image-company-logo-settings {

    
    display: block;
    margin-left: auto;
    
}
.image-company-logo-settings {
    height: 150px;
    object-fit: cover;
}

.check-in-company-logo {
    height: 80px;
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 15px;
}



/* NEW CHECK IN */


.check-in-bg {
    background: var(--color-bg-check-in);
    height: 100vh;
    padding-top: 10px;
}

.form-card-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    max-width: 800px;
    position: relative;
}

@media (max-width: 550px) {
    .form-card-container {
        width: 100vh;
    }
}

.check-in-header {
    height: 150px;
}

.form-position-item {
    height: 60px;
    display: inline-block;
    width: max-content;
    margin-right: 10px;
    border-radius: 4px;
    border: 0px;
    margin-bottom: 8px;
    font-size: 23px;
    font-weight: 800;
    padding-top: 13px;
    padding-left: 20px;
    padding-right: 100px;
    cursor: pointer;
    transition: all 0.2s;
    background: #fff;
    color: rgb(84, 124, 194);
    text-align: left;
}



.input-holder {
    width: 100%;
    padding: 20px;
    position: relative;
}

.check-in-label {
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 10px;
    color:#fff;
    position: absolute;
    top: 40px !important;
    left: 40px;
    transition: all 0.1s;
    opacity: 0.5;
}

.check-in-label-question {
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 10px;
    color: #fff;
}

.top-left-check {
    font-size: 15px;
    top: -10px !important;
    left: 20px;
    opacity: 1;
}

.checkin-body-container .form-input {
    width: 100%;
    height: 80px;
    border-radius: 4px;
    padding: 15px;
    font-size: 29px;
    font-weight: 800;
    text-align: left;
    border: 0px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: left;
    background: rgba(0, 0, 0, 0.057);
    color: #fff;

}


.checkin-body-container .form-input::placeholder {

    color: #fff;
}

#positions-list {
    margin-top: -20px;
}

.question-button {
    margin-left: auto;
    display: block;
    margin-top: 40px;
    width: 140px;
    height: 50px;
    font-size: 21px;
    background: rgb(42, 83, 133) !important;
    border-radius: 4px;
    border: 0px;
    color: #fff;
}

.form-position-item:hover {
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.296);
}

.form-position-item:active {
    opacity: 0.5;
    background: #000;
}

.check-in-company-name {
    font-size: 31px;
    font-weight: 800;
    color: #fff;
    position: absolute;
    top: 50px;
    left: 130px;
}

.check-in-instruction {
    margin-top: 5px;
    font-size: 21px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 90px;
    left: 130px;
}

.check-in-thanks {
    margin-right: auto;
    margin-left: 20px;
    display: block;
    white-space: pre-wrap;
    font-size: 25px;
    font-weight: 500;
    width: 100%;
    color: #fff;
}

.alert-window {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 100;
    width: 300px;
}


.mobile-magnify-divider {
    height: 5px;
    background: #eee;
    margin-top: 10px;
    margin-bottom: 10px;
} 


#condense-mobile-img {
    height: 35px;
    width: 35px;
}

.absolute-textbox-mobile {
    position: relative;
    height: 100%;
    width: 100vw;
    padding: 10px;
    top: 10px;
}


.f-add-note-box {
    width: 100%;
}

.mobile-add-note-box {
    position: relative;
}

.mobile-focus-add-note-buttons {
    position: absolute;
    bottom: 20px;
    right: 0px;
}



.focus-textarea-new {
    width: 100%;
    height: 150px;
    transition: all 0.2s;
    border: 0px;
    box-shadow: 0.0px 0.0px 2px 2px rgba(0, 0, 0, 0.111);
    border-radius: 4px;
    padding: 10px;
    font-size: 19px;
}

@media (max-width: 550px) {
    .focus-textarea-new {
        height: 200px;
        border-bottom: 1px solid #eee;
        box-shadow: none;
    }
}


.focus-textarea-new:focus {
    outline: none;
}

.done-tag {
    margin-left: auto;
    display: block;
    border: 0px;
    box-shadow: 0.5px 0.5px 2px 1px #ccc;
    border-radius: 4px;
    background: rgb(41, 88, 176);
    color: #fff;
    border: 0px;
    height: 35px;
    position: absolute;
    top: 0px;
    right: 0px;
}


.guest-nav-container {
    position: absolute;
    width: 100vw;
    bottom: 10px;
    z-index: 20;

}

.line-button-img {
    object-fit: cover;
    height: 30px;
    width: 30px;
}

.guest-nav-button {
    border: none;
    background: rgb(84, 141, 240);
    border-radius: 100px;
    width: 60px;
    height: 60px;
    padding: 0px;
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    box-shadow: 0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.461);
    transition: all 0.2s;
}

.guest-nav-button:focus {
    outline: none;
}

.guest-nav-button:active {
    opacity: 0.2;
}

.guest-navigator-background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.838);
    z-index: 3;
    padding-top: 30px;
}

.guest-nav-subject {
    padding: 10px;
    padding-left: 30px;
    color: #fff;
    font-size: 21px;
    font-weight: bold;
}

.mobile-note-edit {
    position: absolute;
    right: -5px;
    height: 15px;
}

#archive-position {
    display: block;
    margin-top: 10px;
    border: 2px solid rgba(255, 255, 255, 0.349);
}

.selected-tag-item {
    box-shadow: 2px 2px 5px 1px #ccc !important;
    background: #164e86 !important;
    color: #fff;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
}

.session-item-label {
    font-weight: bold;
    font-size: 13px;
    margin-top: 10px;
}

#filter-sessions-filter {
    position: absolute;
    background: #fff;
    width: 95%;
    top: 40px;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.358);
}

.dash-search-bar {
    width: 300px;
    margin-bottom: 10px;
}

@media (max-width: 550px) {
    .dash-search-bar {
        width: 100%;
    }
}

#title-session {
    background: #fff !important;
    color: #000;
    border: 1px solid #fff;
}

#title-session:focus {
    outline: none;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.358);
}

.nav-input-label-title {
    color: #fff;
}

.open-full-summary {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 0px;
    font-weight: 500;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.166);
}

.send-invite-session {
    margin-top: 10px;
    border-radius: 4px;
    border: 0px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    margin-left: auto;
    background: rgba(0, 0, 0, 0.392);
    color: #fff;
}

.send-invite-session:disabled {
    opacity: 0.2;
}

.no-subjects-container {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: 100px;
    background: #E9ECF0;
    width: 100%;
}
.no-subjects-container img {
    height: 200px;
    margin-top: 100px;

}

.no-subjects-container div {
    margin-top: 30px;
    font-size: 19px;
    opacity: 0.5;
}

.search-buttons {
    margin-top: 5px;
    display: grid;
    grid-template-columns: max-content max-content max-content;
    column-gap: 5px;
}

.search-buttons button {
    border: 0px;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 3px 1px #ccc;

}

.search-buttons button:focus {
    outline: none;
    box-shadow: 0.5px 0.5px 3px 1px rgb(167, 167, 167);
    
}
.search-buttons button:active {
    opacity: 0.5;
}

.search-buttons button:disabled {
    opacity: 0.2;
    box-shadow: none;
}

.nav-userbox {
    padding: 10px;
}

.nav-userbox h2 {
    font-size: 21px;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.454);
    margin: 0px;
}

.disable-account-container {
    width: 100%;

}

#disable-account {
    width: 100%;
    background: rgb(145, 145, 145);
}

#disable-account:hover {
    background: rgb(233, 17, 17) !important;
}

#remove-color {
    background: rgb(233, 17, 17) !important;
    margin-bottom: -10px;
}

.are-you-sure-btns {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 10px;
}

.password-forget-padding {
    padding: 20px;
}

.scroll-90 {
    height: 80vh;
    overflow: auto;
}

.pos-focus {
    font-size: 21px;
}

.candidate-item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 2px 1px rgba(132, 141, 155, 0.084);
    padding: 8px;
    margin-bottom: 3px;
    transition: all 0.2s;
    height: max-content;
}

.candidate-item header {
    display: grid;
    grid-template-columns: 50px auto 160px;
    
}

.candidate-scroller {
    border-radius: 4px;
    background: rgba(20, 34, 63, 0.043);

    overflow: auto;
}

.candidate-count {
    display: grid;
    grid-template-columns: 25px 30px;
}

.padding-2 {
    padding: 3px;
}

.candidate-item .profilepic {
    object-fit: cover;
    height: 40px;
    border-radius: 100px;
    margin-right: 10px;
}

.candidate-item h1 {
    font-size: 17px;
    margin: 0px;
}
.candidate-item h2 {
    font-size: 13px;
    margin: 0px;
    margin-top: 3px;
}

.candidate-icon {
    height: 16px;
    margin-top: 5px;
}

.candidate-count h2 {
    font-size: 17px;
}

.candidate-counts {
    vertical-align: top;
    margin-left: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 7px;
}

.candidate-body {
    margin-left: 50px;
}

.candidate-tags {
    margin-left: 50px;
}

.can-label {
    font-size: 11px;
    font-weight: bold;
    opacity: 0.2;
}