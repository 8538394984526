.page-header {
    position: absolute;
}

.home-bg {
    width: 100%;
}

.modals-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}


/*
    background: rgb(153,208,245);
    background: linear-gradient(180deg, rgba(153,208,245,1) 0%, rgba(62,149,218,1) 100%);
    background: rgb(184,179,241);
    background: linear-gradient(90deg, rgba(184,179,241,1) 0%, rgba(244,227,198,1) 100%);
*/

.background-page {
    background-image: linear-gradient(to top, #e6e9f0 0%, #f6f7f9 100%); 
    display: grid;
    grid-template-columns: max-content auto;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.left-side {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    padding-top: 16px;
    position: relative;
    padding-bottom: 50px;
}

.left-side::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE and Edge */
.left-side {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.right-side {
    height:100vh;
    position: relative;
}
/*
@media (min-width: 0px) {
    .room-list-container {
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr);
    height: min-content;
    row-gap: 10px;
    overflow-y: scroll;
    margin-left: 10px;
    margin-right: -50px;
    padding-right: 50px;
    border-radius: 4px;
    }

    .room-list-container-single {
        margin-left: auto;
        margin-right: auto;
        margin-left: 10px;
        display: grid;
        grid-template-columns: minmax(1fr, 350px);
        grid-template-rows: repeat(1fr);
        height: 90%;
        row-gap: 10px;
        overflow-y: scroll;
        margin-right: -50px;
        padding-right: 50px;
        border-radius: 4px;
        }
}

@media (min-width: 900px) {
    .room-list-container {
    margin-left: 15px;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(1fr);
    row-gap: 10px;
    height: min-content;
    overflow-y: scroll;
    margin-right: -50px;
    padding-right: 50px;
    border-radius: 4px;
    }

    .room-list-container-single {
        margin-left: 15px;
        margin-right: auto;
        display: grid;
        grid-template-columns: 400px auto;
        grid-template-rows: min-content min-content;
        row-gap: 10px;
        height: 90%;
        overflow-y: scroll;
        margin-right: -50px;
        padding-right: 50px;
        border-radius: 4px;
        }
}

@media (min-width: 1200px) {
    .room-list-container {
    margin-left: 15px;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(1fr);
    row-gap: 10px;
    height: min-content;
    overflow-y: scroll;
    margin-right: -50px;
    padding-right: 50px;
    border-radius: 4px;
    }

    .room-list-container-single {
        margin-left: 15px;
        margin-right: auto;
        display: grid;
        grid-template-columns: 400px auto;
        grid-template-rows: repeat(1fr);
        row-gap: 10px;
        height: 90%;
        overflow-y: scroll;
        margin-right: -50px;
        padding-right: 50px;
        border-radius: 4px;
        }
}

@media (min-width: 1600px) {
    .room-list-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: repeat(1fr);
    }

    .room-list-container-single {
        display: grid;
        grid-template-columns: 350px auto;
        grid-template-rows: repeat(1fr);
    }
}
*/

.current-employee-modal {
    margin-left: 35px;
}


.room-icon {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 14px;
    left: 15px;
}

.roomCards:hover {
    box-shadow: .2px 1px 2px 1px rgba(122, 128, 136, 0.123);
}

.room-card-content {
    display: grid;
    grid-template-rows: min-content min-content;
}

.room-card-nav {
    height: 100%;
    padding-top: 10px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0px;
    right: 0px;
}

.room-project {
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    width: auto;
    height: auto;
    margin-bottom: 5px;
}

.room-readers-container {
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 2px;
}

.room-readers-list {
    margin-top: 5px;
}

.room-card-roles {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-right: 3px;
}

.room-reader-container {
    display: inline-block;
    height: 27px;
    width: 27px;
    background: rgb(200, 206, 221);
    border-radius: 100px;
    text-align: center;
    padding-top: 3.5px;
    padding-right: .5px;
    margin-right: 3px;
}


.room-reader {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    letter-spacing: -0.02rem;
}

.display-active-talent {
    margin-top: 15px;
    height: 80px;
    border-radius: 4px;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 5px;
}

.display-active-talent-inactive {
    margin-top: 15px;
    height: 30px;
    border-radius: 4px;
    width: 100%;
    padding-right: 10px;
    margin-bottom: 5px;
}

.active-talent-card {
    background-color: #fff;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    padding: 5px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
}

.room-available {
    text-align: left;
    margin-top: 20px;
    font-weight: 500;
    color: rgb(233, 235, 240);
}

.active-talent-contents {
    margin-left: 8px;
    margin-top: 3px;
}

.room-card-check-in-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
}
.room-card-check-in-project {
    font-size: 15px;
    margin-bottom: -4px;
    font-weight: 500;
}
.room-card-check-in-role {
    font-size: 14px;
    font-weight: 400;
}

.avatar-sample {
    width: 0px;
    height: 0px;
    border-radius: 100px;
    background-color: rgb(255, 255, 255);
    margin-right: 5px;
}


.roomCardHeaderText {
    opacity: 1;
    font-size: 19px;
    font-weight: bold;
    color: rgb(93, 96, 100);
    margin-top: 10px;
    margin-bottom: 0px;
}

.roomCards-active {
    box-shadow: 0 5px 5px 2px rgba(165, 165, 165, 0.3), 0 5px 5px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    width: 260px;
    min-height: 200px;
    max-height: 500px;
    border: none;
    border-radius: 4px;
}

.room-card-body {
    color: #000;
    border-radius: 0px 0px 8px 8px;
    padding-bottom: 10px;
}

.room-card-label {
    font-size: 12px;
    font-weight: 600;
    color: rgba(96, 104, 122, 0.445);
    margin-bottom: 5px;
}

.room-card-header {
    border-radius: 8px;
    cursor: pointer;
}

.room-card-header-active {
    padding: 10px;
    color: #fff;
    align-items: center;
}

.room-projects-container {
    margin-top: 5px;
}

.openRoomBtn {
    position: absolute;
    top: 15px;
    right: 6px;
    display: grid;
    grid-template-rows: 40px 40px;
    height: 30px;
    border-radius: 4px;
    border: none;
    width: 30px;
    transition: all 0.5s;
    cursor: pointer;
}

.expand-icon {
    height: 22px;
    width: 23px;
}
.edit-icon {
    height: 15px;
    width: 15px;
    opacity: 0.2;
    transition: all 0.1s;
    margin-left: 5px;
}

.edit-icon:hover {
    opacity: 1;
    transition: all 0.1s;
}



.room-card-buttons-container{
    width: 100%;
    padding: 0px;
    display: grid;
    grid-template-columns: 90%;
    margin-top: 5px;
}

.completeBtn {
    border-radius: 4px;
    background-color: #51df91;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    height: 35px;
    transition: all 0.1s;
    cursor: pointer;
    width: 50%;
}

.assignBtn {
    border-radius: 4px;
    background-color: #5bbcf9;
    border: 0px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    height: 30px;
    transition: all 0.1s;
    cursor: pointer;
    width: 50%;
}

.assignBtn:disabled {
    background-color: rgb(218, 222, 230);
}

.assignBtn:hover {
    background-color: #285d80;
    color: #ffffff;
}

.assignBtn:disabled:hover {
    background-color: #ccc;
}

.completeBtn:hover {
    background-color: #288048;
    color: #ffffff;
}

/* MODALS */

.room-modal {
    width: 800px;
}

.room-modal-header {
    height: 60px;
    color: #fff;
    background-image: linear-gradient(to bottom right, #50a2ff, #11aff8);
    font-size: 40px;
    font-weight: bold;
}

.room-modal-body {
    padding: 30px;
}

.room-modal-btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: -5px;
    border-radius: 4px;
    background-color: #51df91;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
    width: 25%;
    height: 37px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

.room-modal-select {
    width: 200px;
}

.modal-room-readers-card {
    min-width: 160px;
    max-width: 210px;
    height: 38px;
    font-size: 15px;
    background-color: #a6b3c0;
    color: #fff;
    font-weight: bold;
    border: 0px solid rgb(161, 161, 161);
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 5px;
    margin: 4px;
}

.modal-room-projects-card {
    width: 100%;
    margin-left: -10px;
    margin-top: 10px;
    height: auto;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 15px;
}

.selected-role-container {
    width: 150px;
    margin-left: 20px;
}

.selected-project-role {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: -10px;
    border: none;
    border-radius: 4px;
    background-color: #11aff8;
}

.unselected-project-role {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: -10px;
    border: none;
    border-radius: 4px;
    background-color: #cbced0;
}


/* CHECK IN LIST */

.assign-role-select {
    margin-top: 10px;
    font-size: 12px;
}

.greeting-description {
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 1.3;
    opacity: 0.7;
}

.greeting-message-container {
    width: 96%;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 3px;
    font-size: 14px;
    padding: 8px;
    transition: all 0.2s;
    cursor: pointer;
    border: 0.8px solid #f5f5f5;
}

.greeting-message-container-closed {
    height: 40px;
    width: 95%;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 3px;
    padding: 8px;
    font-size: 12px;
    padding-bottom: 10px;
    transition: all 0.2s;
    cursor: pointer;
    border: 1px solid rgb(223, 231, 240);
}

.greeting-label {
    font-weight: 600;
    margin-bottom: 3px;
}

.greeting-textarea {
    width: 100%;
    height: 50vh;
    border: 0px;
    margin-top: 2px;
    border-radius: 4px;
    white-space: pre-wrap;
    font-size: 17px;
}

.greeting-textarea:focus {
    outline: 0px;
}

.greeting-save {
   width: 100%;
   border-radius: 4px;
   background-color: #51df91;
   color: #fff;
   border: 0px; 
   font-size: 12px;
   font-weight: bold;
   height: 30px;
}

.greeting-save:disabled {
    background-color: #ccc;
}

.greeting-save:disabled:hover {
    background-color: rgb(204, 204, 204);
}

.greeting-save:hover {
    background-color: #34b36d;
}

.greeting-header {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid rgb(224, 230, 235);
    margin-bottom: 10px;
}


.arrowup-icon-greeting {
    height: 15px; 
    width: 15px;
    margin-top: 3px;
}

.arrowdown-icon-greeting {
    height: 15x;
    width: 15px;
    margin-top: 3px;
    transform: rotate(180deg);
}

.minimize-arrow {
    width: 26px;
    height: 26px;
    border-radius: 100px;
    z-index: 6;
    position: absolute;
    top: 12px;
    right: 5px;
    padding-left: 1px;
    padding-top: 1px;
}

.no-opacity {
    opacity: 0;
    transition: all 0.2s;
}

.cursor-over {
    cursor: pointer;
}

.full-opacity {
    opacity: 1;
    transition: all 0.2s;
}


.completed-list-search {
    height: 100%;
}

.search-container {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 15px;
}

.check-in-container {
    height: 100vh;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    border-radius: 2px;
    background-color: #ffffffe2;
    overflow: hidden;
    transition: all 0.5s;
    padding: 2px;
    width: 50px;
    min-width: 50px;
    position: relative;

}

.toggle-arrow {
    height: 14px;
    width: 14px;
    padding: 0px;
    margin-bottom: 2px;
    margin-left: 6px;
    cursor: pointer;
}

.arrow-left {
    transform: rotate(90deg);
    transition: all 0.2s;
}

.arrow-right {
    transform: rotate(270deg);
    transition: all 0.2s;
}

.full-width {
    width: 280px;
    transition: all 0.2s;
}




    .check-in-box {
        height: 100vh;
        overflow-y: auto;
        padding-bottom: 800px;
        transition: all 0.5s;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .check-in-box::-webkit-scrollbar {
        display: none;
    }

    .completed-box::-webkit-scrollbar {
        display: none;
    }

    .completed-box {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 800px;
        transition: all 0.5s;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .check-in-scroll {
        width: 100%;
        -ms-overflow-style: none;
    }

    .check-in-scroll::-webkit-scrollbar {
        display: none;
    }

.check-in-card-container {
    width: 100%;
    height: auto;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
}

.check-in-cards {
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.144);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border: 0px;
    height: auto;
    padding: 8px;
    padding-left: 12px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    width: 100%;
    cursor: default;

}

.tall-padding-bottom {
    height: 300px;
}

.completed-list-search .check-in-cards:hover {
    box-shadow: 1px 1px 2px 2px rgba(148, 155, 163, 0.336);
    margin-bottom: 8px;
    cursor: pointer;
}

#room-page-check-in-cards .check-in-cards:hover {
    box-shadow: 1px 1px 2px 2px rgba(148, 155, 163, 0.384);
    margin-bottom: 8px;
    cursor: pointer;
}

.check-in-card-name {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -3px;
}

.quick-select {
    height: 40px;
    box-shadow: 0.5px 1px 3px 1px #eee;
    padding-top: 10px;
    padding-left: 8px;
}

.quick-select:active {
    opacity: 0.5;
}

.check-in-card-line-two {
    font-size: 12px;
    display: inline-block;
    font-weight: 500;
    margin-right: 5px;
    color: rgb(85, 89, 104);
}

.check-in-times-container {
    float: right;
}

.check-in-card-time {
    font-size: 12px;
    margin-right: 15px;
    color: rgb(161, 161, 161);
}

.check-in-card-time-complete {
    float: right;
    font-size: 12px;
    color: rgb(161, 161, 161);
}

.far-right {
    float: right;
}

.red-text {
    color: rgb(217, 156, 156)
}

.green-text {
    color: rgb(136, 219, 158)
}


.check-in-card-rep {
    font-size: 12px;
    font-weight: 500;
    color: rgb(157, 162, 182);
}

.text-bold {
    font-weight: 500;
}

.assign-next-btn {
    width: 35px;
    height: 35px;
    margin-right: 20px;
}

.assign-next-btn-clicked {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    background-color: #51df91;
}

html, body {
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.filter-talent {
    margin-bottom: 5px;
    border-radius: 4px;
    border: 0px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 40px;
    padding-left: 10px;
    opacity: 0.9;
    color: #000;
    font-weight: 500;
}

.filter-talent:focus {
    opacity: 1;
    outline: none;
}

.filter-talent::placeholder {
    color: rgba(78, 78, 78, 0.24);
}

/* Check In Modal Attributes */

.check-modal-header {
    height: 105px;
    color: #fff;
    background-image: linear-gradient(to bottom right, #50a2ff, #11aff8);
}

.check-modal-name {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: -5px;
}

.check-modal-project {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: -3px;
}

.check-modal-role {
    font-size: 14px;
    font-weight: 500;
}

.save-notes-btn {
    width: 150px;
    height: 35px;
    background-color: #51df91;
    border: none;
    border-radius: 4px;
}

.notes-container {
    width: auto;
    background-color: #F8F8F8;
    height: 250px;
    padding: 2px;
    margin-bottom: 5px;
}

.note-box {
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 7px;
    margin: 3px;
}

.note-times {
    font-size: 11px;
    margin-top: -15px;
    margin-bottom: -3px;
}

.note-remove {
    position: absolute;
    right: 0px;
    font-size: 12px;
    font-weight: bold;
    background-color: #e9676f;
    color: #FFF;
    border: none;
    border-radius: 100px;
}

.note-remove:hover {
    background-color: #e63945;
}

.current-user {
    width:  200px;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* CHECK IN LIST TABS */

.home-page-tab-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 5px;
    padding-right: 5px;
    height: 40px;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 12px;
    margin-bottom: 15px;
    color: rgb(82, 97, 109);
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
}


.home-page-tab {
    font-weight: 700;
    color: rgba(82, 97, 109, 0.877);
    border-radius: 100px;
    text-align: center;
    padding-top: 6px;
    transition: all 0.2s;
    color: rgba(82, 97, 109, 0.404);
    transition: all 0.2s;
}

.active-tab {
    background-color: #465e836b;
    color: #fff;
    transition: all 0.5s;
}

.home-page-tab-inactive {
    font-weight: 700;
    color: rgba(82, 97, 109, 0.404);
    text-align: center;
    padding-top: 10px;
    transition: all 0.2s;

}

/* ALERTS */

.alert-panel {
    position: absolute;
    right: 2px;
    top: 00px;
    width: 276px;
    z-index: 10;
}

.alert-card {
    padding: 10px;
    background-color: #000000bb;
    color: #fefeff;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 1px 2px 5px 1px rgba(95, 58, 106, 0.269);
    border-radius: 4px;
    transition: all 0.5s;
    margin-top: 5px;
    border: 0px solid rgb(176, 169, 225);
}

.alert-delete {
    width: 50%;
    height: 40px;
    margin-top: 10px;
    margin-left: auto;
    display: block;
    border-radius: 4px;
    border: 0px;
    font-size: 14px;
    font-weight: bold;
    background-color: #8e8e8e1d;
    color: #fff;
    transition: all 0.5s;
}

.alert-delete:hover {
    font-size: 14px;
    font-weight: bold;
    background-color: #f0f0f0;
    color: #acacac;
}


/* TEXT STYLING */

.text-red {
    color: #c94040;
}

.list-label {
    color: #fff;
    margin-bottom: 5px;
}

/* DATE FILTER */

.date-filter {
    width: 100%;
    margin-bottom:10px;
    display: grid;
    font-size: 13px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    font-weight: bold;
    color: rgba(28, 39, 70, 0.233);
    cursor: pointer;
}

.date-range-select {
    color: rgb(43, 66, 87);
}


.default-null-message {
    padding: 40px;
    text-align: center;
    color: rgb(114, 129, 179);
    opacity: 0.2;
}

.no-rooms-container {
    margin-top: 10px;
    margin-left: 10px;
    height: auto;
    width: 300px;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.247);
    border-radius: 4px;
}

.default-null-message-rooms {
    color: #fff;
    font-size: 146x;
    font-weight: 600;
    margin-bottom: 15px;
}

.room-icon-null {
    height: 50px;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}


.avg-time-container {
    text-align: center;
    font-size: 12px;
    color: rgb(100, 104, 122);
    font-weight: 700;
}


/* ROLES TO ASSIGN */

.roles-to-assign-container {
    margin-top: -20px;
    height: 55px;
}

.roles-to-assign-card {
    background-color: rgba(255, 255, 255, 0.345);
    padding-top: 10px;
    padding-left: 30px;
    height:45px;
}

.roles-to-assign-label {
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    height: 50px;
    top: 10px;
}

.roles-to-assign-list {
    margin-left: 65px;
    z-index: 1;
}
.role-to-assign {
    display: inline-block;
    background-color: #fff;
    box-shadow: 1px 1px 4px 1px #bbb;
    border-radius: 4px;
    margin-left: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    color: rgb(112, 114, 121);
    cursor: grab;
}

.role-to-assign:active {
    opacity: 1;
    transition: all 0.2s;
}

.role-in-block {
}

.card-dropzone {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    background-color: rgba(41, 191, 68, 0.447);
    position: absolute;
    text-align: center;
    padding-top: 100px;
    color: #fff;
}

.null {
    position: absolute;
}

.talent-tags-btn {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: rgb(131, 147, 163); 
    cursor: pointer;
    width: 50px;
    background-color: #fff;
    height: 27px;
    border-radius: 4px;
    padding-top: 5px;
    box-shadow: 1px 1px 2px 1px rgba(204, 204, 204, 0.233);
    position: absolute;
    left: 20px;
    top: 10px;
}

.talent-tags-btn:hover {
    box-shadow: 1px 1px 5px 1px #ccc;
}


.check-in-card-count-container {
    display: grid;
    grid-template-columns: 40px 40px;
    margin-top: 10px;
    margin-left: auto;
}

.check-in-card-count {
    display: grid;
    grid-template-columns: 14px auto;
}

.talent-count-icon {
    height: 12px;
    width: auto;
    margin-top: 2px;
}

.count-number {
    font-size: 11px;
    margin-left: 5px;
}

.open-schedule-modal-btn {
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width: 120px;
    background-color: #fff;
    height: 35px;
    border-radius: 4px;
    padding-top: 7px;
    box-shadow: 1px 1px 4px 1px #ccc;
    position: absolute;
    right: 100px;
    top: 8px;
}



.open-schedule-modal-btn:hover {
    box-shadow: 1px 1px 8px 2px #ccc;
}

.open-schedule-icon {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    margin-bottom: 2px;
}


.power-off-icon {
    height: 13px;
    width: auto;
    margin-top: -2px;
    margin-left: 8px;
    opacity: 0.3;
}

.power-off-icon:hover {
    opacity: 0.6;
    cursor: pointer;
}

.room-dibs-assignment {
    font-size: 12px;
    margin-top: 3px;
    opacity: 1;
    color: rgb(157, 162, 182);
}

.session-card-date {
    font-size: 15px;
    font-weight: 400;
}

.create-new-session-btn {
    text-align: center;
    padding-top: 5px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    width: 130px;
    margin-left: 25px;
    background: rgb(99, 99, 99);
    box-shadow: 1px 1px 2px 1px #ccc !important;
    color: #fff;
    color: #fff;
    cursor: pointer;
    height: 34px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all 0.5s;
}

.current-progress {
    font-size: 13px;
    margin-top: 15px;
    font-weight: 500;
    margin-bottom: -7px;
}

#quick-session-btn {
    right: 15px;
    background: rgb(109, 134, 218);
}

.create-new-session-btn:hover {
    background: rgb(88, 38, 107);
    transition: all 0.5s;
}

.todays-sessions-list-layout {
    width: 100%;
    margin-top: 10px;
}

.todays-session-list {
}

.home-page-section-adjust {
    height: 200px;
}

.home-page-section {
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
    margin-top: 00px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 2px;
    width: auto;
    overflow: auto;
    position: relative;
    transition: all 0.2s;
    height: 95vh;
    padding-bottom: 10px;
}


.roomsection {
    height: max-content;
}

.room-section {
    height: 370px;
}

.expand-section {
    height: 720px;
}

@media(max-width: 1164px) {
    .double-expand-section-open {
        height: 720px;
    }
}

@media(min-width: 1165px) {
    .double-expand-section-open {
        height: 460px;
    }
}

@media(max-width: 800px) {
    .double-expand-section-close {
        height: 720px;
    }
}

@media(min-width: 801px) {
    .double-expand-section-close {
        height: 460px;
    }
}

.single-expand-section {
    height: 490px;
}

.single-expand-section-room {
    height: 360px;
}

.home-page-section-rooms {
    background-color: #f7f9fc;
    padding-top: 20px;
    margin-bottom: 20px;
    margin-top: 00px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 2px;
    width: auto;
    overflow: hidden;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    position: relative;
    transition: all 0.2s;
    height: 420px;
}


.home-page-section::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
  
  /* Hide scrollbar for IE and Edge */
  .home-page-section  {
    -ms-overflow-style: none;
  }

.rooms-list-section {
    margin-top: 10px;
    position: relative;
}

.todays-sessions-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 10px;
    overflow-y: auto;
    max-height: auto;
    -ms-overflow-style: none;
    margin-top: 10px;
    margin-top: 20px;
}

.todays-sessions-list::-webkit-scrollbar {
    display: none;
}

.rooms-list-scroll {
}

.three-tabs {
    grid-template-columns: 1fr 1fr 1fr;
}



.session-card {
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top;
    padding: 20px;
    position: relative;
    margin-right: 2px;
    margin-top: 2px;
    margin-left: 2px;
    transition: all 0.2s;
    box-shadow: 1px .5px 3px 1px rgba(141, 157, 179, 0.37);
}

.session-card:hover {
    box-shadow: 1px 1px 7px 1px rgba(52, 57, 65, 0.39);
}

.room-cards-list {
    display: inline-block;
    vertical-align: top;
}

.roomCards {
    border: 1px solid rgb(229, 234, 240);
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    height: 90px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    padding-left: 20px;
    transition: all 0.3s;
    display: inline-block;
    vertical-align: top;
    width: max-content;
    padding-right: 20px;
    box-shadow: 1px 1px 2px 1px rgba(80, 106, 141, 0.205);
    margin-right: 10px;
}


.null-message {
    margin-left: 25px;
}

.session-card-header {
    opacity: 1;
    font-size: 19px;
    font-weight: bold;
    color: rgb(93, 96, 100);
    margin-bottom: 0px;
    padding-bottom: 10px;
}

.session-card-topline {
    margin-bottom: 0px;
}

.session-card-name {
    margin-bottom: 0px;
    margin-top: 20px;
}

.session-card-header-sub-text {
    font-size: 15px;
    font-weight: 500;
}

.extra-space {
    height: 50px;
}

.session-card-icon {
    height: 20px;
}

.display-users {
    display: grid;
    margin-bottom: 10px;
    position: absolute;
    right: 30px;
    top: 0px;
}

.session-complete-container {
    height: 20px;
}

.session-complete-text {
    width: 200px;
    position: absolute;
    left: 20px;
    bottom: 15px;
    font-size: 12px;
}

.user-container {
    display: inline-block;
    margin-right: 3px;
    margin-top: 10px;
}

.user-card {
    font-size: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: auto 15px;
}

.users-icon {
    margin-top: 8px;
    height: 15px;
    opacity: 0.2;
}

.session-card-footer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0px;
    column-gap: 5px;
}

.session-card-header {
    cursor: pointer;
}

.open-session-room-btn:hover {
    background: rgb(214, 216, 224);
}

.open-session-room-btn {
    position: absolute;
    right: 20px;
    bottom: 10px;;
    height: 40px;
    width: 150px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    background: #fff;
    color: rgb(67, 67, 78);
    margin-bottom: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
}

.session-notes-session-btn {
    height: 40px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    margin-top: 10px;
    background: rgb(146, 162, 203);
    border: 0px;
    color: rgb(255, 255, 255);
    width: 100%;
}

.prep-session-btn {
    height: 40px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    margin-top: 10px;
    background: fff;
    background: rgb(146, 203, 169);
    color: #fff;
    border: 0px;
    width: 100%;
}

.pause-btn {
    background: rgb(255, 255, 255);
    width: auto;
    color: rgb(145, 146, 146);
    box-shadow: 0px;
    border: 2px solid rgb(145, 146, 146);
}

.prep-session-btn:hover {
    box-shadow: 1px 1px 3px 1px #ccc;
    background: rgb(74, 96, 168);
    transition: all 0.2s;
}

.dark-button {
    background-color: rgb(103, 106, 112);
    color: #fff;
}

.display-session-project {
    width: 100%;
    font-size: 15px;
    margin-top: 10px;
}

.session-card-footer {
}

.session-card-role-container {
    font-size: 15px;
    font-weight: 500;
    padding-right: 5px;
    margin-right: 0px;
    margin-top: 0px;
    padding-top: 0px;
}

.session-card-display {
    display: inline-block;
    margin-bottom: 15px;
}

.home-section-header {
    font-size: 24px;
    font-weight: 800;
    margin-left: 0px;
    margin-top: 0px;
    border-bottom: 1px solid rgba(213, 216, 228, 0.219);
    padding-bottom: 10px;
}

.home-section-sub-header {
    font-size: 18px;
    font-weight: 800;
    margin-left: 0px;
    margin-top: 0px;
    border-bottom: 1px solid rgba(213, 216, 228, 0.219);
    padding-bottom: 10px;
}

.home-section-sub-header {
    font-size: 20px;
    font-weight: bold;
}

.session-counts {
    display: grid;
    grid-template-columns: 50px 50px 30px;
    margin-top: -5px;
    margin-bottom: 5px;
    position: absolute;
    top: 17px;
    background-color: rgb(249, 249, 250);
    border-radius: 100px;
    padding: 5px;
    cursor: pointer;
}

.count-icon-session-card {
    height: 16px;
}

.count-container {
    display: grid;
    grid-template-columns: 15px auto;
}

.low-o {
    opacity: 0.5;
}

.filter-sessions-layout {
    
}

.filter-sessions-select {
    display: inline-block;
    margin-right: 30px;
}

.filter-session-options {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 20px;
}

.filter-session-search-bar {
    display: inline-block;
    margin-left: auto;
}

.filter-session-option {
    display: inline-block;
    margin-right: 15px;
    font-weight: bold;
    cursor: pointer;
}

.unselected-filter {
    font-weight: 600;
    opacity: 0.5;
}


.display-users {
    display: grid;
    margin-bottom: 10px;
    position: absolute;
    right: 35px;
    top: 0px;
}

.room-list-btns-bottom {
    margin-bottom: 10px;
}

.user-card {
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;}

.user-sess-avatar {
  object-fit: cover;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.margin-top-bottom-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-bottom-5 {
    margin-bottom: 10px;
}

.session-edit-icon {
    position: absolute;
    height: 15px;
    width: 15px;
    cursor: pointer;
    border: 0px;
    right:  7.5px;
    top: 8px;
    opacity: 0.3;
    transition: all 0.1;
}

.session-edit-icon-container {
    position: absolute;
    height: 31px;
    width: 31px;
    border: 0px;
    cursor: pointer;
    border-radius: 100%;
    right:  4px;
    top: 9.5px;
    opacity: 0.3;
    transition: all 0.1;
}

.session-edit-icon:hover {
    opacity: 1;
}

.session-edit-icon:focus {
    outline: none;
}

.room-text {
    font-weight: 600;
}

.label-sub-text {
    font-size: 11px;
    font-weight: 400;
    opacity: 0.5;
}

.session-search-bar {
    position: absolute;
    top: 25px;
    left: 140px;
    width: 250px;
    height: 35px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    color: rgb(67, 68, 69);
    background-color: rgb(255, 255, 255);
    z-index: 5;
}

.session-search-bar:focus {
    outline-color: #ccc;
    outline-width: 3px;
}

.session-search-bar::placeholder {
    color: rgb(55, 58, 63);
    opacity: 0.2;
    font-size: 15px;
}

.null-text {
    opacity: 0.5;
}

.remove-checkin-trash {
    height: 12px;
    position: absolute;
    top: 9px;
    right: 12px;
    opacity: 0.5;
}

.remove-checkin-trash:hover {
    opacity: 1;
    cursor: pointer;
}

#on-deck-section-underline {
    border-bottom: 5px solid rgba(133, 133, 133, 0.173);
    padding-bottom: 20px;
    margin-bottom: -10px;
}

#in-progress-label {
    margin-bottom: 5px;
}

.second-room-option {
    border-radius: 4px;
    height: 30px;
    border: 1px solid #ccc;
    padding-left: 5px;
    padding-top: 2px;
    margin-bottom: 5px;
}

.second-room-option:hover {
    background-color: #eee;
    transition: all 0.2s;
}


.dropping-outline {
    border: 2px solid rgb(77, 136, 79) !important;
    background-color: rgba(143, 213, 143, 0.564);
}

.connect-to-session {
    text-align: center;
    width: 100%;
    margin-top: 5px;
    border: 0px;
    color: rgb(100, 126, 230);
    font-size: 13px;
}

.red-text {
    color: rgb(215, 133, 133);
}

.session-progress-bar {
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3px;
    text-align: center;
    height: 30px;
    margin-top: 10px;
}

.access-session-notes {
    font-size: 13px;
    text-align: center;
    height: 30px;
    margin-top: 10px;
    border-radius: 100px;
    background-color: rgb(235, 235, 235);
    padding: 5px;
}

.access-session-notes:hover {
    box-shadow: 0.5px 0.5px 2px 0.5px #eee;
    background-color: #ddd;
}

.progress-1 {
    background-color: rgb(235, 235, 235);
    border-radius: 25px 0px 0px 25px;
    padding-top: 5px;
    font-weight: bold;
    transition: all 0.2s;
}

.progress-2 {
    background-color: #eee;
    padding-top: 5px;
    font-weight: bold;
    transition: all 0.2s;
}


.progress-3 {
    background-color: rgb(235, 235, 235);
    border-radius: 0px 25px 25px 0px;
    padding-top: 5px;
    font-weight: bold;
    transition: all 0.2s;
}

.indicate-push-mode {
    background-color: rgb(160, 160, 160) !important;
    color: #fff;
    box-shadow: 1px 0px 2px 3px rgba(206, 206, 206, 0.509);

}

.complete-p1 {

    background-color: rgb(233, 118, 89) !important;
    color: #fff;
    cursor: default;
}

.complete-p2 {
    background-color: rgb(233, 118, 89) !important;
    color: #fff;
}

.session-progress-status {
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.disabled-progress {
    opacity: 0.5;
}