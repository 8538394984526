.tip-layout-container {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0px;
    transition: all 0.5s;
}

.tip-bg {
    height: 100vh;
    width: 100vw;
    position: absolute;
    justify-content: center;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    opacity: 0.4;
    z-index: 9;
    transition: all 0.5s;
}

.tipFadeInBg{    
    opacity: 0.4;
    transition: all 0.5s;
}

.tipFadeOutBg {
    opacity: 0;
    transition: all 0.5s;
}

.tip-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 3px rgba(87, 80, 88, 0.397);
    padding: 20px;
    border-radius: 4px;
    width: 450px;
    z-index: 10;
    padding-bottom: 50px;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    height: 700px;
    transition: all 0.2s;
}

.tip-cont-scaled {
    height: 450px;
}

.tip-header {
    height: 40px;
    font-size: 21px;
    font-weight: 800;
    color: rgb(187, 187, 187);
    padding-left: 10px;
    margin-top: 0px;
}

.tip-body {
    font-size: 17px;
    font-weight: 500;
    color: rgb(82, 84, 95);
    padding: 10px;
    transition: all 0.5s;
}

.tip-body-scaled {
    height: 300px;
}

.tip-logo {
    height: 100px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.help-tip-close {
    position: absolute;
    height: 12px;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.tip-subtext {
    font-size: 13px;
    margin-top: 15px;
    font-weight: 500;
    opacity: 0.5;
    text-align: center;
    padding-right: 5px;
    padding-left: 5px;
}

.check-in-padding-top {
    height: 80px;
}

.ani-id-email-input {
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgb(237, 238, 241);
    background-color: rgb(250, 251, 252);
    margin-bottom: 5px;
}

.tip-line-divider {
    width: 100%;
    height: 5px;
    background-color: rgba(204, 204, 204, 0.24);
}

.email-inputs-tip {
    margin-top: 20px;
}

.ani-id-email-input:focus {
    outline-color: #ccc;
    outline-width: 3px;
}

.tip-input-container {
    position: relative;
}

.return-to-form-btn {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    background-color: rgb(81, 89, 105);
    border: 0px;
    margin-top: 20px;
    color: #fff;
}

.tip-thanks-message {
    margin-bottom: 15px;
}


