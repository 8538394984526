.search-layout {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.search-layout-with-sidebar {
    height: 100%;
    display: grid;
    grid-template-columns: min-content auto;
}

.search-layout-without-sidebar {
    height: 100%;
    display: grid;
    grid-template-columns: 30px auto;
}

.search-sidebar {
    height: 100%;
    width: 300px;
    background-color: #f7f9fc;
    transition: all 0.1s;
    position: relative;
}

.sidebar-min {
    width: 40px;
}

.arrow-sidebar-min {
    position: absolute;
    top: 15px;
    right: 10px;
    height: 15px;
    width: 15px;
    transition: all 0.1s;
    cursor: pointer;
}

.left {
    transform: rotate(90deg);
}

.right {
    transform: rotate(270deg);
}


/* LIST CONTAINER */

.search-list-container {
    width: 100%;
    margin: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 200px auto;
    overflow-y: auto;
    height: auto;
}

.search-list-header-container {
    padding-bottom: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: 200px auto;
}

.search-list-content {
    width: 100%;
}

.search-list-left {
    height: 100%;
    width: 100%;
    padding-bottom: 150px;
}

.search-list-right {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 150px;
}

.static-box {
    position: absolute;
}

.table-scroll-zone {
    height: 100vh;
}

.table-scroll-zone-header {
    overflow-x: auto;
}

.search-list-table-static {
    display: grid;
    grid-template-columns: 200px;
    background-color: #fff;
}

.search-list-table {
    width: 100%;
    display: grid;
    grid-template-columns: 300px 150px 180px 150px 150px auto;
}

.search-list-item-container {
    width: 100%;
    margin-top: 0px;
    padding-top: 5px;
}

.search-list-item-container:hover {
    background-color: #ddd;
    cursor: pointer;
    width: auto;
    min-width: 950px;
}

.table-header {
    font-weight: bold;
    padding-bottom: 3px;
    border-bottom: 2px solid #ccc;
    position: relative;
}

.item-name {
    font-weight: 600;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    padding-left: 5px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.item-name-border {
    width: 100%;
    border-right: 2px solid #ddd;
}

.item-element {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 5px;
    font-weight: 300;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.item-reader {
    display: inline-block;
    margin-right: 5px;
}

.item-counts {
    display: grid;
    grid-template-columns: 20px 25px 20px 25px;
    font-size: 12px;
    margin-top: 3px;
    cursor: pointer;
}

.count-icon {
    height: 15px;
    width: auto;
}


/* SIDEBAR */

.sidebar-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}

.search-sidebar {
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
}

.sidebar-title {
    font-weight: bold;
    font-size: 16px;
}

.sidebar-section-container {
    margin-top: 20px;
}

.search-tag {
    display: inline-block;
    margin-right: 3px;
    margin-top: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.961);
    cursor: pointer;
}

.search-tag-selected {
    background-color: #ccc;
}

.no-filters-text {
    text-align: center;
    margin-top: 40px;
    opacity: 0.4;
}

.advanced-search-bar {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #ccc;;
    padding: 8px;
}

.advanced-search-bar:focus {
    outline-width: 3px;
    border-radius: 4px;
}
