.show-device-message {
    background-color: #f6f8fa;
    box-shadow: 1px 1px 3px 1px rgba(15, 15, 15, 0.315);
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 50px;
    padding: 10px;
    font-size: 15px;
    width: 360px;
    border-radius: 4px;
    border: 2px solid #ccc;
}

.enable-notifications {
    width: 100%;
}

.push-employee-options {
    margin-top: 10px;
}

.push-employee-select {
    display: grid;
    grid-template-columns: auto 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    margin-top: 4px;
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
}

.push-employee-select:hover {
    background-color: #eee;
}

.select-employee-for-push {
    border-radius: 4px;
    background-color: #eee;
    border: 0px;
}

.close-push-message {
    height: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.push-notification-direction {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 2px;
}

.show-device-text {
    margin-top: 0px;
    font-weight: 500;
    font-size: 15px;
}

.help-tip-bottom-text {
    font-size: 13px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    text-align: center;
}