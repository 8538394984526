.custom-modal-layout {
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.slim-modal-layout {
    height: 100vh;
    width: 100vw;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    z-index: 5;
    top: 0px;
    left: 0px;
}

.close-slim-modal {
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.5;
    cursor: pointer;
}

.modal-bg-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    justify-content: center;
    background-image: linear-gradient(to top, #373737 0%, #6f49d8 100%);
    opacity: 0.2;
    z-index: 10;
    transition: all 0.1s;
}

.slim-modal-card {
    height: 80vh;
    max-height: 500px;
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 100;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    box-shadow: 0px 2px 10px 3px rgba(78, 50, 85, 0.397);
    overflow: hidden;

}

.custom-modal-card {
    height: 90vh;
    width: 100vw;
    max-width: 900px;
    min-width: 400px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    opacity: 1;
    display: grid;
    grid-template-rows: 50px auto 5px;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0px 2px 10px 3px rgba(78, 50, 85, 0.397);
    z-index: 100;
}

/* NAV MODAL */

.nav-modal-card {
    height: 90vh;
    width: 80%;
    min-width: 480px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    background-color: #ffffff;
    border-radius: 4px;
    opacity: 1;
    display: grid;
    grid-template-columns: max-content auto;
    z-index: 11;
    box-shadow: 0px 2px 10px 3px rgba(78, 50, 85, 0.397);
    overflow: hidden;
}

    .nav-react-select {
        width: auto;
        max-width: 500px;
        background-color: rgb(239, 242, 245);
    }

    .nav-modal-nav {
        background-color: rgb(239, 242, 245);
        width: 250px;
        transition: all 0.1s;
        position: relative;
    }

    .date-picker {
        cursor: pointer;
    }

    .nav-modal-content {
        overflow-y: auto;
        height: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .padding-bottom-200 {
        height: 500px;
    }

    .nav-modal-content::-webkit-scrollbar {
        display: none;
    }


    .nav-modal-content input {
        border-radius: 2px;
        border: 0px;
        height: 40px;
        background-color: rgb(239, 242, 245);
        padding-left: 8px;
        margin-top: 2px;
        font-size: 17px;
        font-weight: 500;
        color: rgb(75, 82, 94);
    }

    .nav-modal-content select {
        background-color: rgb(239, 242, 245);
        color: rgb(75, 82, 94);
        border-radius: 2px;
        height: 40px;
        font-size: 17px;

    }

    .date-picker-input {
        width: 150px;
    }

    .save-changes-nav-btn {
        margin-top: 25px;
        margin-left: 10px;
        border-radius: 4px;
        box-shadow: 0.5px 0.5px 1px 0.5px #ddd;
        background: rgb(153,208,245);
        background: linear-gradient(180deg, rgb(38, 134, 197) 0%, rgba(62,149,218,1) 100%);
        color: #fff;
        border: 0px;
        padding: 5px;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 15px;
        font-weight: 600;
        
    }

    .nav-modal-content input::placeholder {
        opacity: 0.5;
        font-weight: 500;
        color: rgb(75, 82, 94);
    }
        
        .nav-minimized {
        width: 40px;
        }

        .nav-modal-nav-container {
            color: rgb(77, 85, 92);
        }

        .nav-modal-title {
            font-size: 17px;
            font-weight: 700;
            margin-top: 30px;
            margin-bottom: 20px;
            margin-left: 30px;
        }

        .nav-modal-links {
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
        }

        .nav-modal-link {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
            width: 100%;
            padding-left: 30px;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
        }

            .link-selected {
                background-color: #fff;
                font-weight: 600;
            }

        .nav-modal-link:hover {
            background-color: rgba(255, 255, 255, 0.439);
        }

        .nav-modal-arrow {
            height: 30px;
            position: absolute;
            cursor: pointer;
            right: -15px;
            top: 15px;
            border-radius: 100px;
            background-color: #fff;
            box-shadow: 0px .5px 2px 1px #eee;
            padding: 5px;
        }

        .arrow-right {
            transform: rotate(270deg);
        }

        .arrow-left {
            transform: rotate(90deg);
        }

        .nav-section-section { 
            padding: 10px;
            padding-left: 30px;
            padding-right: 30px;
        }

        /* CONTAINER */

        .nav-section-header-text {
            font-size: 19px;
            font-weight: 800;
            padding: 10px;
            padding-left: 30px;
            margin-top: 10px;
        }

        .nav-modal-role-item {
            display: inline-block;
            box-shadow: .5px 0.5px 2px 1px #eee;
            border-radius: 4px;
            margin-right: 5px;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
        }

        .selected-role {
            border: 2px solid #ccc;
        }

        .session-type-option {
            display: inline-block;
            vertical-align: top;
            width: 160px;
            height: 110px;
            border: 1px solid #ddd;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 4px;
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            background-color: #fff;
        }

        .type-role {
            height: 50px;
        }

        .session-type-option:hover {
            box-shadow: 1px 1px 5px 1px #ccc;
        }

        .selected-option {
            border: 1px solid #ddd;
            border-bottom: 5px solid rgb(93, 175, 238);
            
        }

        .session-modal-sub-label { 
            font-size: 13px;
            font-weight: 500;
        }


        .city-state-zip {
            display: grid;
            grid-template-columns: 169px 35px 60px;
            column-gap: 3px;
        }

        .address-input-nav {
            width: 270px;
        }

        .manual-address-inputs input {
            height: 40px;
            font-size: 15px;
        }

        .manual-address-inputs {
            margin-bottom: 30px;
        }

        .parking-instructions {
            height: 166px;
            width: 220px;
            margin-bottom: 30px;
            border-radius: 2px;
            border: 0px;
            font-size: 15px;
            font-weight: 500;
            background-color: rgb(239, 242, 245);
            color: rgb(75, 82, 94);
            padding: 8px;
            margin-top: 2px;
        }

        .parking-instructions::placeholder {
            opacity: 0.5;
            color: rgb(75, 82, 94);
        }

        .custom-location-section {
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
        }


.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-10 {
    margin: 10px;
}

.margin-20 {
    margin: 20px;
}

.margin-30 {
    margin: 30px;
}


.schedule-modal-card {
    height: 90vh;
    width: 80%;
    min-width: 480px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    background-color: #ffffff;
    border-radius: 4px;
    opacity: 1;
    display: grid;
    grid-template-rows: 50px 90vh;
    z-index: 100;
    box-shadow: 2px 3px 10px 3px rgb(80, 94, 110);
    overflow: hidden;
}


.custom-modal-header {
    border-radius: 4px 4px 0px 0px;
    padding: 12px;
    padding-left: 20px;
    font-size: 22px;
    font-weight: bold;
}

.custom-modal-body {
    padding: 12px;
    padding-bottom: 0%;
    height: 100%;
}

.custom-modal-body-schedule {
    height: 100%;
}

.custom-modal-footer {
    border-radius: 0px 0px 4px 4px;
}

.modal-section { 
    padding: 5px;
}

.modal-room-readers-list {
    height: auto;
    width: 100%;
}

.reader-container {
    display: inline-block;
    background-color: #f8f8f8;
    padding: 5px;
    padding-left: 6px;
    padding-top: 7px;
    border-radius: 4px;
    margin-right: 2px;
    margin-left: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 560;
    height: 35px;
    cursor: pointer;
}

.reader-container:hover {
    box-shadow: 1px 1px 3px 1px #62aada57;
}

.reader-layout {
    display: grid;
    grid-template-columns: auto 22px;
}

.reader-name {
    margin-right: 5px;
    margin-top: 1px;
}

.select-custom {
    width: 100%;
}

.select-arrow {
    cursor: pointer;
    float: right;
    margin-bottom: 5px;
}

.down-arrow {
    height: 15px;
    width: px;
    margin-top: 2px;
    transition: all 0.1s;
}

.down-arrow-project {
    height: 15px;
    width: 22px;
    margin-top: 2px;
    transition: all 0.1s;
    opacity: 0.2;
    cursor: pointer;
}

.down-arrow-project:hover {
    opacity: 1;
    cursor: pointer;
}

.down-arrow-users {
    height: 22px;
    width: 22px;
    margin-top: 2px;
    transition: all 0.1s;
    opacity: 0.2;
    cursor: pointer;
}

.down-arrow-users:hover {
    opacity: 1;
    cursor: pointer;
}

.up-arrow {
    height: 15px;
    width: 15px;
    margin-top: 2px;
    transform: rotate(180deg);
    transition: all 0.1s;
}

.up-arrow:hover {
    cursor: pointer;
}

.remove-btn {
    opacity: 0.1;
    border: 0px;
    justify-content: center;
}

.x-icon {
    height: 9px;
    width: 9px;
    margin-top: -1px;
    
}

.remove-btn:hover {
    opacity: .8;
}

.select-custom {
    border-radius: 4px;
    height: 40px;
}

.select-custom-multi {
    display: grid;
    grid-template-columns: auto 28px;
    border-radius: 4px;
    height: auto;
    min-height: 47px;
}

.select-custom-project-roles {
    margin-top: -25px;
    margin-right: 2px;
    position: relative;
}

.custom-dropdown {
    width: 40%;
    min-width: 170px;
    border-radius: 4px;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    padding: 2px;
    box-shadow: 1px 1px 3px 1px #aaa;
    border: 2px solid eee;
    position: absolute;
    right: 20px;
    background-color: #fff;
    z-index: 1;
}

.select-arrow-roles {
    float: right;
}

.select-arrow-roles .down-arrow {
    opacity: 0.1;
    cursor: pointer;
}

.select-arrow-roles .down-arrow:hover {
    opacity: 0.5;
}

.custom-dropdown-projects {
    width: 40%;
    border-radius: 4px;
    height: auto;
    padding: 2px;
    box-shadow: 1px 1px 3px 1px #aaa;
    border: 2px solid eee;
    position: absolute;
    right: 20px;
    background-color: #fff;
}

.custom-dropdown-project-roles {
    width: 150px;
    border-radius: 4px;
    height: auto;
    padding: 2px;
    box-shadow: 1px 1px 3px 1px #aaa;
    border: 2px solid eee;
    position: absolute;
    background-color: #fff;
    top: 25px;
    right: 0px;
    z-index: 10;
}

.custom-dropdown-item {
    padding: 8px;
    padding-left: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
    height: 100%;
    font-weight: 500;
    background-color: #fff;
    color: #000000;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.custom-dropdown-item:hover {
    background-color: rgb(204, 221, 233);
}

.custom-dropdown-item-select {
    padding: 8px;
    padding-left: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
    font-weight: 500;
    background-color: rgb(95, 177, 231);
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.custom-dropdown-item-select:hover {
    background-color: rgb(228, 158, 158);
}

.modal-project-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    border-radius: 4px;
}

.modal-project-title {
    font-size: 14px;
    width: 160px;
    font-weight: bold;
    margin-bottom: -5px;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.project-container {
    border-radius: 4px;
    padding: 5px;
    min-height: 80px;
    border: 2px solid rgb(230, 233, 236);
}

.proj-modal-header {
    height: 35px;
    padding: 5px;
    border-bottom: 1px solid #f8f8f8
}

.proj-modal-body {
    padding: 5px;
}

.proj-modal-role {
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: -5px;
    margin-top: -3px;
    margin-right: 3px;
    display: inline-block;
    border-radius: 4px;
    width: max-content;
    font-size: 14px;
    background-color: #f8f8f8;
}


.modal-section-label {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 5px;
    padding-bottom: 5px;
}


.dropdown-message-container {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
}

.dropdown-message {
    width: 100%;
    border-radius: 4px;
    border: 0px;
    font-size: 12px;
    height: 30px;
    padding-left: 5px;
}

.dropdown-message:focus {
    outline: none;
}


/* NOTES */

.note-body-header {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0px;
}

.note-body {
    margin-left: 20px;
    margin-right: 20px;
    height: 60vh;
    border-radius: 4px;
}

.new-note-input-container {
    column-gap: 5px;
    position: relative;
}

.new-note-submit-btn {
    margin-top: 2px;
    height: 38px;
    width: 30px;
    border-radius: 4px 4px 4px 4px;
    background-color: rgba(231, 231, 231, 0.719);
    color: #fff;
    border: 0px;
    right: 0px;
    top: -1px;
    position: absolute;
}

.new-note-textarea {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    padding: 8px;
    padding-right: 40px;
    border: 1px solid #ddd;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
}
.new-note-textarea:focus {
    outline: none;
}

.notes-modal-container {
    background-color: #fff;
    border-radius: 4px;
    height: 80%;
    overflow-y: auto;
    padding-bottom: 100px;
}

.note-container {
    padding-bottom: 8px;
    background-color: #fff;
    padding-top: 8px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 35px auto 35px;
    column-gap: 10px;
    border-bottom: .5px solid #fafafa;
    border-bottom: 1px solid #eee;
}

.note-avatar {
    width: 30px;
    height: 30px;
    background-color: #eee;
    border-radius: 100px;
    margin-top: 3px;
    text-align: center;
    color: #aaa;
    font-weight: bold;
}

.note-text {
    font-size: 17px;
    font-weight: 600px;
}

.note-user {
    font-size: 10px;
    font-weight: 400;
    color: #ccc;
}

.note-btns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.modal-note-layout {
    height: 100%;
    display: grid;
    grid-template-rows: min-content auto;
    row-gap: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    position: relative;
}

.remove-note {
    height: 15px;
    width: auto;
    opacity: 0.2;
    margin-top: 8px;
    cursor: pointer;
}

.remove-note:hover {
    opacity: 0.8
}

.talent-project {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 2px;
}

.talent-role {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}

.read-by-container {
    margin-bottom: 5px;
    font-size: 14px;
}

.note-label {
    font-size: 12px;
    font-weight: bold;
}


.modal-close-btn {
    float: right;
    margin-top: -45px;
    margin-right: 0px;
    cursor: pointer;
    opacity: 0.2;
}

.modal-close-btn {
    opacity: 1;
}

.close-modal-x {
    height: 11px;
    width: 11px;
}


.talent-reader {
    display: inline-block;
    margin-left: 5px;
}


.modal-context {
    font-size: 12px;
    margin-bottom: 20px;
}



/* TAGS */

.tag {
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 50px 25px 50px 50px;
    border: 1px solid #eee;
    cursor: pointer;
}

.tag-elements {
    display: grid;
    grid-template-columns: 20px auto;
    padding-right: 5px;
}

.tag-count {
    font-size: 11px;
    background-color: #eee;
    border-radius: 100px;
    height: 16px;
    width: 16px;
    text-align: center;
    margin-top: 2px;
}

.tag-name {
    font-size: 14px;
}

.tag-delete {
    height: 8px;
    width: 8px;
    float: right;
    opacity: 0.1;
}

.tag .tag-delete:hover {
    opacity: 1;
}


.add-tag-btn-notes-modal {
    width: 100px;
}

.search-tag-notes-modal {
    width: 150px;
    border-radius: 4px;
    border: 0px;
    background-color: rgb(244, 245, 248);
    padding-left: 8px;
    padding-right: 8px;
    height: 35px;
}

.add-tag-container {
    position: relative;
}

.modal-tag-container {
    margin-bottom: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 100px auto;
    column-gap: 10px;
    transition: all 0.2s;
}

.add-tag-btn-notes-modal {
    height: 34px;
    border-radius: 4px;
    background-color: rgb(88, 93, 107);
    color: #fff;
    border: 0px;
    font-size: 15px;
    font-weight: 500;
}

.search-tag-tag-item {
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.search-tag-tag-item:hover {
    background-color: rgb(244, 245, 248);
    cursor: pointer;
}

.modal-tag-container-search-open {
    grid-template-columns: 150px auto;
}

.search-tag-notes-dropdown {
    position: absolute;
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    z-index: 20;
    width: 149px;
    top: 40px;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.search-tag-notes-dropdown::-webkit-scrollbar {
    display: none;
}

.note-modal-tag-list {
    width: 100%;
}

.modal-tag {
    display: inline-block;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.modal-tag-layout {
    display: grid;
    width: min-content;
    column-gap: 10px;
    grid-template-columns: max-content 10px;
}

.modal-tag-name {
    font-size: 15px;
    font-weight: 600;

}



/* SCHEDULE MODAL */

.schedule-modal-layout {
    display: grid;
    grid-template-columns: 200px auto;
    height: 80vh;
    transition: all 0.2s;
}

.schedule-modal-layout-min {
    display: grid;
    grid-template-columns: 50px auto;
    column-gap: 00px;
    height: 100%;
    transition: all 0.2s;
}

.schedule-container {
    position: relative;
    height: 70vh;
    padding: 10px;
    padding-top: 15px;
    border-radius: 4px;
}

.schedule-form-container {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 20px;
    background-color: #f8f8f8;
}

.schedule-form-content {
}

.schedule-form-input {
    border-radius: 4px;
    width: 100%;
    height: 35px;
    border: 1px solid #ccc;
    padding-left: 10px;   
}

.schedule-form {
    padding: 10px;
    border-radius: 0px 0px 0px 4px;
}

.schedule-date {
    font-weight: bold;
    font-size: 14px;
}

.schedule-form-time-input {
    border-radius: 4px;
    width: 100%;
    height: 35px;
    border: 1px solid #ccc;
    text-align: center;
}

.input-container {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
}

.time-input-container {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 5px;
}

.input-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 3px;
}

.add-to-schedule-btn {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    border: 0;
    font-weight: 600;
    font-size: 12px;
    background-color: #ccc;
    margin-bottom: 5px;
}

.schedule-form-quick-add-container {
    margin-top: 25px;
    border-bottom: 1px solid #aaa;
    margin-bottom: 10px;
}

.schedule-generator-btn {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    border: 0;
    font-weight: 600;
    font-size: 12px;
    background-color: rgb(53, 127, 191);
    margin-bottom: 5px;
    color: #fff;
    margin-bottom: 10px;
}

.schedule-date {
    margin-bottom: 10px;
}
.schedule-times-container {
    height: 100%;
    overflow-y: scroll;
    border: 2px solid rgb(235, 235, 236);
    background-color: #f8f8f8;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.times-layout {
    position: relative;
    height: 100%;
    background-color: #fff;
    margin-top: -14px;
}

.step-select {
    right: -3px;
    top: 0px;
    position: absolute;
    font-size: 12px;
    height: 26px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
}

.time-row {
    width: 100%;
    display: grid;
    padding-top: 6px;
    padding-bottom: 6px;
    grid-template-columns: 80px auto;
    border-bottom: 1px solid #eee;
    position: relative;
    min-height: 74px;
}


.date-picker {
    width: 100%;
    margin-left: 3px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid rgb(255, 255, 255);
    text-align: center;
    cursor: pointer;
}


.appt-block {
    position: relative;
    padding: 10px;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 3px 1px #ccc;
    width: 120px;
    height: 60px;
    cursor: grab;
    background-color: #fff;
    color: rgb(43, 72, 111);
    transition: all 0.5s;
    overflow: hidden;
    
}

.appt-block-edit-mode {
    position: relative;
    padding: 10px;
    border-radius: 4px;
    margin-right: 5px;
    box-shadow: 1px 1px 3px 1px #ccc;
    width: 120px;
    height: 278px;
    cursor: grab;
    background-color: #fff;
    color: rgb(43, 72, 111);
    transition: all 0.5s;
    overflow: hidden;
    cursor: default;
    transition: all 0.5s;
}


.date-select-container {
    position: absolute;
    left: 200px;
}

.edit-appt-box {
    margin-top: 10px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.appt-block:hover {
    box-shadow: 1px 1px 8px 1px #ddd;
}


.appt-block:active {
    opacity: 0.2;
    cursor: grab;
    background-color: '',
}


.appt-block-add {
    padding: 5px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    margin-right: 5px;
    box-shadow: 1px 1px 3px 1px #eee;
    width: 100px;
    height: 45px;
    position: absolute;
    top: 10px;
    right: 5px;
}

.appt-block-add-off {
    padding: 5px;
    border-radius: 4px;
    margin-right: 5px;
    width: 50px;
    height: 45px;
    position: absolute;
    top: 10px;
    right: 5px;
    background-color: #f8f8f8;
    transition: all 0.2s;
}

.appt-name {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: -3px;
    margin-top: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.appt-project {
    font-size: 11px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: -3px;
}

.appt-role { 
    font-size: 09px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.appt-min {
    font-size: 10px;
    margin-bottom: -2px;
    margin-top: -4px;
}

.date-view-container {
    display: grid;
    grid-template-columns: auto 30px 200px 30px auto;
    position: relative;
    margin-bottom: 5px;
    margin-top: 5px;
}

.view-datepicker {
    width: 200px;
    border-radius: 4px;
    text-align: center;
    border: 0;
    cursor: pointer;
}

.prev-date-arrow {
    height: 16px;
    width: auto;
    transform: rotate(90deg);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.time-section {
    font-size: 12px;
}

.next-date-arrow {
    height: 16px;
    width: auto;
    transform: rotate(270deg);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.exact-time-select {
    background-color: #f8f8f8;
    border-radius: 4px;
    position: absolute;
    top: 0px;
    right: 5px;
    width: auto;
    padding: 3px;
    z-index: 10;
}

.exact-time-value {
    display: inline-block;
    padding: 5px;
    width: 35px;
    text-align: center;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.589);
    border-radius: 100px;
    margin: 2px;
    cursor: pointer;
    z-index: 1;
}

.exact-time-value-over {
    display: inline-block;
    padding: 5px;
    width: 35px;
    text-align: center;
    font-weight: bold;
    background-color: rgb(102, 168, 234);
    border-radius: 100px;
    margin: 2px;
    cursor: pointer;
    z-index: 1;
    color: #fff;
}

.exact-time-value:hover {
    box-shadow: 1px 1px 4px #bbb;
    background-color: #fff;
}

.min-form {
    float: right;
    margin-right: 5px;
    cursor: pointer;
}

.min-form-arrow {
    height: 14px;
    width: 14px;
    transform: rotate(90deg);
    opacity: 0.5;
}

.expand-appt {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: rgb(132, 132, 132);
    border-radius: 100px;
    text-align: center;
}

.clock-icon {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 3px;
    right: 3px;
}

.change-time-select {
    position: absolute;
    top: 5px;
    border: 1px solid #eee;
    height: 17px;
    width: 70px;
    border-radius: 100px;
    cursor: pointer;
}

.change-time-select:hover {
    border: 1px solid #ccc;
}

.appt-status-icon {
    height: 12px;
    width: 12px;
    top: 1.5px;
    left: 2px;
    position: absolute;
}

.appt-min {
    font-size: 11px;
    position: absolute;
    left: 20px;
    top: 3px;
}

.trash-icon-appt {
    height: 11px;
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0.7;
}

.trash-icon-appt-red {
    height: 11px;
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0.0;
}

.trash-icon-appt-red:hover {
    opacity: 1;
    cursor: pointer;
}

.status-select-icon {
    height: 25px;
    width: 25px;
    opacity: 1;
    cursor: pointer;
}

.appt-icon-btn {
    height: 25px;
    width: 25px;
    margin-top: 3px;
    margin-bottom: 8px;
    margin-right: 8px;
    border-radius: 100px;
    padding: 0px;
    border: 0px;
}

.unselected {
    opacity: 0.2;
}

.pop-over-label {
    font-size: 10px;
    font-weight: bold;
}

.time-edit-inputs {
    display: grid;
    grid-template-columns: 30px 2px 30px 0px 36px;
    border: 1px solid #eee;
    border-radius: 4px;
    padding-bottom: 4px;
    padding-top: 4px;
    margin-bottom: 10px;
}

.time-edit-inputs select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.date-edit-inputs {
    display: grid;
    grid-template-columns: 26px 4px 26px 6px 36px;
    border: 1px solid #eee;
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 10px;
}

.date-edit-input {
    border: 0px;
    text-align: center;
    border-radius: 4px;
}

.time-edit-hm {
    border: 0px;
    text-align: center;
    border-radius: 4px;
}

.time-edit-ampm {
    border: 0px;
    border-radius: 0px 4px 4px 0px;
    margin-right: 2px;
    padding-left: 7px;
    background-color: #fff;
}

.date-select-container {
    position: absolute;
    top: 5px;
    right: 20px;
}

.btn-appt-edit {
    width: 100%;
    border-radius: 4px;
    margin-top: 2px;
    height: 20px;
    color: #fff;
    border: 0px;
    font-size: 11px;
    font-weight: bold;
}

.save {
    background-color: rgb(89, 136, 224);
}

.cancel {
    background-color: rgb(236, 83, 83);
}

.disabled {
    background-color: #ccc;
}

.delete-modal-tag {
    height: 10px;
    margin-top: 6px;
    opacity: 0.2;
}

.delete-modal-tag:hover {
    opacity: 0.7;
    cursor: pointer;
}

.time-element {
    display: inline-block;
    margin-right: 20px;
    font-size: 14px;
}

.time-container {
    margin-bottom: 10px;

}


.listed-room-session-modal {
    height: 70px;
    display: inline-block;
    width: max-content;
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px #ddd;
    padding: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 0px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.listed-user-session-modal {
    height: 80px;
    display: inline-block;
    width: auto;
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px #ddd;
    padding: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 0px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.listed-user-session-modal-selected-item {
    
}

.nav-section-container {
    width: auto;
    max-width: 600px;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    
}


.new-session-modal-body {
    padding: 10px;
    height: 80vh;
    overflow-y: auto;
}

.session-modal-label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(245, 246, 247);
    padding-bottom: 5px;
    margin-top: 20px;
}

.session-room-container {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.session-modal-input {
    height: 40px;
    border-radius: 2px;
    border: 0px;
    background-color: #f8f8f8;
    padding-left: 8px;
    font-size: 17px;
    width: 100%;
    max-width: 500px;
}

.session-modal-time-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    background-color: #f8f8f8;
    font-size: 17px;
    text-align: center;
}

.session-modal-input-select {
    width: 100%;
    height: 40px;
    margin-top: 2px;
    border-radius: 2px;
    border: 0px;
    background-color: #f8f8f8;
    font-size: 17px;
    text-align: center;
}


.center-input {
    text-align: center;
}

.session-time-layout {
    display: grid;
    grid-template-columns: 120px 130px 130px auto;
    column-gap: 20px;
}

.sub-label {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

.time-inputs {
    display: grid;
    grid-template-columns: 45px 45px 60px;
    text-align: center;
    column-gap: 3px;
    width: 80%;
}

.template-avatar {
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    opacity: 0.2;
}

.user-list-session-modal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.user-name-session-modal {
    margin-top: 3px;
}

.submit-session-button {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: rgb(86, 223, 116);
    border: 0;
    box-shadow: 1px 1px 3px 1px #eee;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
}


.delete-session-button {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: rgb(223, 86, 86);
    border: 0;
    box-shadow: 1px 1px 3px 1px #eee;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
}

.go-back-btn {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}

.talent-schedule-button {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-top: 31px;
    height: 40px;
    border-radius: 4px;
    background-color: rgb(86, 141, 223);
    border: 0;
    box-shadow: 1px 1px 3px 1px #eee;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    font-size: 14px;
}


.selected-item {
    opacity: 1;
}


.quick-form-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.quick-form-inputs-side {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    height: 75vh;
    overflow: auto;
}

.quick-form-layout {
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    column-gap: 25px;
}


.description {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.quick-form-header-text {
    font-size: 19px;
    font-weight: 800;
    margin-top: 10px;
    color: rgb(100, 128, 212);
}

.quick-add-select {
    width: 300px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.role-select {
    width: 200px;
}

.quick-add-input-section {
    margin-top: 10px;
    
}

.time-frame-inputs {
    width: 100%;
    display: grid;
    grid-template-columns: min-content min-content min-content min-content;
}

.quick-add-time-input {
    margin-right: 20px;
    border: 0px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    width: 100px;
    margin-bottom: 10px;
}

.to-talent-btn {
    margin-top: 20px;
    border-radius: 4px;
    background-color: #ccc;
    border: 0px;
    width: 150px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.quick-list-name-time {
}

.increment-input {
    width: 50px;
    margin-right: 5px;
    padding-left: 10px;
}

.quick-list-name-name { 
}

.clear-slot-icon {
    height: 15px;
}

.quick-list-name-layout {
    display: grid;
    grid-template-columns: 80px auto 100px 100px;
}

.quick-list-slot-time {
    display: grid;
    grid-template-columns: 90px auto 100px 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}


.tal-sche-slot-item {
    position: relative;
    display: grid;
    grid-template-columns: 90px auto 150px 20px 20px 20px;
    column-gap: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
    height: 60px;
    border-bottom: 1px solid #eee;
}

.session-talent-schedule-header {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.tal-sch-slot-time {
    margin-top: 3px;
}

.tal-sche-slot-name {
    margin-top: 3px;
}

.tal-sch-slot-time {
    margin-top: 3px;
}

.tal-sche-details-txt {
    display: inline-block;
    margin-right: 5px;
}

.tal-sch-slot-role {
    width: 100%;
    margin-right: 10px;
    border: 0px;
}

.session-talent-increment-input {
    padding-left: 8px;
    border-radius: 4px;
    border: 0px;
    background-color: #f8f8f8;
    width: 40px;
    margin-top: 0px;
    height: 30px;
    text-align: center;
    display: inline-block;
    margin-top: 10px;
}

.generate-schedule-btn {
    font-size: 15px;
    border-radius: 4px;
    background-color: rgb(89, 136, 224);
    color: #fff;
    border: 0px;
    padding: 5px;
    padding-right: 8px;
    padding-left: 8px;
    display: block;
    margin-top: 15px;
    font-weight: 600;
}

.tal-sch-list-header {
}

.tal-sche-list-input {
    padding-left: 8px;
    border-radius: 4px;
    border: 0px;
    background-color: #f8f8f8;
    width: 200px;
    margin-top: 0px;
    height: 30px;
    margin-bottom: 20px;
}

.tal-sche-slot-input {
    padding-left: 8px;
    border-radius: 4px;
    background-color: #fff;
    width: 95%;
    margin-top: 0px;
    height: 30px;
}

.nav-slot-input {
    margin-top: -5px !important;
    height: 30px;
    background-color: #fefefe !important;
    margin-left: -5px !important;
    font-size: 15px !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    min-width: 160px !important;
}

.nav-select-slot {
    margin-top: -5px !important;
    height: 30px;
    background-color: #fefefe !important;
    margin-left: -5px !important;
    font-size: 15px !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
}

.nav-slot-input:focus {
    outline: none;
}

.tal-sch-slot-status {
    margin-left: 10px;
    margin-top: 3px;
}

.add-talent-to-schedule-btn {
    margin-left: auto;
    display: block;
}

.quick-add-section-title {
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
}

.quick-form-back-btn {
    cursor: pointer;
    font-size: 13px;
}

.prev-quick-list-item {
    cursor: pointer;
}

.blue-button {
    background-color: #ccc;
}

.erase-slot-icon {
    height: 15px;
    margin-top: 6px;
    cursor: pointer;
    opacity: 0.5
}

.erase-slot-icon:hover {
    opacity: 1;
}


.invite-popup {
    position: absolute;
    right: 0px;
    background-color: rgb(136, 157, 181);
    box-shadow: 1px 1px 5px 1px #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 10;
    width: 300px;
    top: 5px;
}

.invite-popup-input {
    width: 85%;
    border-radius: 4px;
    border: 0px;
    background-color: rgb(84, 143, 211);
    color: #fff;
    font-weight: 500;
    padding: 5px;
    display: inline-block;
}

.invite-popup-input:focus {
    outline: none;
}

.invite-popup-input::placeholder {
    color: #fff;
    opacity: 0.3;
}

.invite-via-ani-popup {
    margin-bottom: 10px;
}

.invite-via-ani-btn {
    border-radius: 4px;
    border: 0px;
    height: 40px;
    width: 100%;
    background-color: rgb(44, 109, 165);
    color: #fff;
    font-weight: 600;
    transition: all 0.2s;
}

.invite-via-ani-btn:hover {
    background-color: rgb(53, 66, 87);
}

.invite-via-ani-btn:active {
    box-shadow: 1px 1px 3px 1px #aaa;
}


.invite-popup-text {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}

.share-popup-icon {
    margin-left: 8px;
    margin-top: -10px;
    height: 20px;
    
}

.share-invite-btn {
    background-color: rgba(103, 168, 243, 0);
    border: 0px;
    width: min-content;
    height: min-content;
    opacity: 0.5;
    display: inline-block;
}

.share-invite-btn:focus {
    opacity: 1;
    outline: none;
}

.envelope-icon-nav {
    margin-left: -2px !important;
}

.close-modal-btn {
    height: 15px;
    border: 0px;
    position: absolute;
    right: 12px;
    top: 12px;
}

.close-modal-btn:focus {
    outline: none;
}


.session-type-img {
    height: 35px;
}

.session-type-option-icon {
    height: 35px;
    margin-top: 18px;
    margin-bottom: 5px;
}

.quick-save-btn {
    margin-top: 5px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500;
    border: 0px;
    color: #fff;
    background-color: rgb(103, 168, 243);
}

.generate-btn-container {
    float: right;
}

.generate-btn {
    height: 30px;
}

.talent-search-box-nav-schedule {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border-radius: 4px;
    width: 200px;
    box-shadow: 0px 1px 3px 1px rgba(89, 93, 95, 0.155);
    border: 1px solid rgb(228, 233, 238);
    left: 95px;
    top: 53px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.no-border-bottom {
    border-bottom: 0px !important;
}

.talent-suggestion-nav-schedule {
    display: grid;
    grid-template-columns: 40px auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    padding: 5px;
    cursor: pointer;
}

.hovered-item {
    background-color: #eee;
}

.talent-avatar-img-nav-schedule {
    object-fit: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: 4px;
}

.talent-name-nav-schedule {
    font-size: 13px;
    font-weight: 500;
    margin-top: -1px;
}

.talent-rep-nav-schedule {
    font-size: 11px;
    font-weight: 400;
    opacity: 0.5;
    margin-top: -3px;
}

.centered-text {
    text-align: center;
}

.font-size-13 {
    font-size: 13px;
}

.opacity-0-5 {
    opacity: 0.5;
}

.tal-sche-slot-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: 4px;
}

.tal-sch-slot-user-container {
    display: grid;
    grid-template-columns: 40px auto;
}

.delete-session-btn {
    margin-top: 10px;
    position: relative;
}
.delete-session-icon {
    height: 16px;
    position: absolute;
    right: 20px;
    top: 8px;
}

.delete-alert-container {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    box-shadow: 1px 1px 3px 0.5px #ddd;
    border-radius: 4px;
    z-index: 10;
    width: 200px;
    top: 310px;
    left: 30px;
}

.delete-alert-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    margin-top: 5px;
}

.delete-alert-btn {
    border-radius: 4px;
    border: 0px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
}

.alert-confirm {
    background-color: rgb(255, 31, 31);
}

.alert-cancel {
    background-color: rgb(31, 128, 255);
}

.session-user-avatar {
  object-fit: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.sd-users-list-container {
    margin-bottom: 20px;
    width: 400px;
}

.user-list-inline-block {
    display: inline-block;

}

.sd-user-avatar-img {
  object-fit: cover;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.invite-popup-bg {
    width: 100%;
    height: 100vh;
    display: block;
}

.highlight-slot-item {
    background-color: rgb(240, 243, 247);
}

.nav-sess-buttons-container {
    position: absolute;
    top: 17px;
    right: 70px;
}

.nav-sess-btn {
    border-radius: 4px;
    border: 1px solid #eee;
    margin-right: 5px;
    width: 100px;
}

.disabled-nav-btn {
    opacity: 0.2;
    cursor: default;
}

.session-talent-header {
    display: grid;
    grid-template-columns: 60px auto;
    margin-bottom: -20px;
}

.sess-modal-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-right: 10px;
    margin-top: 3px;
}

.slim-modal-section-div {
    height: 20px;
}

.session-talent-header {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.sess-modal-name {
    font-size: 21px;
    font-weight: 800;
}

.slim-modal-content {
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;
    height: 420px;
}

.session-tal-mod-label {
    font-size: 13px;
    font-weight: 800;
    margin-bottom: 5px;
    margin-top: 10px;
    color: rgb(158, 160, 179);
}
 
.session-tal-mod-by {
    font-size: 13px;
    opacity: 0.5;
    margin-bottom: -3px;

    color: rgb(158, 160, 179);
}

 
.session-tal-mod-note {
    font-size: 17px;
    font-weight: 500;
    cursor: default;
    width: 100%;
    border: 0px;
    height: 120px;

}

.edit-sess-tal-note {
    height: 200px;
}

.session-tal-mod-note:focus {
    outline: none;
}

.quicksess-select {
    background-color: #ccc;
}

.quick-page-padding {
    padding: 20px;
}

.quick-page-action {
    font-size: 25px;
    font-weight: bold;
    color: rgb(143, 77, 171);
    margin-bottom: 15px;
    margin-top: -20px;
}

.quicksess-project-item {
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 3px;
    height: 40px;
    font-size: 17px;
    font-weight: 500;
    padding-top: 6px;
    padding-left: 10px;
}

.modern-scroll {
    height: 50vh;
    overflow: auto;
}

.quicksess-project-item:active {
    background: #ccc;
    transition: 0.2s;
}

.quicksess-project-item:hover {
    transition: 0.2s;
    background: #eee;
}

.qui-next-button {
    position: absolute;
    top: 15px;
    right: 50px;
    width: 100px;
    border-radius: 4px;
    
}

.quicksess-role-item {
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 3px;
    height: 40px;
    font-size: 17px;
    font-weight: 500;
    padding-top: 6px;
    padding-left: 10px;
    width: 200px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
}

.swap-item-show {
    position: absolute;
    width: 100px;
    background-color: #fff;
    opacity: 1;
    text-align: center;
    right: 0px;
    height: 30px;
    top: 15px;
    z-index: 20;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    padding-top: 3px;
    cursor: pointer;
}

.swap-item-show:hover {
    box-shadow: 1px 1px 3px 1px #ddd;
}