.nav-links {
   height: 320px;
   width: 200px;
   margin-top: 5px;
   box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.192);
   position: absolute;
   right: 10px;
   top: 5px;
   z-index: 20;
   background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
   border-radius: 4px;
   padding-top: 20px;
}


.expand-nav-icon {
    height: 20px;
    opacity: 0.5;
    margin-bottom: 2px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.in-menu {
    top: 5px;
    right: 5px;
}

.nav-bar {
    width: 100%;
    height: 50px;
    margin-top: -50px;
    background-color:rgb(255, 255, 255);
    border-bottom: 3px solid rgb(236, 240, 244);
    padding-bottom: 2px;
}

.nav-link-container {
    padding-top: 10px;
    width: 200px;
    
}

.nav-item {
    color: #000;
}
.expand-nav {
    position: absolute;
    background-color: #fff;
    z-index: 5;
    overflow: hidden;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    top: 3px;
    left: 3px;
    box-shadow: .5px 1px 2px 1px rgba(0, 0, 0, 0.212);
    
}

.ani-icon-nav-hidden-nav {
    height: 20px;
    width: 20px;
    margin-left: 5px;

}

.talent-sign-out-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.nav-links {
}

.nav-bar-alignment {
    height: auto;
    position: relative;
}

.nav-item {
    display: grid;
    grid-template-columns: 40px 160px;
    width: 200px;
    height: 40px;
    padding-top: 8px;
    transition: all 0.2s;
}

.sign-out-btn {
    margin-top: 3.5px;
    cursor: pointer;
    opacity: 0.5;
}

.sign-out-btn:hover {
    opacity: 1
}

.employee-select {
    margin-top: -3px;
    display: grid;
    grid-template-columns: auto auto;
    
}

.select-employee-select {

}

.active-user-text {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 11px;
}

.nav-item-container {
    display: grid;
    grid-template-columns: auto auto;
    margin-right: 5px;
    height: 100%;
    padding: 5px;
    border-radius: 4px;
    margin-top: -3px;
    opacity: 0.5;
}
.nav-item-container-select {
    display: grid;
    grid-template-columns: auto auto;
    margin-right: 5px;
    height: 100%;
    padding: 5px;
    border-radius: 4px;
    margin-top: -3px;
    opacity: 1;
}

.nav-item-container-help {
    display: grid;
    grid-template-columns: auto auto;
    background-color: rgb(192, 201, 224);
    margin-right: 15px;
    height: 100%;
    padding: 5px;
    border-radius: 4px;
    margin-top: -3px;
    opacity: 1;
    cursor: pointer;
}


.nav-item:hover {
    background-color: #fff;
    cursor: pointer;
    width: 200px;
}

.nav-text-label {
    font-size: 13px;
    font-weight: 600;
    width: 100px;
    margin-top: 2px;
    color: rgb(67, 21, 71);
}

.nav-icons {
    height: 18px;
    width: 21px;
    margin-left: 5px;
}

.nav-icons-account {
    height: 18px;
    width: 18px;
    margin-left: 5px;
}

.nav-icons-form {
    height: 18px;
    width: auto;
    margin-left: 5px;
}

.nav-icons-help {
    height: 18px;
    width: auto;
    margin-left: 5px;
}

.need-help-text {
    margin-top: 5px;
    
}

.nav-text {
    font-size: 12px;
    font-weight: 600;
    color: rgb(49, 48, 54);
    margin-left: 5px;
}

.ani-icon-nav {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    margin-top: 3px;
}

/* LOCK SCREEN */

.input-header {
    text-align: center;
    font-weight: 500;
}

.pin-body {
    width: 100%;
    text-align: center;
}

.link-back {
    font-size: 14px;
    font-weight: 500;
}

.pin-input {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    border-radius: 4px;
    border: .5px solid #ddd;
    background-color: #fafafa;
}

.log-out-btn {
    margin-top: 80px;
    font-size: 12px;
    opacity: 0.5;
}

.to-check-in-btn {
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    margin-top: 15px;
    font-size: 14px;
    padding: 10px;
    background-color: rgb(92, 105, 116);
    color: #fff;
    font-weight: bold;
    border: 0px;
    width: 150px;
}

.lock-icon {
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.05;
    cursor: pointer;
    transition: all 0.2s;
}

.lock-icon:hover {
    opacity: 0.8;
    transition: all 0.2s;
}

.shield-icon {
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.2;
}

.lock-page {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 4px;
    height: 50px;
    margin-top: 20px;

}

