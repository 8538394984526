
/*--color-nav: linear-gradient(135deg, #80b8e1 0%, #e2d1c3 100%);*/
:root {
    --color-nav: linear-gradient(135deg, #9bb3c4 0%, #8ea3b8 100%);
    --color-nav-text: #fff;
    --color-bg: rgb(240, 244, 250);
    --color-header: rgb(0, 0, 0);
    --color-label: #17252A;
    --color-text: #17252A;
    --color-nav-button: #1f385c;
    --color-nav-section: #fff;
}

.color-bg {
    background: var(--color-bg);
}

.color-nav-section {
    color: var(--color-nav-section);
}

.nav-button-color {
    background: var(--color-nav-button);
    color: #fff;
}

.nav-button-color:disabled {
    opacity: 0.2;
    color: #fff;
    background: #000;
}

.nav-tab-color {
    background: var(--color-tab-bg);
    color: var(--color-nav-text);
}

.nav-text-color {
    color: var(--color-nav-text);
}

.label-color {
    color: var(--color-label);
}

.header-color {
    color: var(--color-header);
}

.text-color {
    color: var(--color-text);
}

.nav-text-color {
    color: var(--color-nav-text);
}