.tag-display-count {
    width: 100%;
    display: grid;
    grid-template-columns: auto 20px 20px;
}

.group-tag-remove {
    height: 13px;
    margin-top: 6px;
}

.group-note-add-btn-sly {
    position: absolute;
}

@media (min-width: 510px) {
    #sly-note-confirm-note-button {
        display: none;
    }
    .sly-note-add-note-mobile-btn {
        display: none;
    }

    .no-focus {
        position: absolute;
        top: 10px !important;
        right: 10px;
        height: 0px;
        width: 40px;
        border: 0px;
        background-color: #fff;
        border-radius: 100px;
    }
}

@media (max-width: 509px) {
    #the-slyest-add-note {
        position: fixed !important;
        z-index: 15;
        width: 100%;
        height: 100vh;
        left: 0px;
        top: 0px;
        padding: 5px;
        background-color: rgba(52, 76, 106, 0.516);
        border: 0px;
    }

    #group-tag-size {
        font-size: 13px !important;
        padding-top: 6px
    }

    .i-pad-note-textarea {
        border: 0px !important;
        box-shadow: 0.5px 0.5px 3px 1px rgba(0, 0, 0, 0.317) !important;
    }

    .no-focus {
        background-color: rgba(204, 204, 204, 0);
        position: fixed;
        top: 15px !important;
        right: 20px !important;
        height: 50px !important;
        width: 50px !important;
        border: 0px;
        background-color: #fff;
        border-radius: 100px;
    }

    #sly-tag-icon-mobile {
        z-index: 16;
        height: 50px;
        width: 50px;
        box-shadow: 1px 1px 3px 1px #ccc;
        border-radius: 100px;
    }

    #sly-note-confirm-note-button {
        position: fixed;
        bottom: 0px;
        left: 0px;
        height: 60px;
        padding-top:17px;
        color: #fff;
        width: 100%;
        text-align: center;
        background-color: rgb(43, 126, 227);
        border: 0px;
        z-index: 20 !important;
        cursor: pointer;

    }

    .sly-note-add-note-mobile-btn {
        position: fixed;
        z-index: 18;
        top: 240px;
        right: 30px;
        height: 40px;
        cursor: pointer;
    }

    .sly-note-add-note-mobile-btn:hover {
        box-shadow: 1px 1px 3px 1px #ccc;
    }

    .sly-note-add-note-mobile-btn:active {
        opacity: 0.2;
        transition: all 0.2s;
    }
}

.sly-tag-add-box{
    display: grid;
    column-gap: 20px;
    height: 410px;
    width: 100%;
    overflow: auto;
    padding: 10px;
    padding-bottom: 200px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.sly-tag-item {
    height: 30px;
    margin-bottom: 2px;
    cursor: pointer;
    font-weight: 500;
    font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 15px;
    color: rgb(74, 79, 84);
    padding-left: 10px;
    padding-top: px;
}

.sly-tag-add-box::-webkit-scrollbar {
    display: none;
}

.sly-note-tag-list-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    z-index: 5;
}

.adding-first-note {
    position: absolute !important;
    bottom: 10px;
}

.column-divider {
    height: 100%;
    background-color: #ddd;
}

.sly-tag-columns {
    display: grid;
    grid-template-columns: 2fr 2px 5fr;
}

.sly-tag-selected {
    box-shadow: 0px 0px 2px 2px rgba(141, 156, 177, 0.797);
    border-radius: 4px;
    padding-top: 2px;
}

.select-user-scroll {
    height: 500px;
    overflow: auto;
}

.user-list-scroll {
    overflow: auto;
     
}

.sly-tag-item-overlay {

}

.sly-note-listed-tag-item {
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 10px;
    background-color: rgb(239, 239, 245);
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(103, 114, 131);
    font-weight: bold;
    font-size: 13px;
    height: 30px;
    padding-top: 5px;
}

.smaller-tag {
    height: 20px;
    padding-top: 2px;
    font-size: 11px !important;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 2px;
}

.search-group-tag-input {
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #eee;
}

.search-group-tag-cancel {
    height: 13px;
    padding: 2px;
    position: absolute;
    top: -20px;
    right: 0px;
}

.group-tag-add-check {
    height: 15px;
    width: 15px;

}

.group-tag-add-suggestion {
    display: grid;
    grid-template-columns: auto 20px;
    height: 30px;
    cursor: pointer;
    padding-left: 8px; 
    padding-top: 2px;

}

.group-tag-add-suggestion:hover {
    background-color: #eee;
}

.group-tag-eye {
    height: 15px;
}

.no-display {
    opacity: 0;
    position: absolute;
}

.display-none {
    height: 40px;
    transition: all 0.2s;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;

}

.hidden-eye {
    opacity: 0.2;
}

.group-tag-add-button {
    width: 100%;
    margin-bottom: 5px;
    font-size: 13px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #eee;

}

.group-tag {
    margin-bottom: 5px;
    box-shadow: 0.5px 1px 2px 1px #eee;
    padding-left: 5px;
    padding-top: 3px;
    border-radius: 4px;
    cursor: pointer;
    height: auto;
    font-size: 13px;
    border: 0.5px solid #eee;

}

.internal-tags {
    padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    margin-right: 5px;
    margin-top: 5px;
}


.group-tag:hover {
    background-color: #eee;
}

.talent-list-scroller {

}


.send-back-to-q {
    font-size: 13px;
    top: 50px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    color: rgb(80, 108, 201);
    cursor: pointer;

}

.send-back-to-q:active {
    opacity: 0.5;
}

.send-back-to-q:hover {
    font-weight: bold;
}

.group-tag-selected {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 4px 1px #ccc;
}

.disabled-group-tag {
    box-shadow: none;
    cursor: default;
}

.rp-active-talent-tags-container {
    position: relative;
}

.session-nav-divider {
    height: 2px;
    background-color: rgb(222, 230, 235);
    margin-bottom: 10px;
}

.group-add-note-btn-sly {
    padding: 0px;
    border-radius: 100px;
    border: 0px;
    box-shadow: 1px 1px 3px 1px #ccc;
    opacity: 0.8;
    z-index: 10;
    position: absolute;
    top: -10px;
    right: -10px;
} 


.group-add-note-btn {
    padding: 0px;
    border-radius: 100px;
    border: 0px;
    box-shadow: 1px 1px 3px 1px #ccc;
    opacity: 0.8;
    z-index: 10;
    position: absolute;
    top: -10px;
    right: -10px;
} 

.group-focus-description {
    font-size: 13px;
    margin-top: -5px;
    margin-bottom: 10px;
}

.group-session-schedule-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.group-session-schedule-item {
    background-color: #fff;
    box-shadow: .5px .5px 3px 2px #eee;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px;
    min-height: 50px;

}

.group-session-schedule-container {
    padding: 5px;
}

.group-session-schedule-item-layout {
    display: grid;
    grid-template-columns: 70px auto;
}

.group-session-schedule-talent {
    display: grid;
    grid-template-columns: 40px auto;
}

.group-session-schedule-time {
    font-size: 13px;
}

.group-session-schedule-role {
    font-size: 13px;
    opacity: 0.5
}

.group-expanded-note-edit {
    width: 100%;
    height: 250px;
    border: 0px
}

.i-pad-session-title {
    font-size: 19px;
    font-weight: 800;
    margin-top: -20px;
    margin-bottom: 10px;
}

.group-expanded-note-edit:focus {
    outline: none;
}

.talent-ipad-list-scroller {
    margin-top: 00px;
    height: 90vh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 200px;
}

.talent-ipad-list-scroller::-webkit-scrollbar {
    display: none;
}

.rp-active-talent-tags-container {
    overflow: hidden;
}

.schedule-scroll {
    margin-top: 20px;
    height: 80vh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.schedule-scroll::-webkit-scrollbar {
    display: none;
}

.sly-tag-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 40px;
    padding: 5px;
    width: 40px;
}



.note-icons-clickable {
    position: absolute;
    height: 40px;
    padding-top: 5px;
    padding-right: 5px;
    top: 2px;
    right: 0px;
    border-radius: 100px;
}

.note-icons-btn {
    border: 0px;
    background-color: #fff;
}

.white-box {
    margin-top: -10px;
    opacity: 0;
}

.sly-tag-icon-small {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 100px;
    padding: 0px;
    margin-right: 5px;
}

.sly-note-icon-small {
    height: 0px;
    
    background-color: #fff;
    border-radius: 100px;
}

.sly-tag-icon:hover {
    box-shadow: 2px 2px 6px 2px #ccc;
}

.sly-tag-icon:active {
    opacity: 0.5;

    background-color: rgb(126, 126, 126);
    transition: all 0.2s;
}


.custom-marker-add-button {
    width: 100%;
    text-align: center;
    height: 40px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    background-color: rgb(230, 234, 238);
    padding-top: 10px;
    cursor: pointer;

}

.custom-marker-add-button:hover {
    box-shadow: 2px 2px 4px 1px #ccc;
}

.custom-marker-add-button:active {
    box-shadow: 2px 2px 4px 1px #ccc;
    opacity: 0.8;
}

.custom-marker-add {
    margin-bottom: 20px;
}

.custom-marker-container {
    height: auto;
    min-height: 100px;
    margin-bottom: 20px;
}

.ipad-text-input {
    height: 80px !important;
}

.custom-marker-input {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    padding: 8px;
    border: 1px solid #eee;
    margin-top: -10px;
}

.custom-marker-list {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    column-gap: 5px;
}

.custom-marker-item {
    text-align: center;
    background-color: #fafafa;
    color: rgb(148, 148, 148);
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 19px;
    font-weight: 800;
    height: 50px;
    padding-top: 8px;
    position: relative;
    margin-bottom: 5px;
    cursor: pointer;
}

.marker-add-text {
    font-size: 13px;
    text-align: right;
    margin-top: -22px;
    margin-bottom: 20px;
    cursor: pointer;
}

.delete-trash-hover {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    box-shadow: 1px 1px 1px 1px #eee;
    background-color: #fff;
    top: -8px;
    right: -2px;
}

.trash-marker-hover-icon {
    height: 10px;
    margin-top: -10px;
}

.selected-marker {
    background-color: rgb(82, 155, 233);
    color: #fff;
}

.show-tags {
    position: absolute;
    top: 0px;
    right: 0px;
}

.markers-container {
    position: absolute;
    top: 5px;
    right: 5px;
}

.session-mode-section-label {
    margin-left: 10px;
    font-weight: 800;
}

.toggle-divider {
    height: 10px;
}

.sly-note-expand {
    background: #fff;
    box-shadow: 2px 2px 4px 1px #ccc !important;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.2s;
    margin-bottom: 20px;
}

.sly-note-min {
    height: 200px !important;
}

.sly-note {
    margin-bottom: 10px;
}

.group-sly-note {
    margin-bottom: -20px !important;
}

.i-pad-sly-note-header {
    height: 30px;
    margin-bottom: 0px;
}

.i-pad-sly-note-name {
    font-weight: 800;
    font-size: 15px;
    color: rgb(92, 94, 103);
    margin-top: -3px;
}

.i-pad-sly-note-rep {
    font-size: 13px;
    margin-top: -4px;
}

.sly-note-name {
    font-size: 13px;
    font-weight: bold;
}



.group-note-item-container {
    background-color: rgb(245, 246, 248);
    padding: 5px;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 13px !important;
    margin-bottom: 0px;
}

.hide-div {
    display: none;
}

.sly-note-scroll {
    height: 80vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 5px;
    padding-bottom: 300px !important;
    margin-bottom: 200px;
    border: 1px solid rgb(238, 238, 238) !important;
    background: rgb(245, 245, 245);
    border-radius: 4px;
}

.scroll-min-max {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    grid-template-rows: repeat(100, min-content);
    column-gap: 10px;
    row-gap: 5px;
}

@media (max-width: 509px) {
    .sly-note-scroll {
        padding: 1px;
        display: block;
    }
}

.sly-note-scroll::-webkit-scrollbar {
    display: none;
}


.sly-note-focus {
    height: 600px !important;
    box-shadow: 2px 2px 3px 1px #ccc !important;
    border: 2px solid rgb(221, 223, 228) !important;
}

.sly-on-deck-card {
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto;
    background-color: #ffffff;
    border: 0px;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 2px 1px rgb(194, 194, 194);
    height: auto;
    position: relative;
    white-space: pre-wrap;
    overflow: hidden;
    transition: all 0.2s;
    margin-bottom: 5px;
}

.select-user-bg {
    height: 100vh;
    width: 100vw;
    position: absolute;
    padding-top: 50px;
    z-index: 0;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); 
}

.select-user-message {
    width: 300px;
    height: 600px;
    background-color: #fff;
    box-shadow: 1px 1px 4px 1px #ccc;
    border-radius: 4px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    z-index: 25;
}

.select-user-label {
    margin-top: 10px;
    color: rgb(100, 130, 197);
    font-weight: bold;
    font-size: 17px;
    padding: 5px;
}

.sly-check-in-label {
    font-size: 13px;
    font-weight: 600;
    color: rgb(207, 214, 224);
}

.employee-name {
    margin-top: 8px;
}

.employee-option {
    display: grid;
    grid-template-columns: 50px auto;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 4px;
    margin-top: 2px;
    transition: all 0.2s;
}

.selected-emp {
    background-color: #ccc;
    border: 0px;
    color: #fff;
}

.employee-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 2px solid #fff;
}

.on-deck-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 2px solid #fff;
}

.sly-on-deck-name {
    font-weight: 600;
    margin-bottom: -4px;
    margin-top: -4px;
}

.sly-on-deck-rep {
    font-size: 13px;
    opacity: 0.5;
}

.sly-on-deck-project {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: -4px;
}

.sly-on-deck-role {
    font-size: 13px;
}

.sly-on-deck-display {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 11px;
    opacity: 0.5;

}

.sly-box-header {
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: 700;
}


.notes-mininized {
    height: 80px !important;
}

.notes-expanded {
    height: 250px;
}

.notes-very-minimized {
    height: 5px !important;
}

.min-card {
    transition: all 0.5s;
}

.group-note-padding {
    padding: 10px;
}

.long-wait {
    border-left: 4px solid rgb(241, 200, 139);
}

.very-long-wait {
    border-left: 4px solid rgba(255, 109, 109, 0.548);
}


.display-option {
    margin-right: 10px;
}

.display-select {
    font-weight: bold;
}

.setting-toggles-container {
    padding: 15px;
    box-shadow: 0.5px .5px 5px 2px rgba(142, 142, 142, 0.221);
    margin-bottom: 3px;
    border-radius: 4px;
    font-size: 17px;
    font-weight: 400;
    background-color: rgb(240, 243, 247);
}

.show-queue-container {
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 4px;
    background: #fff !important;
    
}

.active-setting-switch-border {
    border: 2px solid rgb(67, 130, 225);
}

.switch-description-additional {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;

}

.check-in-list-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3px;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
}

.check-in-list-tab {
    background-color: #fafafa;
    height: 30px;
    padding-top: 5px;
    border-radius: 4px;
    transition: all 0.2s;
}

.selected-list-tab {
    font-weight: bold;
    background-color: #ccc;
    color: #fff;
}


.sess-schedule-item {
    display: grid;
    grid-template-columns: 60px auto;
    margin-bottom: 4px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 00px 0px 1px 1px rgba(194, 194, 194, 0.324);
    border: 0px;
    padding: 8px;
    border-radius: 4px;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    overflow: hidden;
    transition: all 0.2s;
    margin-bottom: 5px;

}


.green-border {
    border-left: 4px solid rgb(47, 193, 64);
    box-shadow: 0.5px 0.5px 2px 1px rgb(194, 194, 194);
    cursor: pointer;
}

.green-border:hover {
    box-shadow: 1px 1px 3px 2px rgb(194, 194, 194);
}

.sess-schedule-time {
    font-size: 13px;
    margin-top: 2px;
}

.sess-schedule-name {
    font-size: 15px;
    font-weight: 500;
}

.sess-schedule-role {
    font-size: 14px;
    font-weight: 400;
}

.complete-sess-sche-item {
    background-color: #eee;
    opacity: 0.5;
    height: 30px;
    padding-top: 2px;
}

@media (max-width: 750px) {
    .check-in-list-tab {
        margin-top: 20px !important;
    }
}