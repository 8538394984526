.talent-dashboard-container {
    background-color: #F7F9FC;
    height: 100vh;
    display: grid;
    grid-template-columns: min-content 1.5fr 1fr;
    column-gap: 40px;
}

.tal-dash-title {
    text-align: center;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 19px;
}

.tal-dash-min-arrow {
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 100px;
    box-shadow: 0px .5px 3px 1px #eee;
    transition: all 0.2s;
    position: absolute;
    right: -10px;
    background-color: #fff;

}

.right-arrow {
    transform: rotate(90deg);
}

.input-sub-label {
    font-size: 11px;
    opacity: 0.4;
    float: right;
}

.left-arrow {
    transform: rotate(270deg);
}

.tal-dash-settings {
    width: 420px;
    padding: 20px;
    background-color: #fff;
    height: 100vh;
    box-shadow: 2px 0px 3px 0px rgba(204, 204, 204, 0.219);
    transition: all 0.2s;
    position: relative;
}

.dash-sidebar-min {
    width: 20px;
}

.tal-dash-label {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.tal-dash-section-label {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tal-dash-input {
    width: 100%;
    height: 40px;
    font-size: 17px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 4px;
    border: 0px;
    background-color: #eee;
}

.tal-dash-textarea {
    width: 100%;
    height: 100px;
    font-size: 17px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 4px;
    border: 0px;
    background-color: #eee;
}

.tal-dash-prof-pic {
  object-fit: cover;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

.tal-dash-prof-pic-container {
    width: 100%;
    padding-left: 110px;
    margin-top: 10px;
}

.tal-dash-appt-item {
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    padding: 18px;
    width: 90%;
    margin-top: 10px;
}

.job-feed-item {
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    padding: 18px;
    width: 95%;
    margin-top: 10px;
    position: relative;
}

.job-company {
    font-size: 13px;
    opacity: 0.5;
    position: absolute;
    right: 15px;
    top: 15px;
}

.job-role {
    font-size: 19px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: -2px;
}

.job-project {
    font-size: 15px;
    margin-bottom: 10px;
}

.job-header {
    border-bottom: 1px solid #eee;
    padding-left: 15px;
}

.job-body {
    padding-left: 15px;
    padding-top: 10px;
}

.job-label {
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
}

.job-footer {
    padding-left: 20px;
    padding-top: 40px;
}

.job-submission-btn {
    width: 140px;
    height: 30px;
    border-radius: 25px 2px 4px 2px;
    font-weight: bold;
    font-size: 13px;
    margin-top: 20px;
    padding-left: 20px;
    margin-left: auto;
    background: rgb(211, 120, 211);
    color: #fff;
    border: 0px;
    position: absolute;
    right: 10px;
    bottom: 10px;
}


