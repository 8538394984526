.sd-body-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    height: 100%;
    width: 100%;
}

.sb-body-header {
    border-bottom: 2px solid rgba(221, 221, 221, 0.5);
    width: 100%;
}

.sd-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.label-margin-bottom {
    margin-bottom: 5px;
}

.sd-detail-container {
    display: grid;
    grid-template-columns: 65px auto;
    margin-bottom: 8px;
}

.sd-sub-label {
    font-size: 15px;
}

.sd-user-layout {
    display: inline-block;
    margin-right: 10px;
    background-color: rgb(167, 179, 195);
    border-radius: 100px;
}

.sd-user-container {
    display: grid;
    grid-template-columns: 30px auto;
    padding-right: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
}

.sd-user-avatar {
    background-color: #eee;
    border-radius: 100px;
    height: 25px;
    width: 25px;
    border: 2px solid #fff;
    margin-left: -1px;
}

.sd-element {
    font-size: 15px;
    font-weight: 500;
}

.sd-label {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
}

.inline-block-layout {
    display: inline-block;
    margin-right: 10px;
}

.sd-link-label {   
    font-size: 15px;
    font-weight: 500;
    color: rgb(126, 130, 141);   
}

.sd-times-container {
    margin-top: 10px;
    margin-bottom: 4px;
}

.sd-links-container {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    text-align: center;
    height: 110px;
    margin-bottom: 30px;
}

.sd-link-item {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    cursor: pointer;
    height: 150px;
}

.sd-link-item:hover {
    box-shadow: 1px 1px 8px 1px #ccc;
}

.margin-right-10 {
    margin-right: 20px;
}

.sd-time-item {
    display: grid;
    grid-template-columns: 45px auto;
}

.sd-body-body {
    padding-top: 1px;
    margin-top: 20px;
    background-color: #f7f9fc;
    padding-bottom: 200px;
    padding-left: 5px;
    padding-right: 5px;
    display: grid;
    grid-template-rows: 155px 155px;
}

.sd-clock-icon {
    height: 15px;
    width: 15px;
    margin-bottom: 4px;
}

.sd-link-number {
    font-size: 36px;
    color: rgb(140, 141, 156);
    font-weight: 800;
    margin-top: 25px;
}

.sd-most-tags-list {

}

.non-clickable {
    cursor: default;
}

.non-clickable:hover {
    cursor: default;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
}

.sb-null-text {
    color: rgb(55, 58, 63);
    font-size: 15px;
    padding-top: 20px;
    opacity: 0.2;
    text-align: center;

}

.sd-tags-container {
    margin-top: 10px;
}


.sd-tag {
    background-color: rgb(235, 237, 241);
    width: max-content;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    color: rgb(65, 65, 75);
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
}

.sb-talent-view-search {
    padding: 8px;
    height: 30px;
    margin-top: -4px;
    border-radius: 4px;
    border: 0px;
    font-weight: 500;
    font-size: 15px;
    color: rgb(55, 58, 63);
    background-color: #eceef1;
}

.sb-talent-view-search:focus {
    outline-color: rgb(89, 119, 170);
    outline-width: 1px;
}

.sb-talent-view-search::placeholder {
    color: rgb(55, 58, 63);
    opacity: 0.2;
}



.sb-body-container {
    height: 100%;
    overflow: hidden;
    border: 0px;
    border-radius: 4px;
    padding-top: 10px;
}

.sess-dets-back-btn {
    position: absolute;
    top: 10px;
    height: 35px;
    background-color: #fff;
    width: 200px;
    display: grid;
    padding-top: 5px;
    grid-template-columns: 40px auto;
    cursor: pointer;
}

.sb-body-header-talent {
    display: grid;
    grid-template-columns: auto max-content;
    cursor: pointer;
    height: 30px;
}

@media (max-width: 599px) {
    .taglist-header {
        grid-template-rows: 30px 30px;
        grid-template-columns: auto;
        height: 60px;
    }
}

@media (min-width: 600px) {
    .taglist-header {
        grid-template-columns: auto 160px;
    }
}

.sb-talent-sort-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: -5px;
    margin-right: 20px;
    height: 29px;
    cursor: pointer;
    
}

.tag-btn-sorts {
    grid-template-columns: max-content max-content;
    column-gap: 30px;
}

.clear-filter {
    height: 15px;
    position: absolute;
    right: 20px;
    top: 74px;
    opacity: 0.2;
    cursor: pointer;
    padding: 2px;
}

.sd-talent-sort-option {
    font-size: 15px;
    font-weight: 500;
    border: 0px;
    opacity: 0.3;
}

.sd-talent-sort-option:focus {
    outline: 2px solid rgb(151, 159, 168);
}

.sort-text-selected {
    font-weight: 700;
    opacity: 1;
    border: 0px;
}

.sort-text-selected:focus {
    outline-color: #eee;
}

.sb-filter-label {
    font-size: 13px;
    margin-top: 5px;
}

.sb-talent-header-text {
    margin-bottom: 10px;
    color: rgb(83, 134, 201);
    font-size: 15px;
    font-weight: 500;
}

.sb-talent-back-arrow {
    height: 15px;
    transform: rotate(180deg);
    margin-left: 15px;
    margin-top: 4px;
}

.sb-talent-card-note-count {
    display: grid;
    grid-template-columns: 20px auto;
    float: right;
}

.sb-talent-card-note-icon {
    height: 15px;
    margin-top: 2px;
    opacity: 0.5;
}

.sb-talent-card-note-count-text {
    font-size: 13px;
    margin-bottom: 2px;
    font-weight: bold;
    opacity: 0.5;
}

.talent-list-container-sb {
    padding-bottom: 20px;
    background-color: #f7f9fc;
    height: 70vh;
    border-radius: 4px;
}


.sb-talent-body-body::-webkit-scrollbar {
    display: none;
}

.session-overview-scroll {
    overflow-y: auto;
    height: 80vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.session-overview-scroll::-webkit-scrollbar {
    display: none;
}

.sb-talent-body-body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
    overflow-y: auto;
    border-radius: 4px;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.sb-talent-card {
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    position: relative;
    height: auto;
}

.sb-talent-card-talent {
}

.sb-notes-header {
    grid-template-columns: auto;
    width: 250px;
    margin-left: auto;
}

.sb-talent-card-avatar {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: #eee;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sb-talent-card-name {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: -2px;
}

.sb-talent-card-role {
    font-size: 15px;
    font-weight: 600;
}

.sb-talent-card-rep {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.7;
}

.sb-talent-card-tag-container {
    opacity: 0.7;
    display: grid;
    grid-template-columns: 18px auto;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sb-talent-card-tag {
    display: inline-block;
    margin-right: 5px;
    word-break: break-word;
}

.sb-talent-card-tag-list {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.sb-talent-card-top {
    margin-bottom: 10px;
}

.sb-talent-card-time-breakdown {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.sb-talent-card-time-icon {
    height: 10px;
    margin-top: 2.5px;
}

.sb-talent-card-time-text {
    font-size: 11px;
}

.sb-talent-card-time-section {
    display: grid;
    grid-template-columns: 15px auto;
    margin-bottom: 3px;
}

.sb-talent-card-bottom {
    border-top: 1px solid #eee;
    padding-top: 10px;
    width: 90%;
}

.s-notes-body {
    height: 100%;
    margin-top: -10px;
}

.s-notes-body-header {
    padding-left: 10px;
    border-bottom: 1px solid #ddd;
}

.s-note-text {

}

.s-note-tag-container {
    position: block;
    margin-left: 0px;
}

.s-note-user {
    display: grid;
    grid-template-columns: 50px auto;
}

.s-note-user-img {
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.s-note-tag {
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    opacity: 0.5;
}

.s-notes-body-body {
    padding: 10px;
    overflow: auto;
    height: 70vh;
    padding-bottom: 300px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 4px;
    background-color: #f7f9fc;
}

.s-notes-body-body::-webkit-scrollbar {
    display: none;
}

.s-note-container {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    
    white-space: pre-wrap;
}

.s-note-role {
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 13px;
    opacity: 0.5;
}

.s-note-rep {
    float: right;
    font-size: 13px;
}

.note-item {
    display: grid;
    grid-template-columns: 20px auto;
    margin-bottom: 5px;
}

.s-note-talent {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: -3px;
}

.s-note-note {
    margin-top: 5px;
    font-size: 15px;
    margin-left: 30px;
}

.note-bullet {
    height: 8px;
    width: 8px;
    border-radius: 100px;
    background-color: #ddd;
    margin-top: 8px;
    margin-left: -14px;
}

@media (max-width: 599px) {
    .sd-details-layout {
        width: 100%;
        display: grid;
        grid-template-columns: auto;
    }
}

@media (min-width: 600px) {
    .sd-details-layout {
        width: 100%;
        display: grid;
        grid-template-columns: 60% auto;
    }
}

.sd-talent-take-pin-item {
    display: inline-block;
    margin-right: 10px;
    width: 200px;
}

.sd-talent-take-take-item {
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 80px auto auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}

.relative {
}

.sb-select-tag-item {
    height: 30px;
    padding-top: 4px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
}

.selected-tag-item-dropdown {
    background-color: rgb(225, 230, 236);
    z-index: 20;
}

.s-note-tag-item-tagname {
    font-weight: bold;
    color: rgb(90, 97, 110);
    margin-bottom: 5px;
}

.sb-select-tag-dropdown {
    position: absolute;
    top: 100px;
    width: 155px;
    right: 15px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.315);
    padding-bottom: 5px;
    border: 0px;
    max-height: 500px;
    overflow-y: auto;
}

.s-note-tag-item-talent {
    margin-bottom: 5px;
}

.view-as-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.s-note-tag-item-icon {
    height: 15px;
    margin-right: 10px;
    margin-top: -2px;
    opacity: 0.2;
}

.s-note-tag-item-talent-item {
    display: grid;
    grid-template-columns: 40px auto;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
}

.s-note-tag-item-talent-item-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.s-note-tag-item-talent-item-name {
    margin-top: 2px;
}

.view-switch-container {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 5px;
}

.view-switch-toggle-button {

}

.sd-title-container {
    position: absolute;
    top: 10px;
    background-color: #fff;
    left: 3px;
    padding-left: 18px;
    width: 300px;
}

.s-note-header {
    position: relative;
}

.copy-list-widget {
    position: absolute;
    right: 0px;
    top: 0px;
}

.copy-list {
    border-radius: 4px;
    background-color: #f6f7f9;
    border: 0px;
    font-size: 15px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 400;
}