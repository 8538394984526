.switch-common-component-container {
    width: auto;
    height: 18px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 35px auto;
    column-gap: 5px;
    border: 0px;
}

.switch-background {
    border-radius: 100px;
    border: none;
    transition: all 0.1s;
    position: relative;
    width: 35px;
    height: 18px;
}

.active-switch {
    background-color: #445057;
}

.disabled-active-switch {
    background-color: #69757e;
}

.dormant-switch {
    background-color: #dedede;
}

.switch-knob {
    border-radius: 100%;
    background-color: #fff;
    height: 18px;
    width: 18px;
    border: 1px solid #ccc;
    transition: all 0.1s;
}

.right-knob {
    margin-left: 18px;
}

.left-knob {
    right: 0px;
}

.account-switch-padding {
    margin-left: 8px;
    margin-top: 8px;
}

.switch-common-text {
    font-size: 15px;
    font-weight: 400;
    opacity: 0.8;
    margin-top: -3px;
    margin-left: 5px;
}

.switch-large-text {
    font-size: 17px;
    opacity: 1;
    font-weight: bold;
}

.confirm-pop-up-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 200px;
    z-index: 20;
}

.confirm-pop-up-bg {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 4px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
}

.confirm-pop-up-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    margin-top: 10px;
}

.confirm-pop-up-button {
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.confirm-pop-up-button:focus {
    outline: none;
}

.pop-cancel {
    background-color: rgb(153, 153, 153);
    border: 0px;
}

.pop-confirm {
    background-color: rgb(243, 77, 77);
    border: 0px;
}

.filter-box {
    width: 130px;
}

.filter-toggle {
    height: 15px;
}

.filter-box {
    background-color: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    height: max-content;
}

.filter-box-shadow {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.256);
}

.filter-option {
    height: 30px;
    padding-top: 3px;
    padding-left: 5px;
    transition: all 0.2s;
}

.filter-option:hover {
    background: #eee;
}

.filter-option:active {
    background: #ccc;
}

.filter-dropdown-container {
    width: 120px;
    display: grid;
    grid-template-columns: auto 20px;
    padding-left: 5px;
}

.dropdown-bottom-border {
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.arrival-card {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 60px auto;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: all 0.2s;
    cursor: pointer;
    background: rgba(18, 18, 18, 0.125);
    color: #fff;
    font-weight: bold;
}

.arrival-card:hover {
    box-shadow: 0.5px 0.5px 3px 1px rgba(104, 104, 104, 0.584);
}

.arrival-card-image {
    object-fit: cover;
    height: 50px;
    border-radius: 100px;
}

.common-select-component {
    position: relative;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
}

.common-select-icon {
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.common-select-input {
    height: 35px;
    padding: 5px;
    border-bottom: 1px solid #eee;
    background: #fff;
    z-index: 0;
}
.common-select-options {
    position: absolute;
    background: #fff;
    width: 100%;
    box-shadow: 0.5px 0.5px 3px 1px rgba(104, 104, 104, 0.762);
    border-radius: 4px;
    padding: 3px;
    margin-top: 5px;
    z-index: 15;
}

#create-acct-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}

@media (max-width: 550px) {
    .common-select-options {
        position: relative;
    }
}

.common-select-option {
    padding: 5px;
    transition: all 0.1s;
    cursor: pointer;    
    border-radius: 4px;
    background: #fff;
    z-index: 1;
}

.common-select-option:hover {
    background: #eee;
}

.common-selected {
    background: rgb(82, 127, 225);
    color: #fff;
}

.dir-up {
    bottom: 40px;
}

.default-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 15px;
    padding-left: 8px;
    padding-right: 8px;
}

.default-input::placeholder {
    opacity: 0.4;
}

.default-input:focus {
    box-shadow: 0.5px 0.5px 2px 1px rgba(58, 58, 58, 0.267);
    outline: none;
    border: 1px solid rgb(82, 127, 225);;
}