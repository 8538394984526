.col-centered{
}

.background {
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);    
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.account-page-layout {
    width: 100%;
    display: grid;
    grid-template-columns: max-content auto 0px;
    column-gap: 30px;
    transition: all 0.2s;
};

.account-page-right-side {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.account-right-nav {
    background-color: #fefefe;
}

.account-page-left-side {
    width: auto;
    position: relative;
}

.account-input {
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 4px 4px 4px 4px;
    background-color: #fff;
    padding-left: 8px;
    margin-bottom: 2px;
}

.account-input-settings {
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 4px;
    background-color: #fafafa;
    font-weight: 500;
    padding-left: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.account-input-settings-edit {
    width: 100%;
    height: 40px;
    border: 2px solid rgb(235, 194, 194);
    border-radius: 4px;
    font-weight: 500;
    padding-left: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.account-input-settings-center {
    text-align: center;
    padding-left: 0px;
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 4px;
    background-color: #fafafa;
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 2px;
}

.account-input-settings-edit-center {
    text-align: center;
    padding-left: 0px;
    width: 100%;
    height: 40px;
    border: 2px solid rgb(235, 194, 194);
    border-radius: 4px;
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 2px;
}


.account-input-row {
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 4px;
    background-color: #fafafa;
    padding-left: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 5px;
}

.user-settings-container {
    height: 100vh;
    width: 300px;
    border: 0px; 
    padding: 10px;
    background-color: #fff;
    box-shadow: 1px 3px 6px 1px rgba(116, 117, 126, 0.509);
    transition: all 0.2s;
}


.slide-out {
    width: 40px;
}

    .acct-settings-label {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .acct-settings-profile-pic {
        height: 100px;
        width: 100px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        border-radius: 100px;
        background-color: #ccc;
    }

    .acct-settings-input-container {
        margin-top: 5px;
    }

    .acct-settings-input-label {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: -2px;
    }

    .acct-settings-city-inputs {
        display: grid;
        grid-template-columns: 175px 5px 35px 5px 60px;
    }

    .acct-settings-number-inputs {
        display: grid;
        grid-template-columns: 160px 5px 80px;
    }

    .password-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 5px;
    }

    .change-password {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
    }

    .forgot-password {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
    }

.add-btn {
    border-radius: 0px 4px 4px 0px;
    background-color: #288ec6;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
    width: 30px;
    height: 40px;
    transition: all 0.5s;
    cursor: pointer;
}



.view-roles-btn {
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 4.5px;
    border-radius: 4px;
    background-color: #34334b;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 80px;
    height: 30px;
    transition: all 0.5s;
    cursor: pointer;
}

.project-cards {
    display: grid;
    grid-template-columns: 30px auto 26px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    height: auto;
    margin-top: 5px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    border-radius: 4px;
    background-color: #fff;
}


.project-role-cards {
    display: grid;
    grid-template-columns: 30px auto 26px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    height: auto;
    margin-top: 5px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    border-radius: 4px;
    background-color: #fff;
}

.release-to-feed-btn {
    margin-bottom: 8px;
    margin-left: 10px;
    margin-top: -5px;
}

.project-card-right {
    padding-bottom: 10px;
}

.role-cards {
    width: 200px;
    height: 100px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 4px;
    background-color: #fff;
}

.room-cards {
    display: grid;
    grid-template-columns: auto 26px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    height: 80px;
    margin-top: 5px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
}

.upload-prof-pic {
    cursor: pointer;
    position: relative;
}

.upload-prof-pic:hover {
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    transform: scale(1.01);
}

.upload-prof-pic:active {
    opacity: 0.5;
    transition: all 0.3s;
}

.employee-cards {
    width: 100%;
    height: 60px;
    margin-top: 5px;
    margin-right: 10px;
    box-shadow: .5px .5px 2px 1px rgba(0, 0, 0, 0.055);
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
}

.edit-employee-card {
    height: 85px;
}

.employee-email {
    width: 100%;
    cursor: pointer;
    margin-left: 10px;
}
.add-email-container {
    width: 100%;
    margin-left: 10px;
    display: grid;
    grid-template-columns: auto 50px 50px;
    column-gap: 2px;
}

.add-employee-email-input {
    border: 1px solid #eee;
    padding-left: 8px;
    border-radius: 4px;
    width: 100%;
    margin-left: 10px;
}

.save-employee-email {
    border-radius: 4px;
    background-color: rgb(126, 133, 153);
    border: 0px;
    color: #fff;
    font-size: 12px;
    margin-left: 2px;
}

.remove-employee-email {
    border-radius: 4px;
    background-color: rgb(218, 76, 76);
    border: 0px;
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
}

.add-employee-email-input:focus {
    outline: 0;
}

.project-cards:hover {
}

.room-cards:hover {
}

.card-name {
    padding: 5px;
    padding-left: 10px;
    padding-bottom: 0px;
    font-weight: 14px;
    font-weight: bold;
}

.lists-container {
    overflow-y: scroll;
    height: 100vh;
    border-radius: 4px 4px 4px 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    -ms-overflow-style: none;
}

.acct-settings-back-btn {
    margin-bottom: 10px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    width: max-content;
}

.lists-container::-webkit-scrollbar {
    display: none;
}

.no-sidebar {
    width: 0px;
    opacity: 0;
}

.account-header {
    margin-bottom: 5px;
}

.list-scroller {
    width: 100%;
    height: min-content;
    max-height: 80vh;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #f7f9fc;
    padding: 10px;
    overflow: auto;
}

.button-label {
    cursor: pointer;
}

.account-list-label {
    font-size: 20px;
    font-weight: 800;
    position: relative;
    margin-bottom: 5px;
}

.list-header-input-container {
    display: grid;
    grid-template-columns: auto 29px;
    margin-bottom: 20px;
}

.list-background {
    border-radius: 4px;
    padding-top: 1px;
    padding-bottom: 5px;
    overflow-y: auto;
    height: min-content;
    transition: all 0.2s;
    margin-bottom: 10px;
}


.lists-container {
    width: auto;
}

.room-bar {
    padding: 3px;
    height: 20px;
    width: 20px;
    border-radius: 4px;
}

    .settings-icon {
        margin-top: 6px;
        height: 20px;
        width: 20px;
        opacity: 0.2;
        cursor: pointer;
    }

    .settings-icon:hover {
        opacity: 1;
    }
    
    .project-panel-project-title {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 35px;
        width: 80%;
    }

    .role-card {
        height: 65px;
    }

    .activate-btn {
        border-radius: 100px;
        background-color: #dedede;
        border: none;
        color: #ffffff;
        width: 30px;
        height: 15px;
        transition: all 0.1s;
        cursor: pointer;
    }

    .activate-btn:focus {
        outline: none;
    }

    .item-active {
        background-color: #51df91;
    }
    
    .activate-btn:hover {
        background-color: #7aeeae;
        color: #ffffff;
    }
    
    
    .deactivate-btn:hover {
        background-color: #7aeeae;
        color: #ffffff;
    }

    .temp-project-btn {
        border-radius: 4px 0px 0px 4px;
        background-color: #f4f4f4;
        border: none;
        color: #ffffff;
        padding: 5px;
        width: 100%;
        height: 100%;
        transition: all 0.1s;
    }
    

.role-count {
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    opacity: 0.5;
}

.role-count-text {
    display: inline-block;
    margin-right: 2px;
}

.back-arrow {
    height: 20px;
    width: 20px;
    top: 5px;
    cursor: pointer;
    position: absolute;
}


.trash-icon {
    height: 20px;
    width: auto;
    opacity: 0.4;
    float: right;
    margin-top: 6px;

}

.trash-icon:hover {
    opacity: 0.9;
    cursor: pointer;
}


.trash-icon-list {
    height: 12px;
    width: auto;
    opacity: 0.2;
    float: right;
    margin-top: 8px;
    margin-right: 8px;

}

.trash-icon-list:hover {
    opacity: 0.9;
    cursor: pointer;
}

.pic-section {
}

.account-minimize-arrow {
    height: 20px;
    position: absolute;
    right: 8px;
    cursor: pointer;
}

.acct-right-arrow {
    transform: rotate(270deg);
}

.acct-left-arrow {
    transform: rotate(90deg);
}

.user-prof-pic {
    width: 100%;
}

.profile-pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    height: 70px;
    width: 70px;
}

.change-pic-btn {
    background-color: #ddd;
    height: 24px;
    width: 120px;
    text-align: center;
    font-weight: bold;
    border-radius: 100px;
    padding-top: 3px;
    display: block;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    color: #aaa;
    transition: all 0.2s;
    font-size: 12px;
}

.change-pic-btn:hover {
    background-color: rgb(66, 134, 189);
    color: #fff;
    transition: all 0.2s;
    cursor: pointer;
}


.edit-btn {
    float: right;
    height: 15px;
    width: 15px;
}

.edit-btn-icon {
    height: 100%;
    width: 100%;
    margin-bottom: 15px;
    opacity: 0.2;
    cursor: pointer;
}

.edit-btn-icon:hover {
    opacity: .5;
}

.edit-btn-icon-edit-mode {
    height: 100%;
    width: 100%;
    margin-bottom: 15px;
    opacity: 1;
    cursor: pointer;
}


.save-changes-btn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    color: #fff;
    font-weight: bold;
    background-color: #4f576d;
    margin-top: 20px;
}



.connect-container {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    margin-top: 4px;
}

.connect-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
}

.connect-btn {
    text-align: center;
    border-radius: 4px;
    margin-top: 8px;
    cursor: pointer;
    padding: 3px;
    font-weight: 500;
}

#connect-keep {
    border: 1px solid rgb(56, 186, 114);
    color: rgb(56, 186, 114);
}

#connect-combine {
    border: 1px solid rgb(85, 127, 218);
    color: rgb(85, 127, 218);
}

#connect-remove {
    border: 1px solid rgb(216, 58, 58);
    color: rgb(216, 58, 58);
}

.connect-select-container {
    display: grid;
    grid-template-columns: auto 90%;
}

.connect-select {
    margin-top: 6px;
    padding: 3px;
    width: 100%;
    height: 28px;
    background-color: #f8f8f8;
    border: 0px;
}

.cancel-select {
    width: auto;
    height: 17px;
    margin-top: 11px;
    opacity: 0.5;
    font-weight: 500;
}

.cancel-select:hover {
    opacity: 0.8;
    cursor: pointer;
}

.employee-pic {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.employee-item-layout {
    display: grid;
    grid-template-columns: 60px auto;
}

.enable-notifications {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    margin-top: 10px;
    background-color: rgb(102, 106, 119);
    color: #fff;
} 


.employees-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 5px;
}

.delete-project-container {
    position: absolute;
    padding: 5px;
    top: 0px;
    right: 0px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    font-size: 13px;
    font-weight: 500;
    display: grid;
    grid-template-columns: auto min-content;
    column-gap: 10px;
    cursor: pointer;
    opacity: 0.5;
}

.delete-project-container:hover {
    opacity: 1;
}

.account-project-container {
    position: relative;
}

.delete-project-text {
    margin-top: 0px;
}

.account-project-trash-icon {
    height: 15px;
    margin-top: 2px;
}

.alert-settings-modal {
    position: absolute;
    z-index: 20;
    background-color: rgb(119, 144, 227);
    height: min-content;
    width: 50%;
    min-width: 300px;
    top: 10px;
    left: 10px;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 1px rgb(99, 99, 99);
    color: #fff;
    font-weight: bold;
    font-size: 19px;
}

.sounds-good {
    margin-top: 50px;
    width: 200px;
    height: 40px;
    border-radius: 4px;
    border: 0px;
    background-color: #fff;
}