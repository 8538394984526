.landing-page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

.landing-background-color {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: grid;
    grid-template-rows: 320px auto;
  }

.landing-background-color-bottom {
    background: rgba(245, 247, 250, 0.788);
}

.landing-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }

.landing-nav {
    display: grid;
    grid-template-columns: auto 280px;
    padding-top: 20px;
}

.landing-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    margin-top: 50px;
}

.landing-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    margin-top: 50px;
  }
  
.landing-navigator {
    width: 100%;
}

.landing-nav-text {
    display: inline;
    font-size: 17px;
    margin: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.landing-nav-text:hover {
    opacity: 0.5;
}

.ln-select {
    font-weight: 800;
}

.landing-body {
    width: 100%;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
}

.landing-body-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    margin-top: 20px;
}

.landing-tagline {
    font-size: 35px;
    font-weight: 500;
    margin-left: 20px;
    color: rgb(58, 147, 255);
}

.landing-action {
    width: 350px;
    vertical-align: top;
    padding: 20px;
    padding-top: 50px;
}

.landing-action-btn {
    border-radius: 2px;
    height: 40px;
    font-size: 15px;
    font-weight: bold;
    background: linear-gradient(135deg, #11B5F2 0%, rgb(28, 74, 223) 100%);
    border: 0px;
    color: #fff;
    transition: all 0.1s;
}

.landing-login {
    border-radius: 2px;
    height: 40px;
    font-size: 15px;
    width: 100px;
    font-weight: bold;
    background: linear-gradient(135deg, rgb(59, 62, 65) 0%, rgb(58, 58, 58) 100%);
    border: 0px;
    color: #fff;
    transition: all 0.1s;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 2px;
    cursor: pointer;
    z-index: 3
}

.landing-btn-live {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.214);
    
}
.landing-action-btn:focus {
    outline: none; 
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.473);
   
}

.landing-action-btn:hover {
    opacity: 0.8;
}
.landing-action-btn:active {
    opacity: 0.5;
}

.landing-login:hover {
    opacity: 0.5 !important;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.473);
}

.action-label {
    font-size: 45px;
    font-weight: 800;
}

.ani-landing-img {
    height: 300px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.landing-action-display {
}


.landing-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    margin-top: 50px;
}

.header-tagline {
    text-align: center;
    margin-top: 50px;
    font-size: 27px;
    font-weight: 800;
    color: rgb(42, 57, 88);
}

.request-access-box {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.request-layout {
    width: auto;
    max-width: 450px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 10px;
}

.request-access-input {
    height: 40px;
    border-radius: 2px 0px 0px 2px;
    background: #fff;
    border: 1px solid rgba(42, 57, 88, 0.342);
    border-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
    color: #000;
    font-size: 17px;
    transition: all 0.2s;
}

.request-access-live {
    border: 1px solid rgba(32, 90, 207, 0.822);
    border-right: 0px;
}

.request-access-input:focus {
    outline: none;
    box-shadow: 1px 1px 4px 1px rgba(46, 46, 46, 0.288);
}

.landing-body .panels {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .landing-panel-item {
    vertical-align: top;
    width: auto;
    margin: 20px;
    background: #fff;
    padding: 20px;
    height: 380px;
    border-radius: 2px;
    border: 1px solid rgb(150, 173, 213);
  }


@media (max-width: 550px) {
      .header-tagline {
        font-size: 23px;
        font-weight: 500;
        line-height: 1.5;
        padding: 20px;
        padding-top: 0px;
      }

    .landing-body .panels {
        width: 100%;
        margin-bottom: 100px;
    }

}
.landing-panel-rows {
    display: grid;
    grid-template-rows: 120px 55px auto;
}



.landing-panel-label {
    font-size: 21px;
    font-weight: 800;
    text-align: center;
}

.landing-panel-description {
    font-size: 17px;
    font-weight: 300;
    text-align: center;
}

.landing-panel-icon {
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.landing-body {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px;
}

.landing-logo-text {
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    margin-top: 10px;
    color: rgb(231, 214, 233);
}

.landing-sent {
    margin-top: 10px;
    text-align: center;
}